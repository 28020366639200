 

<div class="chatbox-holder">
  <div class="chatbox group-chat chatbox1">
    <div class="chatbox-top chatbox-top1">
      <div class="chat-group-name chat-group-name1">
        <h5>Square pixels official</h5>
      </div>
      <div class="chatbox-icons chatbox-icons1">
        <div class="settings-popup">
          <ul>
            <li><a href="#">Group members</a></li>
            <li><a href="#">Add members</a></li>
            <li><a href="#">Delete members</a></li>
            <li><a href="#">Leave group</a></li>
          </ul>
        </div>
        <a href="javascript:void(0);"><i class="fa fa-minus"></i></a>
        <button type="button" class="close fa fa-close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="chat-messages">
      @for (message of messages; track message) {
        <div style="display: contents;">
          @if (userId === message.sender_id) {
            <div class="message-box-holder">
              <div class="message-box">
                {{message.message}}
                <span>{{ message.createdAt | date: 'hh:mm a' }}</span>
              </div>
            </div>
          }
          @if (userId !== message.sender_id) {
            <div class="message-box-holder">
              <div class="message-sender">
                <a class="senderName">{{message.name}}</a>
              </div>
              <div class="message-box message-partner">
                {{message.message}}
                <span>{{ message.createdAt | date: 'hh:mm a' }}</span>
              </div>
            </div>
          }
        </div>
      }
    </div>

    <div>
      <div class="chat-input-holder">
        <textarea class="chat-input" [(ngModel)]="newMessage" placeholder="Type a message"></textarea>
        <div class="cursorPointer">
          <img src="../../../assets/sendMessage.png" class="message-send message-send1"
            (click)="sendMessage()">
        </div>
      </div>
    </div>
</div>


</div>