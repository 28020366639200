import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators , ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InventoryService } from 'src/app/service/inventory.service';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CrmService } from 'src/app/service/crm.service';
import { NgClass } from '@angular/common';
import { alertModalWithoutConfirm } from 'src/app/component/helpers/alert';

@Component({
  selector: 'app-forward-service-req',
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, MatSelectModule, ReactiveFormsModule, FormsModule,NgClass],
  templateUrl: './forward-service-req.component.html',
  styleUrl: '../../styles/inventory.css'
})
export class ForwardServiceReqComponent {
  @Input({required: true}) selectedServiceInventory: any;
  forwardRequestForm: FormGroup;


 constructor(private fb: FormBuilder,private inventoryService: InventoryService, private crmService: CrmService){
  this.forwardRequestForm = this.fb.group({
    maximumCost: ['', Validators.required],
    comment: ['', Validators.required],
    selectedAdmin: ['', Validators.required]
  })
}
  ngOnInit() {
   this.getAdminUsersList();
  }


  adminUsers: { value: string, viewValue: string, id: string }[] = [];
  getAdminUsersList() {
    const department = "HR";
    const loggedInUsername = localStorage.getItem("username");
    const loggedInUserId = localStorage.getItem("guestID");
    this.crmService.getAnyUsers(department).subscribe(
      (res) => {
        let data = res["data"];
        this.adminUsers = data.map((adminUser) => {
          return {
            value: adminUser.userName,
            viewValue: adminUser.userName,
            id: adminUser._id
          };
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

    // Price Formatting
    formatCurrency(value: string | number): string {
      if (!value) return "";
      return new Intl.NumberFormat("en-IN").format(Number(value));
    }
    onProductCostInput(event: Event): void {
      const input = event.target as HTMLInputElement;
      const value = input.value.replace(/,/g, "");
      this.forwardRequestForm.get("maximumCost")?.setValue(value, { emitEvent: false });
      input.value = this.formatCurrency(value);
    }

  showForwardSuccess = false;
   errorMessage = ''
   submitted = false;
  onSubmitForwardReq(serviceReq: any) {
    console.log({ serviceReq })
    console.log('REQUEST ID', serviceReq.requestId)
    console.log(this.forwardRequestForm.value)
    const forwardReqValues = {
      ...this.forwardRequestForm.value
    };


    const getForwardRequestFormValue: any = {
      maximumCost: String(forwardReqValues.maximumCost),
      comment: String(forwardReqValues.comment),
      requestId: serviceReq.requestId,
      adminId: forwardReqValues.selectedAdmin
    };
    console.log(serviceReq?.requestId, 'REQUEST ID')
    this.inventoryService.addForwardServiceReq(getForwardRequestFormValue).subscribe(response => {
      console.log('submitted ', response);
      // this.getServiceRequestData();
      this.forwardRequestForm.reset();
      this.showForwardSuccess = true;
    },
      error => {
       if (this.forwardRequestForm.invalid) {
          console.log("invalid");
          return alertModalWithoutConfirm(
            "warning",
            "Please fill in all required fields before submitting.",
          );
        }
       else if (error.status === 400) {
          this.errorMessage = 'This request has already been submitted.';
        } 
        console.error('Error', error);
      });
  }
}
