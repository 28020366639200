import { Component } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { provideNativeDateAdapter } from "@angular/material/core";
import { MatSelectModule } from '@angular/material/select';
import { InventoryService } from "src/app/service/inventory.service";
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { CrmService } from "src/app/service/crm.service";
import { NgClass } from "@angular/common";
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { ForwardServiceReqComponent } from "../shared/forward-service-req/forward-service-req.component";
import {MatSnackBar} from '@angular/material/snack-bar';
import { NoDataComponent } from "../shared/no-data/no-data.component";


interface Urgency {
  value: string;
  viewValue: string;
}

interface Service {
  value: string;
  viewValue: string;
}

interface Damage {
  value: string;
  viewValue: string;
}

interface Request {
  value: string;
  viewValue: string;
}
interface AllServicesReq {
  value: string;
  viewValue: string;
}
interface PendingServicesReq {
  value: string;
  viewValue: string;
}
interface ApprovedServicesReq {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-service-request",
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    MatInputModule,
    MatAutocompleteModule,
    ForwardServiceReqComponent,
    NoDataComponent
],
  templateUrl: "./service-request.component.html",
  styleUrls: ["./service-request.component.css", "../styles/inventory.css"],
})
export class ServiceRequestComponent {
  showNoDataMessage: boolean = false;

  //Select Field Options
  urgencies: Urgency[] = [
    { value: 'High', viewValue: 'High' },
    { value: 'Medium', viewValue: 'Medium' },
    { value: 'Low', viewValue: 'Low' },
  ];

  services: Service[] = [
    { value: 'Repair', viewValue: 'Repair' },
    { value: 'Replaced', viewValue: 'Replaced' },
    { value: 'Damage', viewValue: 'Damage' },

  ]

  damages: Damage[] = [
    { value: 'Screen-Broken', viewValue: 'Screen Broken' },
  ];

  requests: Request[] = [
    { value: 'Service Request', viewValue: 'Forwarded Request' },
  ]

  allServiceReq: AllServicesReq[] = [
    { value: 'Service Request', viewValue: 'Service Request' }
  ]

  pendingServiceReq: PendingServicesReq[] = [
    { value: 'Service Request', viewValue: 'Pending Request' }

  ]
  approvedServiceReq: ApprovedServicesReq[] = [
    { value: 'Service Request', viewValue: 'Approved Request' }

  ]


  pendingReq = [];
  totalPending: any;
  onFilterPendingRequest(requestType: string,pageNum: number = 1, limit: number = 20) {
    const getPendingReqValue: any = {
      requestType: requestType,
    };
    const field: any = {
      pageNum: pageNum,
      limit: limit,
    };
    this.inventoryService.getPendingRequest(getPendingReqValue,field).subscribe(response => {
      console.log("Pending Request");
      this.pendingReq = response['data'].records;
      this.totalPending = response['data'].totalCounts
      this.showForwardedReqTable = false;
      this.showServiceRequestTable = false;
      this.showApprovedRequest = false;
      this.showPendingRequest = true;
    })
  }

  approveInv = [];
  totalApproved: any;
  onFilterApprovedRequest(requestType: string,pageNum: number = 1, limit: number = 20) {
    const getApprovedReqValue: any = {
      requestType: requestType,
    }
    const field: any = {
      pageNum: pageNum,
      limit: limit,
    };
    this.inventoryService.getApprovedRequest(getApprovedReqValue,field).subscribe(response => {
      console.log("Approved Request", response);
      this.approveInv = response['data'].records;
      this.totalApproved = response['data'].totalCounts;
      this.showForwardedReqTable = false;
      this.showServiceRequestTable = false;
      this.showApprovedRequest = true;
      this.showPendingRequest = false;



    })
  }
  serviceRequestForm: FormGroup
  forwardRequestForm: FormGroup;
  constructor(private fb: FormBuilder, private inventoryService: InventoryService, private crmService: CrmService, private snackBar: MatSnackBar) {

    // Service Request Form Group
    this.serviceRequestForm = this.fb.group({
      damageType: ['', Validators.required],
      serviceType: ['', Validators.required],
      needByDate: ['', Validators.required],
      detail: ['', Validators.required],
      urgency: ['', Validators.required],
      approverName: ['', Validators.required],
      selectedInventory: [null, Validators.required]
    })

    // Forward Request Form Group
    // this.forwardRequestForm = this.fb.group({
    //   maximumCost: ['', Validators.required],
    //   comment: ['', Validators.required],
    //   selectedAdmin: ['', Validators.required]
    // })



  }

  userDepartment: string | null = null;
  showForwardButton = false;
  ngOnInit() {
    this.getAssignedInventoryData();
    // this.getAllUsers();
    this.getServiceRequestData();
    this.getAdminUsersList();
    this.userDepartment = localStorage.getItem('department');
    this.showForwardButton = this.userDepartment === 'HR';
  }


  showForwardedReqTable: boolean = false;
  showServiceRequestTable: boolean = true;
  showApprovedRequest: boolean = false;
  showPendingRequest: boolean = false;
  //On select Request Type
  onRequestSelect(value: string): void {
    console.log("Selected value:", value);
    this.filterForwardReq(value);
  }

  openServiceForm() {
    this.serviceRequestForm.reset();
    this.formSubmitted = false;

  }

  //Filter Request Type
  forwardedReq = []
  totalForwarded: any;
  currentRequestType: string = '';
  filterForwardReq(requestType: string, pageNum: number = 1, limit: number = 20) {
    this.currentRequestType = requestType;
  
    const getForwardReqValue: any = {
      requestType: requestType,
    };
  
    const field: any = {
      pageNum: pageNum,
      limit: limit,
    };
  
    this.inventoryService.getAllForwardedReq(getForwardReqValue, field).subscribe(response => {
      console.log("Forward Response", response);
      this.forwardedReq = response['data'].records;
      this.totalForwarded = response['data'].totalCounts;
      this.showForwardedReqTable = true;
      this.showServiceRequestTable = false;
    });
  }
  


  //Date Format
  formatStartDate(date: Date): { day: number; month: number; year: number } {
    return {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear()
    };
  }

  //Service Request Form submit
  formSubmitted = false;
  errorMessage = ''
  onServiceReqSubmit() {
    const serviceRequestValue = {
      ...this.serviceRequestForm.value
    };
    console.log({ serviceRequestValue })

    const needByDate = new Date(serviceRequestValue.needByDate);
    const formattedStartDate = this.formatStartDate(needByDate);

    const approver = this.adminUsers.find(adminUser => adminUser.value === serviceRequestValue.approverName);
    const approverId = approver ? approver.id : "null";
    console.log({ approverId });

    const getserviceFormValue: any = {
      damageType: String(serviceRequestValue.damageType),
      serviceType: String(serviceRequestValue.serviceType),
      detail: String(serviceRequestValue.detail),
      needByDate: {
        day: needByDate.getDate(),
        month: needByDate.getMonth() + 1,
        year: needByDate.getFullYear()
      },

      urgency: serviceRequestValue.urgency,
      approverName: String(serviceRequestValue.approverName),
      approverId: approverId,
      // assignedInventoryId: '',
      assignedInventoryId: serviceRequestValue.selectedInventory.assignedInventoryId
    };
    console.log({ getserviceFormValue })
    this.inventoryService.addServiceRequest(getserviceFormValue).subscribe(response => {
      console.log('submitted ', response);
      this.formSubmitted = true;
      this.getServiceRequestData();
      this.showForwardedReqTable = false;
      this.showServiceRequestTable = true;
    }, error => {
      if (error.status === 400) {
        this.errorMessage = 'This request has already been submitted.';
      } else {
        this.errorMessage = 'An error occurred. Please try again.';
      }
      console.error('Error', error);
    });
  }

  //Listing of All Assigned Inventory in Dropdown
  inventory: any[] = [];
  getAssignedInventoryData() {
    const field: any = {
      pageNum: 1,
      limit: 500,
    };

    this.inventoryService.getAssignInv(field).subscribe(
      (response: any) => {
        this.inventory = response.data.records.reverse().map((item: any) => ({
          value: item.name,
          viewValue: item.assetData.productId,
          assetImage: item.assetImage || [],
          assignedInventoryId: item.assignedInventoryId || null
        }));
      },

      (error) => {
        console.error("Error", error);
      }
    );
  }

  searchedInventory = []
  totalSearchedInv: any;
  searchQuery: string;
  onSearchInventory() {
    const field: any = {
      pageNum: 1,
      limit: 100,
      searchQuery: this.searchQuery,
    };

    if (this.searchQuery === "") {
      this.clearSearch();
    } else {
      this.inventoryService.getSearchedInventory(field).subscribe(
        (response: any) => {
          console.log(response);
          this.searchedInventory = response.data.records.map((item: any) => ({
            value: item.productId,
            viewValue: item.productId,
            images: item.images || [],
            assetId: item.assetId || null,
          }));
          console.log("Searched", this.searchedInventory);
          this.inventory = this.searchedInventory;
          this.totalSearchedInv = response.data.totalCounts;
        },
        (error) => {
          console.error("Error", error);
        }
      );
    }
  }

  clearSearch() {
    this.searchQuery = "";
    this.getAssignedInventoryData();
  }

  // On select Asset Field
  selectedInventoryControl = new FormControl();
  onInventoryChange(event: MatAutocompleteSelectedEvent) {
    const selectedInventory = event.option.value;
    this.selectedInventoryControl.setValue(selectedInventory);
    this.serviceRequestForm.patchValue({
      selectedInventory: selectedInventory
    });

    console.log({ selectedInventory })
    const assignedInventoryId = selectedInventory ? selectedInventory.assignedInventoryId : null;
    console.log('Selected AssignedInventoryId :', assignedInventoryId);

    this.serviceRequestForm.patchValue({
      assignedInventoryId: assignedInventoryId
    });
  }

  displayFn(inventory: any): string {
    return inventory && inventory.viewValue ? inventory.viewValue : '';
  }

  //Listing of All users in Approver
  // users: { value: string, viewValue: string, id: string }[] = [];
  // getAllUsers(): void {
  //   this.inventoryService.getInvUsers().subscribe(res => {
  //     let data = res["data"];
  //     this.users = data.map((user: any) => ({
  //       value: user.userName,
  //       viewValue: user.userName,
  //       id: user._id
  //     }));
  //     console.log("users=====", this.users);

  //   });
  // }


  //Admin Users
  adminUsers: { value: string, viewValue: string, id: string }[] = [];
  getAdminUsersList() {
    const department = "HR";
    const loggedInUsername = localStorage.getItem("username");
    const loggedInUserId = localStorage.getItem("guestID");
    this.crmService.getAnyUsers(department).subscribe(
      (res) => {
        let data = res["data"];
        this.adminUsers = data.map((adminUser:any) => {
          return {
            value: adminUser.userName,
            viewValue: adminUser.userName,
            id: adminUser._id
          };
          
        });
        console.log("users=====", this.adminUsers);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  //Listing of All service Request
  selectedInventory: any = {};
  serviceRequest = []
  totalService: any;
  getServiceRequestData() {
    const field: any = {
      pageNum: 1,
      limit: 20,
    }
    this.inventoryService.getServiceRequest(field).subscribe(res => {
      this.serviceRequest = res['data'].records
      this.totalService = res['data'].totalCounts
      console.log("Service Response", res)
      this.showForwardedReqTable = false;
      this.showServiceRequestTable = true;
      this.showApprovedRequest = false;
      this.showPendingRequest = false;
    })
  }


  acceptRequest(requestId: any) {
    console.log(this.currentRequestType, "RequestType")
    const acceptRequest = {
      action: 'Accept',
      requestId: requestId,
      requestType: 'Service Request',
    }
    this.inventoryService.adminAction(acceptRequest).subscribe(response => {
      console.log("called");
      this.snackBar.open("Request Accepted", "OK");
      this.getServiceRequestData();
    })
  }

  declineRequest(requestId: any) {
    const declineRequest = {
      action: 'Rejected',
      requestId: requestId,
      requestType: 'Service Request',
    }
    this.inventoryService.adminAction(declineRequest).subscribe(response => {
      console.log("called");
      this.snackBar.open("Request Declined", "OK");
      this.getServiceRequestData();

    })
  }

  currentPage = 1;
  itemsPerPage = 20;
  getPaginatedLeads() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    if(this.showServiceRequestTable){
    return this.serviceRequest.slice(startIndex, endIndex);
    }
    if(this.showForwardedReqTable){
        return this.forwardedReq.slice(startIndex, endIndex);
      }
      if(this.showApprovedRequest){
        return Math.ceil(this.totalApproved / this.itemsPerPage)
      }
      if(this.showPendingRequest){
        return Math.ceil(this.totalPending / this.itemsPerPage)
      }
  }

  //Function to handle page change
  pageChanged(page: number) {
    this.currentPage = page;
    console.log(page, "PAGE");
    const option = {
      pageNum: page,
      limit: 20,
    };
    if(this.showServiceRequestTable){
      this.inventoryService.getServiceRequest(option).subscribe(res => {
        this.serviceRequest = res['data'].records
        this.totalService = res['data'].totalCounts
      }
    );
  }
  if(this.showForwardedReqTable){
    this.inventoryService.getAllForwardedReq(this.currentRequestType, option).subscribe(res => {
      this.forwardedReq = res['data'].records;
      this.totalForwarded = res['data'].totalCounts;
    })
  }
  if(this.showApprovedRequest){
    this.inventoryService.getApprovedRequest(this.currentRequestType, option).subscribe(res => {
      this.approveInv = res['data'].records;
      this.totalApproved = res['data'].totalCounts;
    })
  }
  if(this.showPendingRequest){
    this.inventoryService.getPendingRequest(this.currentRequestType, option).subscribe(res => {
      this.pendingReq = res['data'].records;
      this.totalPending = res['data'].totalCounts
    })
  }
}

  get totalPages(): number {
    if(this.showServiceRequestTable){
    return Math.ceil(this.totalService / this.itemsPerPage);
  }
  if(this.showForwardedReqTable){
    return Math.ceil(this.totalForwarded / this.itemsPerPage);
  }
  if(this.showApprovedRequest){
    return Math.ceil(this.totalApproved / this.itemsPerPage)
  }
  if(this.showPendingRequest){
    return Math.ceil(this.totalPending / this.itemsPerPage)
  }
}

  get pages(): number[] {
    return Array.from({ length: this.totalPages }, (_, index) => index + 1);
  }

  trackByFn(index: number, item: any): any {
    return item._id;
  }

  get visiblePages(): number[] {
    const numVisiblePages = 3;
    const startPage = Math.max(
      1,
      this.currentPage - Math.floor(numVisiblePages / 2),
    );
    const endPage = Math.min(this.totalPages, startPage + numVisiblePages - 1);

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index,
    );
  }

  isPageVisible(page: number): boolean {
    const numVisiblePages = 3;
    const startPage = Math.max(
      1,
      this.currentPage - Math.floor(numVisiblePages / 2),
    );
    const endPage = Math.min(this.totalPages, startPage + numVisiblePages - 1);

    return page <= 3 || (page >= startPage && page <= endPage);
  }

}
