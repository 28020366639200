import { Component, OnInit } from "@angular/core";
import { Converse } from 'converse.svc-client';
import { UserService } from "../../service/user.service";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { DatePipe } from "@angular/common";
interface Message {
  name: string;
  message: string;
  user?: boolean;
}
declare var $: any; // Declare $ to use jQuery

interface ConverseMessage {
  name: string;
  message: string;
  sid: string;
  sender_id?: string;
  og_msg?: string;
}
@Component({
    selector: "app-conversation",
    templateUrl: "./conversation.component.html",
    styleUrls: ["./conversation.component.css"],
    standalone: true,
    imports: [
    ReactiveFormsModule,
    FormsModule,
    DatePipe
],
})
export class ConversationComponent implements OnInit {
  channel_id: any = "653a07521e843a9bec8a486e";
  user_id: any = localStorage.getItem("guestID");
  ephemeral = false;
  AllMessages: any = { read: [], unread: [] };
  project_id: any;
  allUsersData: any;
  selectedName: any;
  filteredUsers: any[];

  ngOnInit(): void {
    this.userService.getAllUsers().subscribe((resp) => {
      this.allUsersData = resp["data"];
    });

    this.getAllMess();
    this.filteredUsers = this.allUsersData;
  }

  async getAllMess() {
    console.log([]);
  }
  ngAfterViewInit() {
    this.chatInit(0, 0);
    $(".fa-minus").click(function () {
      $(this).closest(".chatbox").toggleClass("chatbox-min");
    });
    $(".fa-close").click(function () {
      $(this).closest(".chatbox").hide();
    });
  }
  async init() {
  
 
 return []
  }

  constructor(private userService: UserService) {}

  async initChannel() {
 

 
  }
  userId: any;
  conversationStart: any;
  async chatInit(receiverId: any, index: any) {
    this.userId = localStorage.getItem("guestID");
    this.selectedName = index.firstName;
    console.log(this.selectedName);
    this.conversationStart = receiverId;

    let resp = await this.init();
    console.log("resp", resp);
  }

  messages: {
    createdAt: string | number | Date;
    text: string;
    sender: string;
    sender_id: string;
    message: string;
    name: string;
  }[] = [];
  newMessage: string = "";

  async sendMessage() {
    if (this.newMessage.trim() !== "") {
      // this.messages.push({ text: this.newMessage, sender: "You" });
  
      this.newMessage = "";
    }
  }
  users: any;
  filterUser() {
    this.filteredUsers = this.allUsersData.filter((user) =>
      user.username.toLowerCase().includes(this.users.toLowerCase())
    );
    this.allUsersData = this.filteredUsers;
  }
}
