<!-- Forward New Inventory Modal -->
<div
  class="modal fade"
  id="forwardNewRequestModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="forwardRequestTitle"
  aria-hidden="true"
  data-backdrop="static"
>
  <div
    class="modal-dialog modal-dialog-centered forward-req-width"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close inven-cls"
          data-dismiss="modal"
          aria-label="Close"
        >
          <svg
            class="cross-svg"
            width="14"
            height="12"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5518 1.64453L1.85612 12.3402"
              stroke="#25252B"
              stroke-width="2.26313"
              stroke-linecap="round"
            />
            <path
              d="M12.5518 12.3401L1.85612 1.64445"
              stroke="#25252B"
              stroke-width="2.26313"
              stroke-linecap="round"
            />
          </svg>
        </button>
      </div>
      <div class="modal-body forward-req-container">
        @if (!showForwardSuccess) {
        <h1>Forward Request</h1>
        } @if(!showForwardSuccess){
        <div class="forward-container">
          <form
            [formGroup]="forwardRequestForm"
            (ngSubmit)="onSubmitForwardReq(selectedInventory)"
          >
            <div class="form-row">
              <div
                class="form-group col-md-6 maximum-cost-input"
                [ngClass]="{
                  'has-error':
                    forwardRequestForm.get('maximumCost').invalid &&
                    (forwardRequestForm.get('maximumCost').dirty ||
                      forwardRequestForm.get('maximumCost').touched ||
                      submitted)
                }"
              >
                <label for="maximumCost" class="form-label asterisk-lbl"
                  >Maximum Cost(₹)</label
                >
                <input
                  type="text"
                  id="maximumCost"
                  class="form-control"
                  formControlName="maximumCost"
                  [value]="
                    formatCurrency(forwardRequestForm.get('maximumCost').value)
                  "
                  (input)="onProductCostInput($event)"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="forward" class="form-label">Forward to</label>
                <mat-form-field style="width: 320px">
                  <mat-select formControlName="selectedAdmin">
                    @for (adminUser of adminUsers; track adminUser) {
                    <mat-option [value]="adminUser.id">{{
                      adminUser.viewValue
                    }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="form-row">
              <div
                class="form-group col-md-6"
                [ngClass]="{
                  'has-error':
                    forwardRequestForm.get('link1').invalid &&
                    (forwardRequestForm.get('link1').dirty ||
                      forwardRequestForm.get('link1').touched ||
                      submitted)
                }"
              >
                <label for="link1" class="form-label asterisk-lbl">Link 1</label>
                <input
                  type="text"
                  id="link1"
                  class="form-control"
                  formControlName="link1"
                />
              </div>
              <div
                class="form-group col-md-6"
                [ngClass]="{
                  'has-error':
                    forwardRequestForm.get('link2').invalid &&
                    (forwardRequestForm.get('link2').dirty ||
                      forwardRequestForm.get('link2').touched ||
                      submitted)
                }"
              >
                <label for="link2" class="form-label asterisk-lbl">Link 2</label>
                <input
                  type="text"
                  id="link2"
                  class="form-control"
                  formControlName="link2"
                />
              </div>
            </div>
            <div class="form-row">
              <div
                class="form-group col-md-6"
                [ngClass]="{
                  'has-error':
                    forwardRequestForm.get('link3').invalid &&
                    (forwardRequestForm.get('link3').dirty ||
                      forwardRequestForm.get('link3').touched ||
                      submitted)
                }"
              >
                <label for="link3" class="form-label asterisk-lbl">Link 3</label>
                <input
                  type="text"
                  id="link3"
                  class="form-control"
                  formControlName="link3"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12 d-flex flex-column"  [ngClass]="{
                'has-error':
                  forwardRequestForm.get('comment').invalid &&
                  (forwardRequestForm.get('comment').dirty ||
                    forwardRequestForm.get('comment').touched ||
                    submitted)
              }">
                <label for="comment">Comment</label>
                <textarea
                  id="comment"
                  placeholder="Add your comment"
                  name="comment"
                  rows="4"
                  cols="50"
                  formControlName="comment"
                >
                </textarea>
              </div>
            </div>

            <div class="approve-submit-button d-flex justify-content-center">
              <button
                type="submit"
                data-toggle="modal"
                class="btn approve-submit"
                data-target="#sentRequestModal"
              >
                Submit For Approval
              </button>
            </div>
          </form>
          @if (errorMessage) {
          <div class="alert alert-danger mt-3">
            {{ errorMessage }}
            <button type="button" class="close" data-dismiss="alert">
              <svg
                class="cross-svg"
                width="14"
                height="12"
                viewBox="0 0 14 14"
                fill="none"
                style="margin-bottom: 5px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5518 1.64453L1.85612 12.3402"
                  stroke="#25252B"
                  stroke-width="2.26313"
                  stroke-linecap="round"
                />
                <path
                  d="M12.5518 12.3401L1.85612 1.64445"
                  stroke="#25252B"
                  stroke-width="2.26313"
                  stroke-linecap="round"
                />
              </svg>
            </button>
          </div>
          }
        </div>
        } @if(showForwardSuccess){
        <div class="sent-req-content">
          <svg
            class="tick-mark"
            width="76"
            height="76"
            viewBox="0 0 76 76"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.7808 3.91014C46.0698 3.91014 54.0763 6.92354 60.3083 12.3889C66.5404 17.8543 70.573 25.3989 71.655 33.617C72.7369 41.8352 70.7943 50.1664 66.1892 57.0586C61.584 63.9507 54.6303 68.9336 46.6237 71.079C38.6171 73.2244 30.1035 72.3858 22.6693 68.7197C15.2351 65.0535 9.38711 58.8097 6.21502 51.1516C3.04292 43.4935 2.76302 34.9434 5.42745 27.0942C8.09189 19.245 13.5189 12.6321 20.6975 8.48759"
              stroke="#24BA61"
              stroke-width="6.40622"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M24.9683 38.0768L33.5099 46.6184L50.5932 29.5352"
              stroke="#24BA61"
              stroke-width="5.33852"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <h3>Request Sent</h3>

          <p>
            Your request has been sent for approval, you will be <br />
            notified once it gets approved by superadmin
          </p>
          <div class="d-flex justify-content-center align-items-center">
            <button
            (click)="forwardReqReset()"
              class="btn btn-warning close"
              style="width: 210px; opacity: 1; font-size: 16px; height: 48px"
              data-dismiss="modal"
              aria-label="Close"
            >
              Okay
            </button>
          </div>
        </div>
        }
      </div>
    </div>
  </div>
</div>
