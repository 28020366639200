import { Component, Renderer2 } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { InventoryService } from "src/app/service/inventory.service";
import {
  FormGroup,
  FormBuilder,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { provideNativeDateAdapter } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { CrmService } from "src/app/service/crm.service";
import { toast, NgxSonnerToaster } from "ngx-sonner";
import { alertModalWithoutConfirm } from "../../helpers/alert";
import { ForwardReqComponent } from "../shared/forward-req/forward-req.component";
import { ForwardServiceReqComponent } from "../shared/forward-service-req/forward-service-req.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NoDataComponent } from "../shared/no-data/no-data.component";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { FormsModule } from "@angular/forms";

interface Request {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-inventory-dashboard",
  standalone: true,
  imports: [
    RouterLink,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    ZXingScannerModule,
    NgxSonnerToaster,
    ForwardReqComponent,
    ForwardServiceReqComponent,
    NoDataComponent,
  ],
  templateUrl: "./inventory-dashboard.component.html",
  styleUrls: ["./inventory-dashboard.component.css", "../styles/inventory.css"],
})
export class InventoryDashboardComponent {
  viewAll: boolean = false;

  uploadAssetImageForm: FormGroup;
  selectedFiles: any[] = [];
  extendRequestForm: FormGroup;
  forwardRequestForm: FormGroup;
  forwardNewRequestForm: FormGroup;
  overdueExtendForm: FormGroup;
  declineReasonForm: FormGroup;
  chargingReminderForm: FormGroup;
  scanRequestForm: FormGroup;
  constructor(
    private inventoryService: InventoryService,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private crmService: CrmService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this.uploadAssetImageForm = this.fb.group({
      assetImage: [],
    });
    this.extendRequestForm = this.fb.group({
      extendTill: [],
    });

    // Forward Request Form Group
    this.forwardRequestForm = this.fb.group({
      maximumCost: ["", Validators.required],
      comment: ["", Validators.required],
      selectedAdmin: ["", Validators.required],
    });

    this.declineReasonForm = this.fb.group({
      rejectionReason: ["", Validators.required],
    });

    this.chargingReminderForm = this.fb.group({
      chargingBatteryType: ["", Validators.required],
      firstContactDate: ["", Validators.required],
      reason: ["", Validators.required],
    });

    this.scanRequestForm = this.fb.group({
      assetName: [""],
      productId: [""],
      location: [""],
    });
  }

  scannerEnabled: boolean = false;
  scannedProductName: string = "";
  scannedProductId: string = "";

  openScannerModal() {
    this.scannerEnabled = true;
    const modalElement = document.getElementById("qrScannerModal");
    if (modalElement) {
      modalElement.classList.add("show");
      modalElement.style.display = "block";
      modalElement.style.backgroundColor = "rgba(0,0,0,0.5)";
    }
    setTimeout(() => {
      this.scannerEnabled = true;
    }, 100);
  }

  closeScannerModal() {
    this.scannerEnabled = false;
    const modalElement = document.getElementById("qrScannerModal");
    if (modalElement) {
      modalElement.classList.remove("show");
      modalElement.style.display = "none";
    }
  }

  handleScanSuccess(data: string) {
    this.scannerEnabled = false;
    this.closeScannerModal();
    this.scannedProductId = data;
    if (this.scannedProductId) {
      console.log("Extracted Product ID:", this.scannedProductId);
      this.getInventoryData();
  } 
    else {
      console.error("Product ID not found");
    }
  }

  openSelectReqModal() {
    const modalElement = document.getElementById("selectReqModal");
    if (modalElement) {
      this.renderer.addClass(modalElement, "show");
      this.renderer.setStyle(modalElement, "display", "block");
    }
  }

  closeSelectReqModal() {
    const modalElement = document.getElementById("selectReqModal");
    if (modalElement) {
      this.renderer.removeClass(modalElement, "show");
      this.renderer.setStyle(modalElement, "display", "none");
    }
  }

  openServiceRequestModal() {
    this.router.navigate(['/inventory/service-request'], { queryParams: { openServiceReq: 'true',productId: this.scannedProductId } });
  }

  openInventoryRequestModal() {
    this.router.navigate(['/inventory/inventory-request'], { queryParams: { openInventoryReq: 'true',productId: this.scannedProductId } });
  }

  openManualServiceReqModal(){
    this.router.navigate(['/inventory/service-request'], { queryParams: { openServiceReq: 'true'}}); 
  }
  openManualInventoryReqModal(){
    this.router.navigate(['/inventory/inventory-request'], { queryParams: { openInventoryReq: 'true'}}); 
  }

  inventoryy: any[] = [];
  totalInventory: number;
  inventoryAccessory: any;
  barCodeImg: any;
  searchQuery: string;
  isAllInventory: boolean = false;
  productDetails: any;
  getInventoryData() {
    const field: any = {
      pageNum: 1,
      limit: 20,
      available: true,
    };

    this.inventoryService.getAllInventory(field).subscribe(
      (response: any) => {
        this.inventoryy = response.data.records.reverse();
        this.totalInventory = response.data.totalCounts;

        this.productDetails = this.inventoryy.find(
          (item) => item.productId === this.scannedProductId
        );

        if (this.productDetails) {
          console.log("Matched Product Details:", this.productDetails);
          this.openSelectReqModal();
          this.prefillForm();
        } else {
          console.error("Product not found.");
        }
      },
      (error) => {
        console.error("Error", error);
      }
    );
  }

  prefillForm() {
    if (this.productDetails) {
      this.scanRequestForm.patchValue({
        assetName: this.productDetails.name,
        productId: this.productDetails.productId,
        location: this.productDetails.location,
      });
    }
  }

  onScanReqSubmit() {
    console.log("Form submitted:", this.scanRequestForm.value);
  }

  defaultPendingRequestType: string = "Inventory Request";
  userDepartment: string | null = null;
  showUserDashboard = false;
  showAdminRequest = false;
  ngOnInit() {
    this.getInventoryData();
    this.getAdminUsersList();
    this.overdueAssetInventories();
    this.getAssignedInventoryData();
    this.onFilterPendingRequest(this.defaultPendingRequestType);
    this.onFilterApprovedRequest(this.defaultPendingRequestType);
    this.getInventoryRequest();
    this.getNewInventory();
    this.getServiceRequestData();
    this.getInventoryReturnRequest();
    this.getExtendData();
    this.inventoryOverview();
    this.assetReturnReq();
    this.userDepartment = localStorage.getItem("department");
    this.showUserDashboard = this.userDepartment === "HR";
    this.showAdminRequest = this.userDepartment === "ADMIN";
  }

  assignedInventoryId: string;
  selectedInventoryIndex: number | null = null;
  openReturn(assignedInventoryId: string, index: number) {
    this.assignedInventoryId = assignedInventoryId;
    this.selectedInventoryIndex = index;

    this.uploadAssetImageForm.reset();
    this.selectedFiles = [];

    this.uploadAssetImageForm.get("assetImage").enable();
  }

  overdueAssignedInventoryId: string;
  selectedOverdueIndex: number | null = null;
  openOverdueExtend(overdueAssignedInventoryId: string, index: number) {
    this.overdueAssignedInventoryId = overdueAssignedInventoryId;
    this.selectedOverdueIndex = index;
  }

  removeFile(file: any) {
    const index = this.selectedFiles.indexOf(file);
    if (index > -1) {
      this.selectedFiles.splice(index, 1);
    }
    if (this.selectedFiles.length < 4) {
      this.uploadAssetImageForm.get("assetImage").enable();
    }
  }

  onFileSelected(event: any) {
    if (event.target.files && event.target.files.length > 0) {
      const files = event.target.files;

      if (files.length + this.selectedFiles.length > 4) {
        alert("You can only upload a maximum of 4 files.");
        return;
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.selectedFiles.push({
            file: file,
            name: file.name,
            size: (file.size / 1024).toFixed(2) + " KB",
            preview: e.target.result,
          });
        };
        reader.readAsDataURL(file);
      }

      console.log("Selected Files", this.selectedFiles);

      if (this.selectedFiles.length >= 4) {
        this.uploadAssetImageForm.get("assetImage").disable();
      }
    }
  }

  showForwardSuccess = false;
  onReturnSubmit() {
    console.log(this.uploadAssetImageForm.value);

    if (this.selectedFiles.length === 0) {
      return alertModalWithoutConfirm(
        "warning",
        `Please upload atleast 1 image.`
      );
    }

    const formData = new FormData();
    formData.append("assignedInventoryId", this.assignedInventoryId);

    this.selectedFiles.forEach((fileData: any, index: number) => {
      formData.append(`files[${index}]`, fileData.file, fileData.name);
    });

    this.inventoryService.addAssignedReturn(formData).subscribe(
      (res) => {
        this.showForwardSuccess = true;
        if (this.selectedInventoryIndex !== null) {
          this.inventory.splice(this.selectedInventoryIndex, 1);
          this.selectedInventoryIndex = null;
          if (this.selectedInventoryIndex !== null) {
            this.inventory.splice(this.selectedInventoryIndex, 1);
            this.selectedInventoryIndex = null;

            this.totalAssigned--;
          }
        }
      },
      (err) => {
        console.error("failed", err);
      }
    );
  }

  formatExtendDate(date: Date): { day: number; month: number; year: number } {
    return {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
  }

  onRequestExtend() {
    const extendValue = {
      ...this.extendRequestForm.value,
    };
    const extendTill = new Date(extendValue.extendTill);
    const formattedExtendDate = this.formatExtendDate(extendTill);
    const extendRequest = {
      assignedInventoryId: this.assignedInventoryId,
      extendTill: formattedExtendDate,
    };

    this.inventoryService
      .assignedExtendRequest(extendRequest)
      .subscribe((response) => {
        console.log("submitted");
      });
  }

  // Overdue Extend
  onRequestOverdueExtend() {
    const extendValue = {
      ...this.extendRequestForm.value,
    };
    const extendTill = new Date(extendValue.extendTill);
    const formattedExtendDate = this.formatExtendDate(extendTill);
    const extendRequest = {
      assignedInventoryId: this.assignedInventoryId,
      extendTill: {
        day: extendTill.getDate(),
        month: extendTill.getMonth() + 1,
        year: extendTill.getFullYear(),
      },
    };

    this.inventoryService
      .overdueExtendRequest(extendRequest)
      .subscribe((response) => {
        console.log("submitted");
        this.overdueAssetInventories();
      });
  }

  //Assigned Assets Listings
  inventory: any[] = [];
  totalAssigned: any;
  getAssignedInventoryData() {
    const field: any = {
      pageNum: 1,
      limit: 100,
    };
    this.inventoryService.getAssignInv(field).subscribe(
      (response: any) => {
        this.inventory = response["data"].records.reverse();
        this.totalAssigned = response["data"].totalCounts;
      },
      (error) => {
        console.error("Error", error);
      }
    );
  }
  requests: Request[] = [
    { value: "Inventory Request", viewValue: "Inventory Request" },
    { value: "Service Request", viewValue: "Service Request" },
    { value: "New Inventory Request", viewValue: "New Inventory Request" },
    // { value: "Extend Request", viewValue: "Extend Request" },
    // { value: "Return Request", viewValue: "Return Request" },
  ];

  showUrgency: boolean = false;
  showReturnHead: boolean = false;
  showServicePending: boolean = false;
  onRequestSelect(value: string): void {
    console.log("Selected value:", value);
    this.onFilterPendingRequest(value);
  }

  onApprovedReqSelect(value: string) {
    console.log("Selected value:", value);
    this.onFilterApprovedRequest(value);
  }
  pendingReq = [];
  totalPending: any;

  onFilterPendingRequest(
    requestType: string,
    pageNum: number = 1,
    limit: number = 20
  ) {
    const getPendingReqValue: any = {
      requestType: requestType,
    };
    const field: any = {
      pageNum: pageNum,
      limit: limit,
    };

    this.showUrgency = requestType === "New Inventory Request";
    this.showReturnHead = requestType === "Return Request";
    this.showServicePending = requestType === "Service Request";

    this.inventoryService
      .getPendingRequest(getPendingReqValue, field)
      .subscribe((response) => {
        console.log("Pending Request");
        this.pendingReq = response["data"].records;
        this.totalPending = response["data"].totalCounts;
      });
  }

  approveInv = [];
  totalApproved = [];
  showServiceApproved: boolean = false;
  showAssetType: boolean = false;
  showApprovedReturn: boolean = false;
  onFilterApprovedRequest(
    requestType: string,
    pageNum: number = 1,
    limit: number = 20
  ) {
    const getApprovedReqValue: any = {
      requestType: requestType,
    };
    const field: any = {
      pageNum: pageNum,
      limit: limit,
    };
    this.showAssetType = requestType === "New Inventory Request";
    this.showServiceApproved = requestType === "Service Request";
    this.showApprovedReturn = requestType === "Return Request";
    this.inventoryService
      .getApprovedRequest(getApprovedReqValue, field)
      .subscribe((response) => {
        console.log("Approved Request", response);
        // alertModalWithoutConfirm("success", "Yor Product has been Deleted!")
        this.approveInv = response["data"].records;
        this.totalApproved = response["data"].totalCounts;
      });
  }

  overdueAssetInv: any;
  openOverdueAsset(index: number) {
    this.overdueAssetInv = this.overdueAssets[index];
    console.log({ overdueAssetName: this.overdueAssetInv });
  }

  overdueAssets = [];
  totalOverdueAssets: any;
  overdueAssetInventories() {
    const field: any = {
      pageNum: 1,
      limit: 100,
    };
    this.inventoryService.getOverdueAssets(field).subscribe((response) => {
      console.log(response);
      this.overdueAssets = response["data"].requests;
      this.totalOverdueAssets = response["data"].totalCounts;
    });
  }

  //ADMIN DASHBOARD

  //Inventory Request Listing
  inventoriesReq = [];
  totalInventoryRequest: any;
  getInventoryRequest() {
    const field: any = {
      pageNum: 1,
      limit: 20,
    };
    this.inventoryService
      .getAllInventoryRequest(field)
      .subscribe((response) => {
        console.log("Response", response);
        this.inventoriesReq = response["data"].records;
        this.totalInventoryRequest = response["data"].totalCounts;
      });
  }

  //New Inventory Request Listing
  newInventories = [];
  totalNewInventory: any;
  getNewInventory() {
    const field: any = {
      pageNum: 1,
      limit: 20,
    };
    this.inventoryService
      .getNewInventoryRequest(field)
      .subscribe((response) => {
        console.log("Response", response);
        this.newInventories = response["data"].records;
        this.totalNewInventory = response["data"].totalCounts;
      });
  }

  //Service Request Listing
  serviceRequest = [];
  totalService: any;
  getServiceRequestData() {
    const field: any = {
      pageNum: 1,
      limit: 20,
    };
    this.inventoryService.getServiceRequest(field).subscribe((res) => {
      this.serviceRequest = res["data"].records;
      this.totalService = res["data"].totalCounts;
    });
  }

  returnInv = [];
  totalReturn: any;
  getInventoryReturnRequest() {
    this.inventoryService.getInvReturnRequest().subscribe((response) => {
      console.log("RETURN REQUEST", response);
      this.returnInv = response["data"].records;
      this.totalReturn = response["data"].totalCounts;
    });
  }

  adminUsers: { value: string; viewValue: string; id: string }[] = [];
  getAdminUsersList() {
    const department = "ADMIN";
    const loggedInUsername = localStorage.getItem("username");
    const loggedInUserId = localStorage.getItem("guestID");
    this.crmService.getAnyUsers(department).subscribe(
      (res) => {
        let data = res["data"];
        this.adminUsers = data.map((adminUser) => {
          return {
            value: adminUser.userName,
            viewValue: adminUser.userName,
            id: adminUser._id,
          };
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  //Forward Request Form Submit
  selectedInventory: any = {};

  // Forward New Inventory Request
  // selectedNewInventory: any = {}

  extendInv = [];
  totalExtend: any;
  getExtendData() {
    const field: any = {
      pageNum:1,
      limit: 100,
    };
    this.inventoryService.getExtendRequest(field).subscribe((response) => {
      this.extendInv = response["data"].records;
      this.totalExtend = response["data"].totalCounts;
    });
  }

  totalAssets: any;
  rentalAssets: any;
  availableAsset: any;
  inventoryOverview() {
    this.inventoryService.getInventoryOverview().subscribe((response) => {
      console.log("Overview");
      this.totalAssets = response["data"].totalAssets;
      this.rentalAssets = response["data"].rentalAssets;
      this.availableAsset = response["data"].availableAsset;
    });
  }
  previewReturnImg: any;
  openReturnPreviewSlider(index: number) {
    this.previewReturnImg = this.returnInv[index];
    this.uploadAssetImageForm.reset();
    this.declineReasonForm.reset();
    this.showDeclineForm = false;
  }
  onShowMore() {
    this.router.navigate(['/inventory/inventory-request']);
  }

  // ASSET RETURN REQUEST ACCEPT
  acceptReturnRequest(requestId: any) {
    const acceptRequest = {
      action: "Accept",
      requestId: requestId,
      requestType: "Return Request",
    };
    this.inventoryService.adminAction(acceptRequest).subscribe((response) => {
      console.log("called");
      alertModalWithoutConfirm("success", "Request has been Approved!");
      window.location.reload();
      // toast.success('Request has been accepted')
      this.getInventoryReturnRequest();
    });
  }

  // ASSET RETURN REQUEST REJECT
  rejectReturnRequest(requestId: any) {
    const rejectValue = {
      ...this.declineReasonForm.value,
    };

    const getRejectValue = {
      rejectionReason: rejectValue.rejectionReason,
      action: "Rejected",
      requestId: requestId,
      requestType: "Return Request",
    };
    this.inventoryService.adminAction(getRejectValue).subscribe((response) => {
      console.log("Decline request", response);
    });
  }

  // ACCEPT INVENTORY REQUEST
  acceptInvRequest(requestId: any) {
    const acceptRequest = {
      action: "Accept",
      requestId: requestId,
      requestType: "Inventory Request",
    };
    this.inventoryService.adminAction(acceptRequest).subscribe((response) => {
      console.log("called");
      alertModalWithoutConfirm("success", "Request has been Approved!");
      window.location.reload();
      this.snackBar.open("Accepted", "OK");
      this.getInventoryRequest();
    });
  }

  // REJECT INVENTORY REQUEST
  declineInvRequest(requestId: any) {
    const declineRequest = {
      action: "Rejected",
      requestId: requestId,
      requestType: "Inventory Request",
    };
    this.inventoryService.adminAction(declineRequest).subscribe((response) => {
      console.log("called");
      this.snackBar.open("Declined", "OK");
      this.getInventoryRequest();
    });
  }

  // ACCEPT NEW INVENTORY REQUEST
  acceptNewInvRequest(requestId: any) {
    const acceptRequest = {
      action: "Accept",
      requestId: requestId,
      requestType: "New Inventory Request",
    };
    this.inventoryService.adminAction(acceptRequest).subscribe((response) => {
      this.snackBar.open("Accepted", "OK");
      console.log("called");
      this.getNewInventory();
    });
  }

  //Show Remarks Form on decline click
  showDeclineForm: boolean = false;
  onDeclineButtonClick() {
    this.showDeclineForm = true;
  }

  // REJECT NEW INVENTORY REQUEST
  declineNewInvRequest(requestId: any) {
    const declineRequest = {
      action: "Rejected",
      requestId: requestId,
      requestType: "New Inventory Request",
    };
    this.inventoryService.adminAction(declineRequest).subscribe((response) => {
      console.log("called");
      this.snackBar.open("Declined", "OK");
      this.getNewInventory();
    });
  }

  //ACCEPT SERVICE REQUEST
  acceptNewServiceRequest(requestId: any) {
    const acceptRequest = {
      action: "Accept",
      requestId: requestId,
      requestType: "Service Request",
    };
    this.inventoryService.adminAction(acceptRequest).subscribe((response) => {
      console.log("called");
      this.snackBar.open("Accepted", "OK");
      // window.location.reload();
      this.getServiceRequestData();
    });
  }

  // REJECT SERVICE REQUEST
  declineServiceRequest(requestId: any) {
    const declineRequest = {
      action: "Rejected",
      requestId: requestId,
      requestType: "Service Request",
    };
    this.inventoryService.adminAction(declineRequest).subscribe((response) => {
      console.log("called");
      this.snackBar.open("Declined", "OK");
      this.getServiceRequestData();
    });
  }
  
  newInvReqId : any = {};
  assetReturnInv = [];
  totalAssetReturn: any;
  assetReturnReq() {
    const field: any = {
      pageNum: 1,
      limit: 100,
    };
    this.inventoryService.getAssetReturnRequest(field).subscribe((response) => {
      console.log("ASSET Return", response);
      this.assetReturnInv = response["data"].requests;
      this.totalAssetReturn = response["data"].totalCounts;
    });
  }
}
