import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CollectionComponent } from "./component/collection/collection.component";
import { HomeComponent } from "./component/home/home.component";
import { LoginComponent } from "./component/login/login.component";
import { NotificationComponent } from "./component/notification/notification.component";
import { UploadComponent } from "./component/upload/upload.component";
import { DataComponent } from "./component/data/data.component";
import { SingleProjectComponent } from "./component/single-project/single-project.component";
import { AdminAccessComponent } from "./component/admin-access/admin-access.component";
import { CreateNewProjectComponent } from "./component/create-new-project/create-new-project.component";
import { LiveProjectListComponent } from "./component/live-project-list/live-project-list.component";
import { AuthGaurdGuard } from "./auth-gaurd.guard";
import { AlertsComponent } from "./component/alerts/alerts.component";
import { PageNotFoundComponentComponent } from "./component/page-not-found-component/page-not-found-component.component";
import { PlayListComponent } from "./component/play-list/play-list.component";
// import { GridViewComponent } from "./component/grid-view/grid-view.component";
// import { PublicViewComponent } from "./component/public-view/public-view.component";
// import { PrivateViewComponent } from "./component/private-view/private-view.component";
// import { NormalViewComponent } from "./component/normal-view/normal-view.component";
// import { SingleViewComponent } from "./component/single-view/single-view.component";
// import { PrivateSingleViewComponent } from "./component/private-single-view/private-single-view.component";
import { SinglePlaylistViewComponent } from "./component/single-playlist-view/single-playlist-view.component";
import { ConversationComponent } from "./component/conversation/conversation.component";
import { AuthLoginGuard } from "./auth-login.guard";
import { LeadsComponent } from "./component/crm/leads/leads.component";
import { CrmComponent } from "./component/crm/crm.component";
import { DashboardComponent } from "./component/crm/dashboard/dashboard.component";
import { LeadTrackComponent } from "./component/lead-track/lead-track.component";
import { InventoriesComponent } from "./component/inventory/inventories/inventories.component";
import { InventoryDashboardComponent } from "./component/inventory/inventory-dashboard/inventory-dashboard.component";
import { RequestsComponent } from "./component/inventory/requests/requests.component";
import { InventoryComponent } from "./component/inventory/inventory.component";
import { WorkstationComponent } from "./component/inventory/workstation/workstation.component";
import { ServiceRequestComponent } from "./component/inventory/service-request/service-request.component";
import { InventoryRequestComponent } from "./component/inventory/inventory-request/inventory-request.component";
import { NewInventoryComponent } from "./component/inventory/new-inventory/new-inventory.component";
import { AdminRequestComponent } from "./component/inventory/admin-request/admin-request.component";
import { ReturnRequestComponent } from "./component/inventory/return-request/return-request.component";


const routes: Routes = [
  { path: "login", component: LoginComponent, canActivate: [AuthLoginGuard] },
  { path: "home", component: HomeComponent, canActivate: [AuthGaurdGuard] },
  {
    path: "lead-track",
    component: LeadTrackComponent,
    canActivate: [AuthGaurdGuard],
  },
  {
    path: "collection",
    component: CollectionComponent,
    canActivate: [AuthGaurdGuard],
    children: [
      // {
      //   path: "grid-view",
      //   component: GridViewComponent,
      // },
      // {
      //   path: "normal-view",
      //   component: NormalViewComponent,
      // },
      // {
      //   path: "normal-view",
      //   loadChildren: () =>
      //     import("./component/normal-view/normal-view.module").then(
      //       (m) => m.NormalViewModule
      //     ),
      // },
      // {
      //   path: "public",
      //   // component: PublicViewComponent,
      //   children: [
      //     {
      //       path: "",
      //       component: PublicViewComponent,
      //     },
      //     {
      //       path: ":id",
      //       component: SingleViewComponent,
      //     },
      //   ],
      // },
      // {
      //   path: "private",
      //   // component: PrivateViewComponent,
      //   children: [
      //     {
      //       path: "",
      //       component: PrivateViewComponent,
      //     },
      //     {
      //       path: ":id",
      //       component: PrivateSingleViewComponent,
      //     },
      //   ],
      // },
    ],
  },
  {
    path: "crm",
    component: CrmComponent,
    children: [
      {
        path: "dashboard",
        component: DashboardComponent,
      },
      {
        path: "leads",
        component: LeadsComponent,
      },
    ],
  },
  {
    path: "inventory",
    component: InventoryComponent,
    children: [
      {
        path: "dashboard",
        component: InventoryDashboardComponent,
      },
      {
        path: "inventories",
        component: InventoriesComponent,
      },
      {
        path: "workstation",
        component: WorkstationComponent,
      },
      {
        path: "requests",
        component: RequestsComponent,
      },
      {
        path: "service-request",
        component: ServiceRequestComponent,
      },
      {
        path: "inventory-request",
        component: InventoryRequestComponent,
      },
      {
        path: "new-inventory-request",
        component: NewInventoryComponent,
      },
      {
        path: "return-request",
        component: ReturnRequestComponent,
      },
      {
        path: "admin-request",
        component: AdminRequestComponent
      }
    ],
  },
  { path: "upload", component: UploadComponent, canActivate: [AuthGaurdGuard] },
  {
    path: "edit/:id",
    component: UploadComponent,
    canActivate: [AuthGaurdGuard],
  },
  { path: "data", component: DataComponent, canActivate: [AuthGaurdGuard] },
  {
    path: "notification",
    component: NotificationComponent,
    canActivate: [AuthGaurdGuard],
  },

  {
    path: "collection/play-list",
    component: PlayListComponent,
  },
  {
    path: "single-playlist/:id",
    component: SinglePlaylistViewComponent,
  },

  {
    path: "single/:id",
    component: SingleProjectComponent,
    canActivate: [AuthGaurdGuard],
  },
  {
    path: "access",
    component: AdminAccessComponent,
    canActivate: [AuthGaurdGuard],
  },
  // { path: 'create-project' , component:CreateNewProjectComponent},
  {
    path: "create-project/:id",
    component: CreateNewProjectComponent,
    canActivate: [AuthGaurdGuard],
  },
  {
    path: "live-project-list",
    component: LiveProjectListComponent,
    canActivate: [AuthGaurdGuard],
  },

  { path: "alerts", component: AlertsComponent },

  {
    path: "access",
    component: AdminAccessComponent,
    canActivate: [AuthGaurdGuard],
  },
  {
    path: "conversation",
    component: ConversationComponent,
    canActivate: [AuthGaurdGuard],
  },
  { path: "", redirectTo: "/login", pathMatch: "full" },
  // { path: 'normal-view', loadChildren: () => import('./normal-view/normal-view/normal-view.module').then(m => m.NormalViewModule) },
  {
    path: "**",
    // redirectTo: "/login",
    // pathMatch: "full",
    component: PageNotFoundComponentComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
