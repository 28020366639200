@if (showChatContainer) {
<div class="single-chat">
  <img
    src="assets/single-chats.png"
    width="105px"
    alt=""
    class="img-fluid"
    (click)="closeContainer()"
  />
</div>
}
<div class="single-chat-wrapper">
  @if (!showChatContainer ) {
  <div class="single-chat-container">
    <div class="d-flex justify-content-between align-items-center">
      <div><h5 class="m-0">Chats</h5></div>
      <div
        (click)="showChatContainer = !showChatContainer"
        style="cursor: pointer"
      >
        <img style="width: 15px" src="assets/close1.png" alt="" />
      </div>
    </div>
    <hr />
    <div>
      <div>
        <input
          type="text"
          class="form-control filter-input"
          placeholder="Search in chats...."
          [(ngModel)]="searchTerm"
          (input)="updateFilteredUsers()"
        />
      </div>
      <div class="user-wrapper mt-3">
        @for (user of filteredUsers ; track user) {

        <div>
          @if(user.type !== 'group' && user.groupName !== 'Samurais'){
          <div
            class="d-flex align-items-start gap-3 seperate-chat justify-content-between"
            (click)="startChat(user.userId)"
          >
            <div class="d-flex align-items-center w-100">
              <img
                style="width: 40px; height: 40px"
                class="rounded-circle"
                [src]="user.profileImage"
                alt=""
              />
              <div class="ml-3 w-100">
                <p class="m-0 text-capitalize font-weight-bold">
                  {{ user.userName }}
                </p>

                <div class="m-0 recent-msg">
                  <span class="recent-text-msg">{{
                    user.lastMessage
                      ? user.lastMessage.message
                      : "No recent Message"
                  }}</span>
                  <div class="d-flex align-items-center">
                    @if (user.unreadCount && user.unreadCount != 0) {
                    <p class="msg-count" style="margin-bottom: 0 !important">
                      <span class="unread-msg">{{
                        showcount ? user.unreadCount : ""
                      }}</span>
                    </p>
                    }
                    <span class="text-muted small ml-2">
                      {{ user.lastMessageTimeAgo }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }
        
        </div>
        }
        <div class="chat-messages-wrapper">
          @if (showUsers1) {
          <div class="w-50 bg-white rounded-2 chat-msg">
            <div class="d-flex justify-content-between">
              <!-- <div *ngIf="showUsers1" (click)="showUsers1 = !showUsers1">
                  <img src="assets/back-btn.png" class="img-fluid" alt="" />
                </div> -->
              <div class="d-flex align-items-center">
                <img
                  style="width: 25px; height: 25px"
                  class="rounded-circle"
                  [src]="userProfileImg1"
                  alt=""
                />
                <h5
                  class="text-capitalize"
                  style="margin-left: 8px; margin-bottom: 0"
                >
                  {{ chatUserName }}
                </h5>
              </div>
              <div (click)="closeChat()" style="cursor: pointer">
                <img style="width: 15px" src="assets/close1.png" alt="" />
              </div>
            </div>
            <hr />
            <div class="chat-wrapper 55" #chatBody>
              @for (message of singleChatMessages; track message) {
              <div style="display: contents">
                @if(message.showDateHeader){
                <div
                  class="message-date d-flex justify-content-center align-items-center w-100"
                  style="margin-top: 10px; margin-bottom: 0 !important"
                >
                  <p>{{ message.updatedAt | date : "MMM d, yyyy" }}</p>
                </div>
                } @if (userId === message.sender_id) {
                <div class="message-box-holder 1">
                  @if (isDifferentTimestamp(message)) {
                  <div class="message-time">
                    <span>{{ message.creationTime | date : "hh:mm a" }}</span>
                  </div>
                  }
                  @if (message.message) {
                    <!-- <div class="receiver-box message-partner"> -->
                      <div class="message-box" 
                    (click)="openUrlInNewWindow(message.message)"
                    style="cursor: pointer">
                      @if (isURL(message.message)) {

                        <div
                          class=" url-preview"

                        >
                         <div class="d-flex thumb-box">
                          @if (message.meta?.image) {
                            <img [src]="message.meta.image" alt="Thumbnail" class="thumbnail-image" />
                          }
                  
                        <div class="meta-title-container">
                          @if (message.meta?.title) {
                            <p class="meta-title">{{ message.meta.title }}</p>
                          }
                          @if (message.meta?.description) {
                            <p class="meta-description">{{ message.meta.description }}</p>
                          }
                        </div>
                        </div>
                          <span [style.color]="'black'" [style.textDecoration]="'underline'">
                            {{ message.message }}
                          </span>
                        </div>
                      } @else {
                        <div>
                          <span [style.color]="'white'">
                            {{ message.message }}
                          </span>
                        </div>
                      }
                  
                      <div class="message-time d-flex justify-content-end">
                        <span>{{ message.createdAt | date: "hh:mm a" }}</span>
                      </div>
                    </div>
                    <!-- </div> -->
                  }
                   @if (message.url && isImage(message.url)) {
                  <div class="image-box-holder message-box">
                    <div class="image-box">
                      <img
                        [src]="message.url"
                        class="img-fluid"
                        alt="Image"
                      />
                    </div>
                  </div>
                  } @if (message.url && isVideo(message.url)) {
                  <div class="video-box-holder message-box">
                    <div class="video-box">
                      <video
                        [src]="message.url"
                        class="embed-responsive embed-responsive-16by9"
                        controls
                      ></video>
                      <div class="message-time">
                        <span>{{
                          message.creationTime | date : "hh:mm a"
                        }}</span>
                      </div>
                    </div>
                  </div>
                  } @if (message.url && isDocument(message.url)) {
                  <div class="document-box-holder message-box">
                    <div class="document-box download-doc">
                      <p>{{ message.url }}</p>
                      <a [href]="message.url" target="_blank">
                        <img src="../../../assets/dwnload-icon.svg" alt="" />
                      </a>
                    </div>
                    <div class="message-time">
                      <span>{{ message.createdAt | date : "hh:mm a" }}</span>
                    </div>
                  </div>
                  }
                </div>
                }
                 @if (userId !== message.sender_id) {
                <div class="receiver-message-box-holder 2">
                  @if (isDifferentTimestamp(message)) {
                  <div class="message-time">
                    <span>{{ message.creationTime | date : "hh:mm a" }}</span>
                  </div>
                  }
                 
                  @if (message.message) {
                    <div class="receiver-box message-partner" 
                  (click)="openUrlInNewWindow(message.message)"
                  style="cursor: pointer"
                 
                    >
                
                      @if (isURL(message.message)) {

                        <div
                          class=" url-preview"
                         
                        >
                        @if (message.meta?.image || message.meta?.title || message.meta?.description) {
                        <div class="d-flex thumb-box"  
                         >
                          @if (message.meta?.image) {
                            <img [src]="message.meta.image" alt="Thumbnail" class="thumbnail-image" />
                          }
                  
                        <div class="meta-title-container">
                          @if (message.meta?.title) {
                            <p class="meta-title">{{ message.meta.title }}</p>
                          }
                          @if (message.meta?.description) {
                            <p class="meta-description">{{ message.meta.description }}</p>
                          }
                        </div>
                        </div>
                      }
                          <span [style.color]="'black'" [style.textDecoration]="'underline'">
                            {{ message.message }}
                          </span>
                        </div>

                      } @else {
                        <div>
                          <span [style.color]="'black'">
                            {{ message.message }}
                          </span>
                        </div>
                      }
                  
                      <div class="message-time d-flex justify-content-end">
                        <span>{{ message.createdAt | date: "hh:mm a" }}</span>
                      </div>
                    </div>
                    <!-- </div> -->
                  }
                  
                   @if (message.url && isImage(message.url)) {
                  <div class="image-box-holder receiver-box">
                    <div class="image-box">
                      <img
                        [src]="message.url"
                        class="img-fluid"
                        alt="Image"
                      />
                      <div class="message-time">
                        <span>{{
                          message.creationTime | date : "hh:mm a"
                        }}</span>
                      </div>
                    </div>
                  </div>
                  } @if (message.url && isVideo(message.url)) {
                  <div class="video-box-holder receiver-box">
                    <div class="video-box">
                      <video
                        [src]="message.url"
                        class="embed-responsive embed-responsive-16by9"
                        controls
                      ></video>
                      <div class="message-time">
                        <span>{{
                          message.creationTime | date : "hh:mm a"
                        }}</span>
                      </div>
                    </div>
                  </div>
                  } @if (message.url && isDocument(message.url)) {
                  <div
                    class="document-box-holder message-box message-partner"
                  >
                    <div class="mess-box download-doc">
                      <p>{{ message.url }}</p>
                      <a [href]="message.url" target="_blank">
                        <img src="../../../assets/dwnload-icon.svg" />
                      </a>
                    </div>
                    <div class="message-time">
                      <span>{{ message.createdAt | date : "hh:mm a" }}</span>
                    </div>
                  </div>
                  }
                </div>
                }
              </div>
              }
              @if (isLoading) {
                <div class="upload-container">
                    <div class="progress-circle">
                        <svg viewBox="0 0 36 36">
                            <path class="circle-bg" d="M18 2.084a 15.916 15.916 0 1 1 0 31.832a 15.916 15.916 0 1 1 0 -31.832" />
                            <path class="circle-progress" d="M18 2.084a 15.916 15.916 0 1 1 0 31.832a 15.916 15.916 0 1 1 0 -31.832" 
                                stroke-dasharray="100, 100" stroke-dashoffset="100" />
                        </svg>
                    </div>
                    <span id="progress-text">{{ uploadProgress }}%</span>
                </div>
            }
            </div>
            <hr />

            @if (MetaDataDetails && MedataLength > 0 && MetaDataDetails.fileUrl) {
              @if (MetaDataDetails.thread && MetaDataDetails.thread.length > 0) {
                <ul class="filter-data">
                  <p><b>Main File:</b> {{ MetaDataDetails.fileName }}</p>
                  <div class="flex" style="width: 90%;word-break: break-word;">
                  <p style="margin-bottom: 0;"><b>Version - 1:</b></p>
                  <p>{{ MetaDataDetails.fileUrl }}</p>
                </div>
                  @if (MetaDataDetails.thread.length > 1) {
                    <ul class="sub-file">
                      @for (threadItem of MetaDataDetails.thread; track threadItem; let j = $index) {
                        <li (click)="selectSuggestion(MetaDataDetails.fileUrl, MetaDataDetails._id)">
                          <b>Version - {{ j + 2 }}:</b> {{ MetaDataDetails.fileUrl }}
                        </li>
                      }
                    </ul>
                  }
                </ul>
              }
            }
            
            
            
            
            <div class="chat-input-holder">
              <textarea
                type="text"
                class="chat-input"
                [(ngModel)]="newMessage"
                placeholder="Type a message"
                (input)="onInputChange()"
                (keydown.enter)="sendChatMessage(0)"
              ></textarea>
              <div class="position-relative mx-3">
                <input type="file" (change)="handleFileInput($event)" /><img
                  class="img-fluid img-icon"
                  src="assets/upload-pic.png"
                  alt=""
                />
              </div>
              <div class="cursorPointer" (click)="sendChatMessage(0)">
                <img
                  src="../../../assets/sendMessage.png"
                  class="message-send message-send1 m-auto d-block"
                />
              </div>
            </div>
          </div>
          }
          @if (showUsers2) {
            <div class="w-50 bg-white rounded-2 chat-msg">
              <div class="d-flex justify-content-between">
                <!-- <div *ngIf="showUsers1" (click)="showUsers1 = !showUsers1">
                    <img src="assets/back-btn.png" class="img-fluid" alt="" />
                  </div> -->
                <div class="d-flex align-items-center">
                  <img
                    style="width: 25px; height: 25px"
                    class="rounded-circle"
                    [src]="userProfileImg2"
                    alt=""
                  />
                  <h5
                    class="text-capitalize"
                    style="margin-left: 8px; margin-bottom: 0"
                  >
                    {{ chatUserName2 }}
                  </h5>
                </div>
                <div (click)="closeChat2()" style="cursor: pointer">
                  <img style="width: 15px" src="assets/close1.png" alt="" />
                </div>
              </div>
              <hr />
              <div class="chat-wrapper 2" #chatBody>
                @for (message of singleChatMessages2; track message) {
                <div style="display: contents">
                  @if(message.showDateHeader){
                  <div
                    class="message-date d-flex justify-content-center align-items-center w-100"
                    style="margin-top: 10px; margin-bottom: 0 !important"
                  >
                    <p>{{ message.updatedAt | date : "MMM d, yyyy" }}</p>
                  </div>
                  }
                   @if (userId === message.sender_id) {
                  <div class="message-box-holder 1">
                    @if (isDifferentTimestamp(message)) {
                    <div class="message-time">
                      <span>{{ message.creationTime | date : "hh:mm a" }}</span>
                    </div>
                    }
                    @if (message.message) {
                      <!-- <div class="receiver-box message-partner"> -->
                        <div class="message-box" 
                      (click)="openUrlInNewWindow(message.message)"
                      style="cursor: pointer">
                        @if (isURL(message.message)) {

                          <div
                            class=" url-preview"
                          >
                           <div class="d-flex thumb-box">
                            @if (message.meta?.image) {
                              <img [src]="message.meta.image" alt="Thumbnail" class="thumbnail-image" />
                            }
                    
                          <div className="meta-title-container" style="padding-left: 8px;">
                            @if (message.meta?.title) {
                              <p class="meta-title">{{ message.meta.title }}</p>
                            }
                            @if (message.meta?.description) {
                              <p class="meta-description">{{ message.meta.description }}</p>
                            }
                          </div>
                          </div>
                            <span [style.color]="'black'" [style.textDecoration]="'underline'">
                              {{ message.message }}
                            </span>
                          </div>
                        } @else {
                          <div>
                            <span [style.color]="'black'">
                              {{ message.message }}
                            </span>
                          </div>
                        }
                    
                        <div class="message-time d-flex justify-content-end">
                          <span>{{ message.createdAt | date: "hh:mm a" }}</span>
                        </div>
                      </div>
                      <!-- </div> -->
                    }
                     @if (message.url && isImage(message.url)) {
                    <div class="image-box-holder message-box">
                      <div class="image-box">
                        <img
                          [src]="message.url"
                          class="img-fluid"
                          alt="Image"
                        />
                      </div>
                    </div>
                    } @if (message.url && isVideo(message.url)) {
                    <div class="video-box-holder message-box">
                      <div class="video-box">
                        <video
                          [src]="message.url"
                          class="embed-responsive embed-responsive-16by9"
                          controls
                        ></video>
                        <div class="message-time">
                          <span>{{
                            message.creationTime | date : "hh:mm a"
                          }}</span>
                        </div>
                      </div>
                    </div>
                    } @if (message.url && isDocument(message.url)) {
                    <div class="document-box-holder message-box">
                      <div class="document-box download-doc">
                        <p>{{ message.url }}</p>
                        <a [href]="message.url" target="_blank">
                          <img src="../../../assets/dwnload-icon.svg" alt="" />
                        </a>
                      </div>
                      <div class="message-time">
                        <span>{{ message.createdAt | date : "hh:mm a" }}</span>
                      </div>
                    </div>
                    }
                  </div>
                  }
                   @if (userId !== message.sender_id) {
                  <div class="receiver-message-box-holder 2">
                    @if (isDifferentTimestamp(message)) {
                    <div class="message-time">
                      <span>{{ message.creationTime | date : "hh:mm a" }}</span>
                    </div>
                    }
                   
                    @if (message.message) {
                      <div class="receiver-box message-partner" 
                     
                    (click)="openUrlInNewWindow(message.message)"
                    style="cursor: pointer"
                      >
                  
                        @if (isURL(message.message)) {

                          <div
                            class=" url-preview"
                           
                          >
                          <div class="d-flex thumb-box">
                            @if (message.meta?.image) {
                              <img [src]="message.meta.image" alt="Thumbnail" class="thumbnail-image" />
                            }
                    
                          <div class="meta-title-container">
                            @if (message.meta?.title) {
                              <p class="meta-title">{{ message.meta.title }}</p>
                            }
                            @if (message.meta?.description) {
                              <p class="meta-description">{{ message.meta.description }}</p>
                            }
                          </div>
                          </div>
                            <span [style.color]="'black'" [style.textDecoration]="'underline'">
                              {{ message.message }}
                            </span>
                          </div>
                        } @else {
                          <div>
                            <span [style.color]="'black'">
                              {{ message.message }}
                            </span>
                          </div>
                        }
                    
                        <div class="message-time d-flex justify-content-end">
                          <span>{{ message.createdAt | date: "hh:mm a" }}</span>
                        </div>
                      </div>
                      <!-- </div> -->
                    }
                    
                     @if (message.url && isImage(message.url)) {
                    <div class="image-box-holder receiver-box">
                      <div class="image-box">
                        <img
                          [src]="message.url"
                          class="img-fluid"
                          alt="Image"
                        />
                        <div class="message-time">
                          <span>{{
                            message.creationTime | date : "hh:mm a"
                          }}</span>
                        </div>
                      </div>
                    </div>
                    } @if (message.url && isVideo(message.url)) {
                    <div class="video-box-holder receiver-box">
                      <div class="video-box">
                        <video
                          [src]="message.url"
                          class="embed-responsive embed-responsive-16by9"
                          controls
                        ></video>
                        <div class="message-time">
                          <span>{{
                            message.creationTime | date : "hh:mm a"
                          }}</span>
                        </div>
                      </div>
                    </div>
                    } @if (message.url && isDocument(message.url)) {
                    <div
                      class="document-box-holder message-box message-partner"
                    >
                      <div class="mess-box download-doc">
                        <p>{{ message.url }}</p>
                        <a [href]="message.url" target="_blank">
                          <img src="../../../assets/dwnload-icon.svg" />
                        </a>
                      </div>
                      <div class="message-time">
                        <span>{{ message.createdAt | date : "hh:mm a" }}</span>
                      </div>
                    </div>
                    }
                  </div>
                  }
                </div>
                }
                
                @if (isLoading) {
                  <div class="upload-container">
                      <div class="progress-circle">
                          <svg viewBox="0 0 36 36">
                              <path class="circle-bg" d="M18 2.084a 15.916 15.916 0 1 1 0 31.832a 15.916 15.916 0 1 1 0 -31.832" />
                              <path class="circle-progress" d="M18 2.084a 15.916 15.916 0 1 1 0 31.832a 15.916 15.916 0 1 1 0 -31.832" 
                                  stroke-dasharray="100, 100" stroke-dashoffset="100" />
                          </svg>
                      </div>
                      <span id="progress-text">{{ uploadProgress }}%</span>
                  </div>
              }
              </div>
              <hr />

              @if (MetaDataDetails && MedataLength > 0 && MetaDataDetails.fileUrl) {
                @if (MetaDataDetails.thread && MetaDataDetails.thread.length > 0) {
                  <ul class="filter-data">
                    <p><b>Main File:</b> {{ MetaDataDetails.fileName }}</p>
                    <div class="flex" style="width: 90%;word-break: break-word;">
                    <p style="margin-bottom: 0;"><b>Version - 1:</b></p>
                    <p>{{ MetaDataDetails.fileUrl }}</p>
                  </div>
                    @if (MetaDataDetails.thread.length > 1) {
                      <ul class="sub-file">
                        @for (threadItem of MetaDataDetails.thread; track threadItem; let j = $index) {
                          <li (click)="selectSuggestion(MetaDataDetails.fileUrl, MetaDataDetails._id)">
                            <b>Version - {{ j + 2 }}:</b> {{ MetaDataDetails.fileUrl }}
                          </li>
                        }
                      </ul>
                    }
                  </ul>
                }
              }
              <div class="chat-input-holder">
                <textarea
                  type="text"
                  class="chat-input"
                  [(ngModel)]="newMessage2"
                  placeholder="Type a message"
                  (input)="onInputChange()"
                  (keydown.enter)="sendChatMessage(1)"
                ></textarea>
                <div class="position-relative mx-3">
                  <input type="file" (change)="handleFileInput2($event)" /><img
                    class="img-fluid img-icon"
                    src="assets/upload-pic.png"
                    alt=""
                  />
                </div>
                <div class="cursorPointer" (click)="sendChatMessage(1)">
                  <img
                    src="../../../assets/sendMessage.png"
                    class="message-send message-send1 m-auto d-block"
                  />
                </div>
              </div>
            </div>
            }
        </div>
      </div>
    </div>
  </div>
  }
</div>
