<!-- <app-header></app-header> -->
<div class="row main-container">
  <div class="side-nav-banner">
    <div class="side-menu">
      <ul>
        <li (click)="navigateToHome()">HOME</li>
        @for(project of projects; track project) {
        <li
          [class.disabled]="!accessibleProjects.includes(project)"
          [class.active]="selectedProject === project"
          (click)="
            accessibleProjects.includes(project) ? setProject(project) : null
          "
        >
          {{ project }}
        </li>
        }
      </ul>
    </div>
  </div>
  <div>
    @if(selectedProject === 'IIC-LAB'){
    <div class="iic-container-banner">
      @if(activeButton === 'Contact Us'){
      <div class="d-flex align-items-center justify-content-between">
        <h2 style="padding-bottom: 5px">{{ totalLeads }} Leads</h2>
      </div>
      }  @if( activeButton === 'News Letter'){
      <div class="d-flex align-items-center justify-content-between">
        <h2 style="padding-bottom: 5px">{{ this.newsLetterLength }} Leads</h2>
      </div>
      }
      @if( activeButton === 'Career'){
        <div class="d-flex align-items-center justify-content-between">
          <h2 style="padding-bottom: 5px">{{ this.careerLength }} Applications</h2>
        </div>
        }
      <div class="listing-types d-flex align-items-center justify-content-end">
        <button
        class="news-letter contact-us"
        [ngClass]="{'active': activeButton === 'Career'}"
        (click)="getCareer(); setActiveButton('Career')"
      >
        Career
      </button>
      
      <button
        class="news-letter contact-us"
        [ngClass]="{'active': activeButton === 'News Letter'}"
        (click)="getNewsLetter(); setActiveButton('News Letter')"
      >
        News Letter
      </button>
      
      <button
        class="contact-us"
        [ngClass]="{'active': activeButton === 'Contact Us'}"
        (click)="selectType('Contact Us'); setActiveButton('Contact Us')"
      >
        Contact Us
      </button>
      </div>
      <div
        class="menu-content"
        style="margin-top: 15px; display: flex; justify-content: flex-end"
      >
        <div class="calender-field">
          <div class="month-type">
            <!-- CALENDAR -->
            <!-- <mat-form-field
              class="example-form-field"
              style="
                --mat-form-field-filled-with-label-container-padding-bottom: 0px;
                --mat-form-field-container-height: 0px;
                --mat-form-field-filled-with-label-container-padding-top: 0px;
                --mdc-filled-text-field-container-color: #fff;
                --mat-form-field-container-vertical-padding: 10px;
              "
            >
              <mat-date-range-input [rangePicker]="rangePicker">
                <input
                  matStartDate
                  placeholder="Start date"
                  [(ngModel)]="startDate"
                />
                <input
                  matEndDate
                  placeholder="End date"
                  [(ngModel)]="endDate"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matIconSuffix
                [for]="rangePicker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #rangePicker>
                <mat-date-range-picker-actions>
                  <button
                    mat-button
                    matDateRangePickerCancel
                    (click)="clearLeads()"
                  >
                    Reset
                  </button>
                  <button
                    style="
                      --mdc-protected-button-container-color: #fecc28;
                      --mdc-protected-button-label-text-color: #000;
                    "
                    mat-raised-button
                    color="primary"
                    matDateRangePickerApply
                    (click)="searchLeadsListByDateRange()"
                  >
                    Apply
                  </button>
                </mat-date-range-picker-actions>
              </mat-date-range-picker>
            </mat-form-field> -->
          </div>
          @if(!careerTable){
          <button
            class="btn btn-primary track-btn download"
            type="button"
            (click)="exportToExcel()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M21 14a1 1 0 0 0-1 1v4a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-4a1 1 0 0 0-2 0v4a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-4a1 1 0 0 0-1-1m-9.71 1.71a1 1 0 0 0 .33.21a.94.94 0 0 0 .76 0a1 1 0 0 0 .33-.21l4-4a1 1 0 0 0-1.42-1.42L13 12.59V3a1 1 0 0 0-2 0v9.59l-2.29-2.3a1 1 0 1 0-1.42 1.42Z"
              />
            </svg>
          </button>
        }
        @if(careerTable){
          <button
            class="btn btn-primary track-btn download"
            type="button"
            (click)="exportToCareerExcel()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M21 14a1 1 0 0 0-1 1v4a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-4a1 1 0 0 0-2 0v4a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-4a1 1 0 0 0-1-1m-9.71 1.71a1 1 0 0 0 .33.21a.94.94 0 0 0 .76 0a1 1 0 0 0 .33-.21l4-4a1 1 0 0 0-1.42-1.42L13 12.59V3a1 1 0 0 0-2 0v9.59l-2.29-2.3a1 1 0 1 0-1.42 1.42Z"
              />
            </svg>
          </button>
        }
          @if(isAdminShare){
          <button
            class="btn btn-primary track-btn"
            type="button"
            data-toggle="modal"
            data-target="#shareLeadModal"
            (click)="sharedUsers()"
          >
            <svg
              class="share"
              xmlns="http://www.w3.org/2000/svg"
              width="1.2em"
              height="1.2em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M18 22q-1.25 0-2.125-.875T15 19q0-.175.025-.363t.075-.337l-7.05-4.1q-.425.375-.95.588T6 15q-1.25 0-2.125-.875T3 12t.875-2.125T6 9q.575 0 1.1.213t.95.587l7.05-4.1q-.05-.15-.075-.337T15 5q0-1.25.875-2.125T18 2t2.125.875T21 5t-.875 2.125T18 8q-.575 0-1.1-.212t-.95-.588L8.9 11.3q.05.15.075.338T9 12t-.025.363t-.075.337l7.05 4.1q.425-.375.95-.587T18 16q1.25 0 2.125.875T21 19t-.875 2.125T18 22"
              /></svg
            >Share
          </button>
          }
        </div>
      </div>

      <!-- Share Modal -->
      <div
        class="modal fade"
        id="shareLeadModal"
        #shareModal
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Share with..
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              @for (user of iicUsers; track user;) {
              <div class="d-flex justify-content-between shareDiv mb-1 mt-1">
                <div
                  style="
                    padding-bottom: 8px;
                    font-size: 16px;
                    font-weight: 600;
                    width: 100%;
                  "
                  (click)="toggleCheckbox(user)"
                >
                  {{ user.username }}
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      [checked]="user.checked"
                      (change)="toggleCheckbox(user)"
                    />
                  </div>
                </div>
              </div>
              }
            </div>
            <div class="modal-footer" style="border-top: none">
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                class="close d-flex justify-flex-end continue"
                (click)="shareWithUsers(); closeShareLeadmodal()"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
      @if(activeButton === 'Contact Us'){
        @if(isSpinnerLoader){
          <div class="loader-wrapper">
            <span class="loader"></span>
          </div>
          }
      <div class="demo-tracker-banner">
        <table class="table leads-content">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Contact No.</th>
              <th scope="col">Message</th>
              <th scope="col">Assigned To</th>
              <th scope="col">Status</th>
              <!-- <th scope="col">Note</th> -->
              <th scope="col">Assign To</th>
            </tr>
          </thead>
          @for (lead of filteredLeads; track lead;let i=$index) {
          <tbody>
            <tr>
              <td
                style="width: 350px"
                data-toggle="modal"
                data-target="#contactLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p class="name">{{ lead.createdAt | date : "dd-MM-yyyy" }}</p>
              </td>
              <td
                data-toggle="modal"
                data-target="#contactLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p class="name">{{ lead.name }}</p>
              </td>
              <td
                class="email"
                data-toggle="modal"
                data-target="#contactLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p
                  class=""
                  data-toggle="tooltip"
                  data-placement="top"
                  title="{{ lead.email }}"
                >
                  {{ lead.email }}
                </p>
              </td>

              <td
                data-toggle="modal"
                data-target="#contactLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p class="contact">{{ lead.phoneNo }}</p>
              </td>

              <td
                data-toggle="modal"
                data-target="#contactLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p
                  class="message"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="{{ lead.message }}"
                >
                  {{ lead.message }}
                </p>
              </td>

              <td
                data-toggle="modal"
                data-target="#contactLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p style="height: 29px">
                  {{ lead.assignedToString }}
                </p>
              </td>
              <td
                data-toggle="modal"
                data-target="#contactLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p>{{ lead.status }}</p>
              </td>
              <td class="d-flex justify-content-between align-items-center">
                <button
                  (click)="getALLUsersList()"
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="assign-button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ selectedUser[i] ? selectedUser[i] : "Users" }}

                  <svg
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.6"
                      d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                      stroke="#092C4C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>

                <div class="dropdown-menu" aria-labelledby="sales-rep-button">
                  @for (user of iicUsers; track user) {
                  <a class="dropdown-item" (click)="assignToUser(user, lead, i)"
                    >{{ user.username }}
                  </a>
                  }
                </div>
              </td>
            </tr>
          </tbody>
          }
        </table>
        @if(showNoDataMessage){
        <div
          class="d-flex justify-content-center"
          style="
            font-size: 40px;
            width: 100%;
            margin-top: 70px;
            flex-direction: column;
            position: relative;
          "
        >
          <img
            src="../../../assets/no-data.jpg"
            alt=""
            width="500px"
            style="position: absolute; left: 30%; top: 15%"
          />
          <p style="position: absolute; left: 37%">No Leads Available</p>
        </div>
        }
      </div>
      }
       @if( activeButton === 'News Letter'){
        @if(isSpinnerLoader){
          <div class="loader-wrapper">
            <span class="loader"></span>
          </div>
          }
      <div class="demo-tracker-banner">
        <table class="table leads-content">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
            </tr>
          </thead>
          @for (lead of newsLetter; track lead;let i=$index) {
          <tbody>
            <tr>
              <td
                style="width: 350px"
                data-toggle="modal"
                data-target="#contactLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p class="name">{{ lead.createdAt | date : "dd-MM-yyyy" }}</p>
              </td>
              <td
                data-toggle="modal"
                data-target="#contactLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p class="name">{{ lead.name }}</p>
              </td>
              <td
                class="email"
                data-toggle="modal"
                data-target="#contactLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p
                  class=""
                  data-toggle="tooltip"
                  data-placement="top"
                  title="{{ lead.email }}"
                >
                  {{ lead.email }}
                </p>
              </td>
            </tr>
          </tbody>

          }
        </table>
        @if(showNoDataMessage){
        <div
          class="d-flex justify-content-center"
          style="
            font-size: 40px;
            width: 100%;
            margin-top: 70px;
            flex-direction: column;
            position: relative;
          "
        >
          <img
            src="../../../assets/no-data.jpg"
            alt=""
            width="500px"
            style="position: absolute; left: 30%; top: 15%"
          />
          <p style="position: absolute; left: 37%">No Leads Available</p>
        </div>
        }
      </div>
      } 
      @if( activeButton === 'Career'){
        @if(isSpinnerLoader){
          <div class="loader-wrapper">
            <span class="loader"></span>
          </div>
          }
      <div class=" career-tracker-banner">
        <table class="">
          <thead>
            <tr>
              <th style="padding-left: 10px;">Date</th>
              <th>Name</th>
              <th>Email</th>
              <th>Contact</th>
              <th>Portfolio</th>
              <th>Linkdin URL</th>
              <th>Job Role</th>
              <th>Experience</th>
              <th>Notice Period</th>
              <th>Current CTC</th>
              <th>Expected CTC</th>
              <th>Resume</th>
            </tr>
          </thead>
          <tbody>
          @for (career of careersList; track career;let i=$index) {
            <tr>
              <td
              >
                <p class="name">{{ career.applicationDate }}</p>
              </td>
              <td
              >
                <p class="name">{{ career.name }}</p>
              </td>
              <td
                class="email"
              >
                <p
                  class=""
                  data-toggle="tooltip"
                  data-placement="top"
                  title="{{ career.email }}"
                >
                  {{ career.email }}
                </p>
              </td>
              <td
                class="email"
              >
                <p
                  class=""
                >
                  {{ career.phoneNumber }}
                </p>
              </td>
              <td
                class="email"
              >
              <p class="url"  data-toggle="tooltip"
              data-placement="top"
              title="{{ career.portFolioLink }}">
              <a href="{{career.portFolioLink}}" target="_blank">
                  {{ career.portFolioLink }}
                  </a>
                </p>
              </td>
              <td
                class="email"
              >
              <p class="url" data-toggle="tooltip"
              data-placement="top"
              title="{{ career.linkedinUsername }}">
                <a href="{{career.linkedinUsername}}" target="_blank">
                  {{ career.linkedinUsername }}
                </a>
              </p>
              </td>
              <td
                class="" 
              >
                <p class="" style="margin-left:10px; margin-right: 10px;">
                  {{ career.currentJobRole }}
                </p>
              </td>
              <td
                class=""
              >
                <p class="">
                  {{ career.experience }}
                </p>
              </td>
              <td
              class="" style="width:290px"
              >
                <p class="">
                  {{ career.noticePeriod }}
                </p>
              </td>
              <td
                class="" style="width:290px"
              >
                <p class="">
                  {{ career.currentCTC }}
                </p>
              </td>
              <td
              class="" style="width:290px"
              >
                <p class="">
                  {{ career.expectedCTC }}
                </p>
              </td>
              <td class="">
                <p class="resume">
                  <a style="color:#007bff;" (click)="downloadAndOpenPDF(career.Resume_URL)">View Resume</a>
                </p>
              </td>
            </tr>
          }
        </tbody>
        </table>
        @if(showNoDataMessage){
        <div
          class="d-flex justify-content-center"
          style="
            font-size: 40px;
            width: 100%;
            margin-top: 70px;
            flex-direction: column;
            position: relative;
          "
        >
          <img
            src="../../../assets/no-data.jpg"
            alt=""
            width="500px"
            style="position: absolute; left: 30%; top: 15%"
          />
          <p style="position: absolute; left: 37%">No Leads Available</p>
        </div>
        }
      </div>
      }
      @if(!showNoDataMessage && activeButton !== 'Career' || activeButton === 'Contact Us' || activeButton === 'News Letter') {
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" [ngClass]="{ disabled: currentPage === 1 }">
            <a
              class="page-link"
              href="javascript:void(0)"
              aria-label="Previous"
              (click)="currentPage !== 1 ? pageChanged(currentPage - 1) : null"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  d="m4 10l9 9l1.4-1.5L7 10l7.4-7.5L13 1z"
                />
              </svg>
            </a>
          </li>
          @for(page of visiblePages; track page){
          <li
            class="page-item"
            [ngClass]="{
              active: currentPage === page,
              hidden: !isPageVisible(page)
            }"
          >
            <a
              class="page-link"
              href="javascript:void(0)"
              (click)="pageChanged(page)"
              >{{ page }}</a
            >
          </li>
          }

          <li
            class="page-item"
            [ngClass]="{ disabled: currentPage === totalPages }"
          >
            <a
              class="page-link"
              href="javascript:void(0)"
              aria-label="Next"
              (click)="
                currentPage !== totalPages ? pageChanged(currentPage + 1) : null
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  d="M7 1L5.6 2.5L13 10l-7.4 7.5L7 19l9-9z"
                />
              </svg>
            </a>
          </li>
        </ul>
      </nav>
      } @for(single of filteredLeads; track single;let i=$index){
      <div
        class="modal fade iic-lead-banner"
        #iicContactLead
        id="contactLeadDetail"
        tabindex="-1"
        role="dialog"
        aria-labelledby="contactLeadDetailModalTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered new-lead"
          role="document"
        >
          <div class="modal-content" style="height: 640px">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Leads Details
              </h5>
              <div class="d-flex">
                <button
                  type="button"
                  class="close lead-close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img
                    src="../../assets/crm/create-lead-close.svg"
                    alt="close"
                  />
                </button>
              </div>
            </div>
            <div class="modal-body detail-content">
              <form>
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="comp-name">Date</label>
                    <p class="mb-0">
                      {{ singleLead?.createdAt | date : "dd-MM-yyyy" }}
                    </p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="phone">Name</label>
                    <p class="mb-0">{{ singleLead?.name }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputEmail">Email</label>
                    <p class="mb-0">{{ singleLead?.email }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputEmail">Contact No</label>
                    <p class="mb-0">{{ singleLead?.phoneNo }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputEmail">Assigned To</label>
                    <p class="mb-0">{{ singleLead?.assignedToString }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputEmail">Status</label>
                    <div class="dropdown lead-track-status">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="industry-button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <p
                          style="width: 130px; margin-bottom: 0"
                          class="track-status"
                        >
                          {{
                            selectedStatus[i] !== undefined &&
                            selectedStatus[i] !== ""
                              ? selectedStatus[i]
                              : singleLead?.status !== undefined &&
                                singleLead?.status !== ""
                              ? singleLead?.status
                              : "Select"
                          }}
                        </p>
                        <svg
                          width="11"
                          height="7"
                          viewBox="0 0 11 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.6"
                            d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                            stroke="#092C4C"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                      <div
                        class="dropdown-menu status-items"
                        aria-labelledby="industry-button"
                      >
                        @for (status of leadStatus; track status) {
                        <a
                          class="dropdown-item"
                          (click)="selectStatus(status, i, singleLead._id)"
                        >
                          {{ status }}
                        </a>
                        }
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="inputEmail">Message</label>
                    <p class="mb-0">{{ singleLead?.message }}</p>
                  </div>

                  <div class="form-group col-md-12 d-flex flex-column">
                    <label for="inputEmail">Note</label>
                    <textarea
                      [(ngModel)]="statusnote"
                      [ngModelOptions]="{ standalone: true }"
                      id="w3review"
                      name="w3review"
                      rows="4"
                      cols="50"
                    ></textarea>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                (click)="updateStatus(singleLead._id)"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
      }
    </div>
    } @if(selectedProject === 'V-RETAIL' ) {
    <div class="vretail-container-right">
      <h2 style="padding-bottom: 5px">{{ totalLeads }} Leads</h2>

      <div
        class="listing-types d-flex align-items-center justify-content-between"
      >
        <button
          class="demo"
          [class.active]="selectedType === 'Book Demo'"
          (click)="selectType('Book Demo')"
        >
          Book A Demo
        </button>
        <button
          class="contact-us"
          [class.active]="selectedType === 'Contact Us'"
          (click)="selectType('Contact Us')"
        >
          Contact Us
        </button>
        <button
          class="onboarding"
          [class.active]="selectedType === 'User Onboard'"
          (click)="selectType('User Onboard')"
        >
          Onboarding
        </button>
        <button
          class="onboarding"
          [class.active]="selectedType === 'Lead Magnet Features'"
          (click)="selectType('Lead Magnet Features')"
        >
          Lead Magnet Features
        </button>
        <button
          class="onboarding"
          [class.active]="selectedType === 'Lead Magnet Book Demo'"
          (click)="selectType('Lead Magnet Book Demo')"
        >
          Lead Magnet Book Demo
        </button>
        <button
          class="onboarding"
          [class.active]="selectedType === 'Lead Magnet Pricing'"
          (click)="selectType('Lead Magnet Pricing')"
        >
          Lead Magnet Pricing
        </button>
      </div>

      <div
        class="vretail-menu-content"
        style="margin-top: 15px; display: flex; justify-content: flex-end"
      >
        <div class="calender-field">
          <div class="month-type">
            <!-- CALENDAR -->
            <!-- <mat-form-field
              class="example-form-field"
              style="
                --mat-form-field-filled-with-label-container-padding-bottom: 0px;
                --mat-form-field-container-height: 0px;
                --mat-form-field-filled-with-label-container-padding-top: 0px;
                --mdc-filled-text-field-container-color: #fff;
                --mat-form-field-container-vertical-padding: 10px;
              "
            >
              <mat-date-range-input [rangePicker]="rangePicker">
                <input
                  matStartDate
                  placeholder="Start date"
                  [(ngModel)]="startDate"
                />
                <input
                  matEndDate
                  placeholder="End date"
                  [(ngModel)]="endDate"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matIconSuffix
                [for]="rangePicker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #rangePicker>
                <mat-date-range-picker-actions>
                  <button
                    mat-button
                    matDateRangePickerCancel
                    (click)="clearLeads()"
                  >
                    Reset
                  </button>
                  <button
                    style="
                      --mdc-protected-button-container-color: #fecc28;
                      --mdc-protected-button-label-text-color: #000;
                    "
                    mat-raised-button
                    color="primary"
                    matDateRangePickerApply
                    (click)="searchLeadsListByDateRange()"
                  >
                    Apply
                  </button>
                </mat-date-range-picker-actions>
              </mat-date-range-picker>
            </mat-form-field> -->
          </div>
        
          <button
            class="btn btn-primary track-btn download"
            type="button"
            (click)="exportToExcel()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M21 14a1 1 0 0 0-1 1v4a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-4a1 1 0 0 0-2 0v4a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-4a1 1 0 0 0-1-1m-9.71 1.71a1 1 0 0 0 .33.21a.94.94 0 0 0 .76 0a1 1 0 0 0 .33-.21l4-4a1 1 0 0 0-1.42-1.42L13 12.59V3a1 1 0 0 0-2 0v9.59l-2.29-2.3a1 1 0 1 0-1.42 1.42Z"
              />
            </svg>
          </button>
      

     
          @if(isAdminShare){
          <button
            class="btn btn-primary track-btn"
            type="button"
            data-toggle="modal"
            data-target="#shareLeadModal"
            (click)="sharedUsers()"
          >
            <svg
              class="share"
              xmlns="http://www.w3.org/2000/svg"
              width="1.2em"
              height="1.2em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M18 22q-1.25 0-2.125-.875T15 19q0-.175.025-.363t.075-.337l-7.05-4.1q-.425.375-.95.588T6 15q-1.25 0-2.125-.875T3 12t.875-2.125T6 9q.575 0 1.1.213t.95.587l7.05-4.1q-.05-.15-.075-.337T15 5q0-1.25.875-2.125T18 2t2.125.875T21 5t-.875 2.125T18 8q-.575 0-1.1-.212t-.95-.588L8.9 11.3q.05.15.075.338T9 12t-.025.363t-.075.337l7.05 4.1q.425-.375.95-.587T18 16q1.25 0 2.125.875T21 19t-.875 2.125T18 22"
              /></svg
            >Share
          </button>
          }
        </div>
      </div>

      <!-- Share Modal -->
      <div
        class="modal fade"
        id="shareLeadModal"
        #shareLeadModal
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Share with..
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              @for (user of iicUsers; track user;) {
              <div class="d-flex justify-content-between shareDiv mb-1 mt-1">
                <div
                  style="
                    padding-bottom: 8px;
                    font-size: 16px;
                    font-weight: 600;
                    width: 100%;
                  "
                  (click)="toggleCheckbox(user)"
                >
                  {{ user.username }}
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="{{ 'checkbox-' + user.id }}"
                      [checked]="user.checked"
                      (change)="toggleCheckbox(user)"
                    />
                  </div>
                </div>
              </div>
              }
            </div>
            <div class="modal-footer" style="border-top: none">
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                class="close d-flex justify-flex-end continue"
                (click)="shareWithUsers(); closeShareLeadmodal()"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
      @if(selectedType === 'Book Demo'){
        @if(isSpinnerLoader){
          <div class="loader-wrapper">
            <span class="loader"></span>
          </div>
          }
      <div class="demo-tracker-banner">
        @if(showNoDataMessage){
        <div
          class="d-flex justify-content-center"
          style="
            font-size: 40px;
            width: 100%;
            margin-top: 70px;
            flex-direction: column;
            position: relative;
          "
        >
          <img
            src="../../../assets/no-data.jpg"
            alt=""
            width="500px"
            style="position: absolute; left: 30%; top: 15%"
          />
          <p style="position: absolute; left: 37%">No Leads Available</p>
        </div>
        } @else { @if (!isShimmerLoading){
        <table class="table leads-content">
          <thead>
            <tr>
              <th scope="col" style="width: 200px">Date</th>
              <th scope="col" style="width: 400px">Scheduled Date</th>
              <th scope="col" style="width: 400px">Scheduled Time</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col" style="width: 350px">Assigned To</th>
              <th scope="col">Status</th>
              <th scope="col">Assign To</th>
            </tr>
          </thead>
          <tbody>
            @for(lead of filteredLeads; track lead;let i=$index){

            <tr>
              <td
                data-toggle="modal"
                data-target="#bookDemoVrLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p style="width: 105px" class="">
                  {{ lead?.createdAt | date : "dd-MM-yyyy" }}
                </p>
              </td>
              <td
                data-toggle="modal"
                data-target="#bookDemoVrLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p></p>
              </td>
              <td
                data-toggle="modal"
                data-target="#bookDemoVrLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p class="name"></p>
              </td>
              <td
                data-toggle="modal"
                data-target="#bookDemoVrLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p class="name">{{ lead.name }}</p>
              </td>
              <td
                class="email"
                data-toggle="modal"
                data-target="#bookDemoVrLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p
                  style="width: 180px; word-wrap: break-word"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="{{ lead.email }}"
                >
                  {{ lead.email }}
                </p>
              </td>

              <td
                data-toggle="modal"
                data-target="#bookDemoVrLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p>{{ lead.assignedToString }}</p>
              </td>
              <td
                data-toggle="modal"
                data-target="#bookDemoVrLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p>{{ lead.status }}</p>
              </td>

              <td>
                <button
                  (click)="getALLUsersList()"
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="assign-button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ selectedUser[i] ? selectedUser[i] : "Users" }}

                  <svg
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.6"
                      d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                      stroke="#092C4C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <div class="dropdown-menu" aria-labelledby="sales-rep-button">
                  @for (user of iicUsers; track user) {
                  <a class="dropdown-item" (click)="assignToUser(user, lead, i)"
                    >{{ user.username }}
                  </a>
                  }
                </div>
              </td>
            </tr>

            }
          </tbody>
        </table>
        } }
      </div>
      @if(!showNoDataMessage && !isSpinnerLoader){
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" [ngClass]="{ disabled: currentPage === 1 }">
            <a
              class="page-link"
              href="javascript:void(0)"
              aria-label="Previous"
              (click)="currentPage !== 1 ? pageChanged(currentPage - 1) : null"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  d="m4 10l9 9l1.4-1.5L7 10l7.4-7.5L13 1z"
                />
              </svg>
            </a>
          </li>
          @for(page of visiblePages; track page){
          <li
            class="page-item"
            [ngClass]="{
              active: currentPage === page,
              hidden: !isPageVisible(page)
            }"
          >
            <a
              class="page-link"
              href="javascript:void(0)"
              (click)="pageChanged(page)"
              >{{ page }}</a
            >
          </li>
          }

          <li
            class="page-item"
            [ngClass]="{ disabled: currentPage === totalPages }"
          >
            <a
              class="page-link"
              href="javascript:void(0)"
              aria-label="Next"
              (click)="
                currentPage !== totalPages ? pageChanged(currentPage + 1) : null
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  d="M7 1L5.6 2.5L13 10l-7.4 7.5L7 19l9-9z"
                />
              </svg>
            </a>
          </li>
        </ul>
      </nav>
      } @for(single of filteredLeads; track single;let i=$index){
      <div
        class="modal fade"
        #bookDemoVr
        id="bookDemoVrLeadDetail"
        tabindex="-1"
        role="dialog"
        aria-labelledby="bookDemoVrModalTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered new-lead"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Leads Details
              </h5>
              <div class="d-flex">
                <button
                  type="button"
                  class="close lead-close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img
                    src="../../assets/crm/create-lead-close.svg"
                    alt="close"
                  />
                </button>
              </div>
            </div>
            <div class="modal-body detail-content">
              <form>
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="comp-name">Date</label>
                    <p class="mb-0">
                      {{ singleLead?.createdAt | date : "dd-MM-yyyy" }}
                    </p>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="location">Scheduled Date</label>
                    <p class="mb-0"></p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="custmer">Scheduled Time</label>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="phone">Name</label>
                    <p class="mb-0">{{ singleLead?.name }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputEmail">Email</label>
                    <p class="mb-0">{{ singleLead?.email }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputEmail">Assigned To</label>
                    <p class="mb-0">{{ singleLead?.assignedToString }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputEmail">Status</label>
                    <div class="dropdown lead-track-status">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="industry-button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <p
                          style="width: 130px; margin-bottom: 0"
                          class="track-status"
                        >
                          {{
                            selectedStatus[i] !== undefined &&
                            selectedStatus[i] !== ""
                              ? selectedStatus[i]
                              : singleLead?.status !== undefined &&
                                singleLead?.status !== ""
                              ? singleLead?.status
                              : "Select"
                          }}
                        </p>
                        <svg
                          width="11"
                          height="7"
                          viewBox="0 0 11 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.6"
                            d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                            stroke="#092C4C"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                      <div
                        class="dropdown-menu status-items"
                        aria-labelledby="industry-button"
                      >
                        @for (status of leadStatus; track status) {
                        <a
                          class="dropdown-item"
                          (click)="selectStatus(status, i, singleLead._id)"
                        >
                          {{ status }}
                        </a>
                        }
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-md-12 d-flex flex-column">
                    <label for="inputEmail">Note</label>
                    <textarea
                      [(ngModel)]="statusnote"
                      [ngModelOptions]="{ standalone: true }"
                      id="w3review"
                      name="w3review"
                      rows="4"
                      cols="50"
                    ></textarea>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                (click)="updateStatus(singleLead._id)"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
      } } @if(selectedType === 'Contact Us'){
        @if(isSpinnerLoader){
          <div class="loader-wrapper">
            <span class="loader"></span>
          </div>
          }
      <div class="demo-tracker-banner">
        @if(showNoDataMessage){
        <div
          class="d-flex justify-content-center"
          style="
            font-size: 40px;
            width: 100%;
            margin-top: 70px;
            flex-direction: column;
            position: relative;
          "
        >
          <img
            src="../../../assets/no-data.jpg"
            alt=""
            width="500px"
            style="position: absolute; left: 30%; top: 15%"
          />
          <p style="position: absolute; left: 37%">No Leads Available</p>
        </div>
        } @else { @if (!isShimmerLoading){
        <table class="table leads-content">
          <thead style="background-color: #f0f2f4">
            <tr>
              <th style="width: 300px">Date</th>
              <th style="width: 300px">Name</th>
              <th style="width: 300px">Email</th>
              <th style="width: 150px">Contact No</th>
              <th style="width: 300px">Message</th>
              <th style="width: 300px">Tags</th>
              <th style="width: 300px">Assigned To</th>
              <th style="width: 300px">Status</th>
              <th style="width: 300px">Assign To</th>
            </tr>
          </thead>
          <tbody>
            @for(lead of filteredLeads; track lead;let i=$index){
            <tr>
              <td
                data-toggle="modal"
                data-target="#contactUsVrLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p style="width: 110px">
                  {{ lead.createdAt | date : "dd-MM-yyyy" }}
                </p>
              </td>
              <td
                data-toggle="modal"
                data-target="#contactUsVrLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p style="width: 80px">{{ lead.name }}</p>
              </td>
              <td
                class="email"
                data-toggle="modal"
                data-target="#contactUsVrLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p
                  style="width: 180px; word-wrap: break-word"
                  title="{{ lead.email }}"
                >
                  {{ lead.email }}
                </p>
              </td>
              <td
                style="width: 20%"
                data-toggle="modal"
                data-target="#contactUsVrLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p style="width: 120px">{{ lead.phoneNo }}</p>
              </td>
              <td
                class="message"
                data-toggle="modal"
                data-target="#contactUsVrLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p
                  style="width: 130px"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="{{ lead.message }}"
                >
                  {{ lead.message }}
                </p>
              </td>
              <td
                data-toggle="modal"
                data-target="#contactUsVrLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p style="width: 60px">{{ lead.reason }}</p>
              </td>

              <td
                data-toggle="modal"
                data-target="#contactUsVrLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p style="width: 130px">{{ lead.assignedToString }}</p>
              </td>
              <td
                data-toggle="modal"
                data-target="#contactUsVrLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p style="width: 145px">{{ lead.status }}</p>
              </td>
              <td class="d-flex user-list">
                <button
                  (click)="getALLUsersList()"
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="assign-button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ selectedUser[i] ? selectedUser[i] : "Users" }}
                  <svg
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.6"
                      d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                      stroke="#092C4C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <div
                  class="dropdown-menu"
                  aria-labelledby="sales-rep-button"
                  style="width: 100px"
                >
                  @for (user of iicUsers; track user) {
                  <a class="dropdown-item" (click)="assignToUser(user, lead, i)"
                    >{{ user.username }}
                  </a>
                  }
                </div>
              </td>
            </tr>
            }
          </tbody>
        </table>
        } }
      </div>
      @if(!showNoDataMessage && !isSpinnerLoader){
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" [ngClass]="{ disabled: currentPage === 1 }">
            <a
              class="page-link"
              href="javascript:void(0)"
              aria-label="Previous"
              (click)="currentPage !== 1 ? pageChanged(currentPage - 1) : null"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  d="m4 10l9 9l1.4-1.5L7 10l7.4-7.5L13 1z"
                />
              </svg>
            </a>
          </li>
          @for(page of visiblePages; track page){
          <li
            class="page-item"
            [ngClass]="{
              active: currentPage === page,
              hidden: !isPageVisible(page)
            }"
          >
            <a
              class="page-link"
              href="javascript:void(0)"
              (click)="pageChanged(page)"
              >{{ page }}</a
            >
          </li>
          }

          <li
            class="page-item"
            [ngClass]="{ disabled: currentPage === totalPages }"
          >
            <a
              class="page-link"
              href="javascript:void(0)"
              aria-label="Next"
              (click)="
                currentPage !== totalPages ? pageChanged(currentPage + 1) : null
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  d="M7 1L5.6 2.5L13 10l-7.4 7.5L7 19l9-9z"
                />
              </svg>
            </a>
          </li>
        </ul>
      </nav>
      } @for(single of filteredLeads; track single;let i=$index){
      <div
        class="modal fade"
        #contactUsVr
        id="contactUsVrLeadDetail"
        tabindex="-1"
        role="dialog"
        aria-labelledby="contactUsVrModalTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered new-lead"
          role="document"
        >
          <div class="modal-content" style="height: 640px">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Leads Details
              </h5>
              <div class="d-flex">
                <button
                  type="button"
                  class="close lead-close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img
                    src="../../assets/crm/create-lead-close.svg"
                    alt="close"
                  />
                </button>
              </div>
            </div>
            <div class="modal-body detail-content">
              <form>
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="comp-name">Date</label>
                    <p class="mb-0">
                      {{ singleLead?.createdAt | date : "dd-MM-yyyy" }}
                    </p>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="location">Name</label>
                    <p class="mb-0">{{ singleLead?.name }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="custmer">Email</label>
                    <p class="mb-0">{{ singleLead?.email }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="phone">Contact No</label>
                    <p class="mb-0">{{ singleLead?.phoneNo }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputEmail">Tags</label>
                    <p class="mb-0">{{ singleLead?.reason }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputEmail">Assigned To</label>
                    <p class="mb-0">{{ singleLead?.assignedToString }}</p>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="inputEmail">Message</label>
                    <p class="mb-0">{{ singleLead?.message }}</p>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="inputEmail">Status</label>
                    <div class="dropdown lead-track-status">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="industry-button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <p
                          style="width: 130px; margin-bottom: 0"
                          class="track-status"
                        >
                          {{
                            selectedStatus[i] !== undefined &&
                            selectedStatus[i] !== ""
                              ? selectedStatus[i]
                              : singleLead?.status !== undefined &&
                                singleLead?.status !== ""
                              ? singleLead?.status
                              : "Select"
                          }}
                        </p>
                        <svg
                          width="11"
                          height="7"
                          viewBox="0 0 11 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.6"
                            d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                            stroke="#092C4C"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                      <div
                        class="dropdown-menu status-items"
                        aria-labelledby="industry-button"
                      >
                        @for (status of leadStatus; track status) {
                        <a
                          class="dropdown-item"
                          (click)="selectStatus(status, i, singleLead._id)"
                        >
                          {{ status }}
                        </a>
                        }
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-12 d-flex flex-column">
                    <label for="inputEmail">Note</label>
                    <textarea
                      [(ngModel)]="statusnote"
                      [ngModelOptions]="{ standalone: true }"
                      id="w3review"
                      name="w3review"
                      rows="4"
                      cols="50"
                    ></textarea>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                (click)="updateStatus(singleLead._id)"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
      } } @if(selectedType === 'User Onboard'){
        @if(isSpinnerLoader){
          <div class="loader-wrapper">
            <span class="loader"></span>
          </div>
          }
      <div class="demo-tracker-banner">
        @if(showNoDataMessage){
        <div
          class="d-flex justify-content-center"
          style="
            font-size: 40px;
            width: 100%;
            margin-top: 70px;
            flex-direction: column;
            position: relative;
          "
        >
          <img
            src="../../../assets/no-data.jpg"
            alt=""
            width="500px"
            style="position: absolute; left: 30%; top: 15%"
          />
          <p style="position: absolute; left: 37%">No Leads Available</p>
        </div>
        } @else { @if (!isShimmerLoading){
        <table class="table leads-content">
          <thead style="background-color: #f0f2f4">
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Assigned To</th>
              <th scope="col">Contact No.</th>
              <th scope="col">Tags</th>
              <th scope="col">Status</th>
              <th scope="col">Assign To</th>
            </tr>
          </thead>
          <tbody>
            @for(lead of filteredLeads; track lead; let i=$index){
            <tr>
              <td
                data-toggle="modal"
                data-target="#userOnboardVr"
                (click)="singleLeadDetails(lead._id)"
              >
                <p class="name">{{ lead.createdAt | date : "dd-MM-yyyy" }}</p>
              </td>
              <td
                data-toggle="modal"
                data-target="#userOnboardVr"
                (click)="singleLeadDetails(lead._id)"
              >
                <p class="name">{{ lead.name }}</p>
              </td>
              <td
                class="email"
                data-toggle="modal"
                data-target="#userOnboardVr"
                (click)="singleLeadDetails(lead._id)"
              >
                <p
                  style="width: 180px; word-wrap: break-word"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="{{ lead.email }}"
                >
                  {{ lead.email }}
                </p>
              </td>

              <td
                data-toggle="modal"
                data-target="#userOnboardVr"
                (click)="singleLeadDetails(lead._id)"
              >
                <p>{{ lead.assignedToString }}</p>
              </td>
              <td
                data-toggle="modal"
                data-target="#userOnboardVr"
                (click)="singleLeadDetails(lead._id)"
              >
                <p>{{ lead.phoneNo }}</p>
              </td>
              <td
                data-toggle="modal"
                data-target="#userOnboardVr"
                (click)="singleLeadDetails(lead._id)"
              >
                <p>{{ lead.status }}</p>
              </td>
              <td
                data-toggle="modal"
                data-target="#userOnboardVr"
                (click)="singleLeadDetails(lead._id)"
              >
                <p>{{ lead.status }}</p>
              </td>
              <td class="d-flex">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="assign-button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ selectedUser[i] ? selectedUser[i] : "Users" }}
                  <svg
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.6"
                      d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                      stroke="#092C4C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <div class="dropdown-menu" aria-labelledby="sales-rep-button">
                  @for (user of iicUsers; track user) {

                  <a class="dropdown-item" (click)="assignToUser(user, lead, i)"
                    >{{ user.username }}
                  </a>
                  }
                </div>
              </td>
            </tr>
            }
          </tbody>
        </table>
        } }
      </div>
      @if(!showNoDataMessage && !isSpinnerLoader){
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" [ngClass]="{ disabled: currentPage === 1 }">
            <a
              class="page-link"
              href="javascript:void(0)"
              aria-label="Previous"
              (click)="currentPage !== 1 ? pageChanged(currentPage - 1) : null"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  d="m4 10l9 9l1.4-1.5L7 10l7.4-7.5L13 1z"
                />
              </svg>
            </a>
          </li>
          @for(page of visiblePages; track page){
          <li
            class="page-item"
            [ngClass]="{
              active: currentPage === page,
              hidden: !isPageVisible(page)
            }"
          >
            <a
              class="page-link"
              href="javascript:void(0)"
              (click)="pageChanged(page)"
              >{{ page }}</a
            >
          </li>
          }

          <li
            class="page-item"
            [ngClass]="{ disabled: currentPage === totalPages }"
          >
            <a
              class="page-link"
              href="javascript:void(0)"
              aria-label="Next"
              (click)="
                currentPage !== totalPages ? pageChanged(currentPage + 1) : null
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  d="M7 1L5.6 2.5L13 10l-7.4 7.5L7 19l9-9z"
                />
              </svg>
            </a>
          </li>
        </ul>
      </nav>
      } @for(single of filteredLeads; track single;let i=$index){
      <div
        class="modal fade"
        #contactUsVr
        id="userOnboardVr"
        tabindex="-1"
        role="dialog"
        aria-labelledby="contactUsVrModalTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered new-lead"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Leads Details
              </h5>
              <div class="d-flex">
                <button
                  type="button"
                  class="close lead-close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img
                    src="../../assets/crm/create-lead-close.svg"
                    alt="close"
                  />
                </button>
              </div>
            </div>
            <div class="modal-body detail-content">
              <form>
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="comp-name">Date</label>
                    <p class="mb-0">
                      {{ singleLead?.createdAt | date : "dd-MM-yyyy" }}
                    </p>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="location">Name</label>
                    <p class="mb-0">{{ singleLead?.name }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="custmer">Email</label>
                    <p class="mb-0">{{ singleLead?.email }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputEmail">Assigned To</label>
                    <p class="mb-0">{{ singleLead?.assignedToString }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputEmail">Tags</label>
                    <p class="mb-0"></p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputEmail">Status</label>
                    <div class="dropdown lead-track-status">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="industry-button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <p
                          style="width: 130px; margin-bottom: 0"
                          class="track-status"
                        >
                          {{
                            selectedStatus[i] !== undefined &&
                            selectedStatus[i] !== ""
                              ? selectedStatus[i]
                              : singleLead?.status !== undefined &&
                                singleLead?.status !== ""
                              ? singleLead?.status
                              : "Select"
                          }}
                        </p>
                        <svg
                          width="11"
                          height="7"
                          viewBox="0 0 11 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.6"
                            d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                            stroke="#092C4C"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                      <div
                        class="dropdown-menu status-items"
                        aria-labelledby="industry-button"
                      >
                        @for (status of leadStatus; track status) {
                        <a
                          class="dropdown-item"
                          (click)="selectStatus(status, i, singleLead._id)"
                        >
                          {{ status }}
                        </a>
                        }
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-12 d-flex flex-column">
                    <label for="inputEmail">Note</label>
                    <textarea
                      [(ngModel)]="statusnote"
                      [ngModelOptions]="{ standalone: true }"
                      id="w3review"
                      name="w3review"
                      rows="4"
                      cols="50"
                    ></textarea>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                (click)="updateStatus(singleLead._id)"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
      } } @if(selectedType === 'Lead Magnet Features'){
        @if(isSpinnerLoader){
          <div class="loader-wrapper">
            <span class="loader"></span>
          </div>
          }
      <div class="demo-tracker-banner">
        @if(showNoDataMessage){
        <div
          class="d-flex justify-content-center"
          style="
            font-size: 40px;
            width: 100%;
            margin-top: 70px;
            flex-direction: column;
            position: relative;
          "
        >
          <img
            src="../../../assets/no-data.jpg"
            alt=""
            width="500px"
            style="position: absolute; left: 30%; top: 15%"
          />
          <p style="position: absolute; left: 37%">No Leads Available</p>
        </div>
        } @else {
        <table class="table leads-content">
          <thead style="background-color: #f0f2f4">
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Email</th>
              <th scope="col">Assigned To</th>
              <th scope="col">Status</th>
              <th scope="col">Assign To</th>
            </tr>
          </thead>
          <tbody>
            @for(lead of filteredLeads; track lead; let i=$index){
            <tr>
              <td
                data-toggle="modal"
                data-target="#leadMagnetVrLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p class="name">{{ lead.createdAt | date : "dd-MM-yyyy" }}</p>
              </td>
              <td
                class="email"
                data-toggle="modal"
                data-target="#leadMagnetVrLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p
                  style="width: 180px; word-wrap: break-word"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="{{ lead.email }}"
                >
                  {{ lead.email }}
                </p>
              </td>

              <td
                data-toggle="modal"
                data-target="#leadMagnetVrLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p>{{ lead.assignedToString }}</p>
              </td>
              <td
                data-toggle="modal"
                data-target="#leadMagnetVrLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p>{{ lead.status }}</p>
              </td>
              <td class="d-flex user-list">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="assign-button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ selectedUser[i] ? selectedUser[i] : "Users" }}
                  <svg
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.6"
                      d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                      stroke="#092C4C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <div class="dropdown-menu" aria-labelledby="sales-rep-button">
                  @for (user of iicUsers; track user) {

                  <a class="dropdown-item" (click)="assignToUser(user, lead, i)"
                    >{{ user.username }}
                  </a>
                  }
                </div>
              </td>
            </tr>
            }
          </tbody>
        </table>
        }
      </div>
      @if(!showNoDataMessage && !isSpinnerLoader){
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" [ngClass]="{ disabled: currentPage === 1 }">
            <a
              class="page-link"
              href="javascript:void(0)"
              aria-label="Previous"
              (click)="currentPage !== 1 ? pageChanged(currentPage - 1) : null"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  d="m4 10l9 9l1.4-1.5L7 10l7.4-7.5L13 1z"
                />
              </svg>
            </a>
          </li>
          @for(page of visiblePages; track page){
          <li
            class="page-item"
            [ngClass]="{
              active: currentPage === page,
              hidden: !isPageVisible(page)
            }"
          >
            <a
              class="page-link"
              href="javascript:void(0)"
              (click)="pageChanged(page)"
              >{{ page }}</a
            >
          </li>
          }

          <li
            class="page-item"
            [ngClass]="{ disabled: currentPage === totalPages }"
          >
            <a
              class="page-link"
              href="javascript:void(0)"
              aria-label="Next"
              (click)="
                currentPage !== totalPages ? pageChanged(currentPage + 1) : null
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  d="M7 1L5.6 2.5L13 10l-7.4 7.5L7 19l9-9z"
                />
              </svg>
            </a>
          </li>
        </ul>
      </nav>
      } @for(single of filteredLeads; track single;let i=$index){
      <div
        class="modal fade"
        id="leadMagnetVrLeadDetail"
        tabindex="-1"
        role="dialog"
        aria-labelledby="contactUsVrModalTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered new-lead"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Leads Details
              </h5>
              <div class="d-flex">
                <button
                  type="button"
                  class="close lead-close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img
                    src="../../assets/crm/create-lead-close.svg"
                    alt="close"
                  />
                </button>
              </div>
            </div>
            <div class="modal-body detail-content">
              <form>
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="comp-name">Date</label>
                    <p class="mb-0">
                      {{ singleLead?.createdAt | date : "dd-MM-yyyy" }}
                    </p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="custmer">Email</label>
                    <p class="mb-0">{{ singleLead?.email }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputEmail">Assigned To</label>
                    <p class="mb-0">{{ singleLead?.assignedToString }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputEmail">Status</label>
                    <div class="dropdown lead-track-status">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="industry-button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <p
                          style="width: 130px; margin-bottom: 0"
                          class="track-status"
                        >
                          {{
                            selectedStatus[i] !== undefined &&
                            selectedStatus[i] !== ""
                              ? selectedStatus[i]
                              : singleLead?.status !== undefined &&
                                singleLead?.status !== ""
                              ? singleLead?.status
                              : "Select"
                          }}
                        </p>
                        <svg
                          width="11"
                          height="7"
                          viewBox="0 0 11 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.6"
                            d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                            stroke="#092C4C"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                      <div
                        class="dropdown-menu status-items"
                        aria-labelledby="industry-button"
                      >
                        @for (status of leadStatus; track status) {
                        <a
                          class="dropdown-item"
                          (click)="selectStatus(status, i, singleLead._id)"
                        >
                          {{ status }}
                        </a>
                        }
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-12 d-flex flex-column">
                    <label for="inputEmail">Note</label>
                    <textarea
                      [(ngModel)]="statusnote"
                      [ngModelOptions]="{ standalone: true }"
                      id="w3review"
                      name="w3review"
                      rows="4"
                      cols="50"
                    ></textarea>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                (click)="updateStatus(singleLead._id)"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
      } } @if(selectedType === 'Lead Magnet Book Demo'){
        @if(isSpinnerLoader){
          <div class="loader-wrapper">
            <span class="loader"></span>
          </div>
          }
      <div class="demo-tracker-banner">
        @if(showNoDataMessage){
        <div
          class="d-flex justify-content-center"
          style="
            font-size: 40px;
            width: 100%;
            margin-top: 70px;
            flex-direction: column;
            position: relative;
          "
        >
          <img
            src="../../../assets/no-data.jpg"
            alt=""
            width="500px"
            style="position: absolute; left: 30%; top: 15%"
          />
          <p style="position: absolute; left: 37%">No Leads Available</p>
        </div>
        } @else { @if (!isShimmerLoading){
        <table class="table leads-content">
          <thead style="background-color: #f0f2f4">
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Email</th>
              <th scope="col">Assigned To</th>
              <th scope="col">Status</th>
              <th scope="col">Assign To</th>
            </tr>
          </thead>
          <tbody>
            @for(lead of filteredLeads; track lead; let i=$index){
            <tr>
              <td
                style="width: 30%"
                data-toggle="modal"
                data-target="#leadMagnetBookDemoVr"
                (click)="singleLeadDetails(lead._id)"
              >
                <p class="name">{{ lead.createdAt | date : "dd-MM-yyyy" }}</p>
              </td>
              <td
                class="email"
                data-toggle="modal"
                data-target="#leadMagnetBookDemoVr"
                (click)="singleLeadDetails(lead._id)"
              >
                <p
                  style="width: 180px; word-wrap: break-word"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="{{ lead.email }}"
                >
                  {{ lead.email }}
                </p>
              </td>

              <td
                data-toggle="modal"
                data-target="#leadMagnetBookDemoVr"
                (click)="singleLeadDetails(lead._id)"
              >
                <p>{{ lead.assignedToString }}</p>
              </td>
              <td
                data-toggle="modal"
                data-target="#leadMagnetBookDemoVr"
                (click)="singleLeadDetails(lead._id)"
              >
                <p>{{ lead.status }}</p>
              </td>
              <td class="d-flex">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="assign-button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ selectedUser[i] ? selectedUser[i] : "Users" }}
                  <svg
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.6"
                      d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                      stroke="#092C4C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <div class="dropdown-menu" aria-labelledby="sales-rep-button">
                  @for (user of iicUsers; track user) {

                  <a class="dropdown-item" (click)="assignToUser(user, lead, i)"
                    >{{ user.username }}
                  </a>
                  }
                </div>
              </td>
            </tr>
            }
          </tbody>
        </table>
        } }
      </div>
      @if(!showNoDataMessage && !isSpinnerLoader){
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" [ngClass]="{ disabled: currentPage === 1 }">
            <a
              class="page-link"
              href="javascript:void(0)"
              aria-label="Previous"
              (click)="currentPage !== 1 ? pageChanged(currentPage - 1) : null"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  d="m4 10l9 9l1.4-1.5L7 10l7.4-7.5L13 1z"
                />
              </svg>
            </a>
          </li>
          @for(page of visiblePages; track page){
          <li
            class="page-item"
            [ngClass]="{
              active: currentPage === page,
              hidden: !isPageVisible(page)
            }"
          >
            <a
              class="page-link"
              href="javascript:void(0)"
              (click)="pageChanged(page)"
              >{{ page }}</a
            >
          </li>
          }

          <li
            class="page-item"
            [ngClass]="{ disabled: currentPage === totalPages }"
          >
            <a
              class="page-link"
              href="javascript:void(0)"
              aria-label="Next"
              (click)="
                currentPage !== totalPages ? pageChanged(currentPage + 1) : null
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  d="M7 1L5.6 2.5L13 10l-7.4 7.5L7 19l9-9z"
                />
              </svg>
            </a>
          </li>
        </ul>
      </nav>
      } @for(single of filteredLeads; track single;let i=$index){
      <div
        class="modal fade"
        #contactUsVr
        id="leadMagnetBookDemoVr"
        tabindex="-1"
        role="dialog"
        aria-labelledby="contactUsVrModalTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered new-lead"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Leads Details
              </h5>
              <div class="d-flex">
                <button
                  type="button"
                  class="close lead-close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img
                    src="../../assets/crm/create-lead-close.svg"
                    alt="close"
                  />
                </button>
              </div>
            </div>
            <div class="modal-body detail-content">
              <form>
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="comp-name">Date</label>
                    <p class="mb-0">
                      {{ singleLead?.createdAt | date : "dd-MM-yyyy" }}
                    </p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="custmer">Email</label>
                    <p class="mb-0">{{ singleLead?.email }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputEmail">Assigned To</label>
                    <p class="mb-0">{{ singleLead?.assignedToString }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputEmail">Status</label>
                    <div class="dropdown lead-track-status">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="industry-button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <p
                          style="width: 130px; margin-bottom: 0"
                          class="track-status"
                        >
                          {{
                            selectedStatus[i] !== undefined &&
                            selectedStatus[i] !== ""
                              ? selectedStatus[i]
                              : singleLead?.status !== undefined &&
                                singleLead?.status !== ""
                              ? singleLead?.status
                              : "Select"
                          }}
                        </p>
                        <svg
                          width="11"
                          height="7"
                          viewBox="0 0 11 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.6"
                            d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                            stroke="#092C4C"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                      <div
                        class="dropdown-menu status-items"
                        aria-labelledby="industry-button"
                      >
                        @for (status of leadStatus; track status) {
                        <a
                          class="dropdown-item"
                          (click)="selectStatus(status, i, singleLead._id)"
                        >
                          {{ status }}
                        </a>
                        }
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-12 d-flex flex-column">
                    <label for="inputEmail">Note</label>
                    <textarea
                      [(ngModel)]="statusnote"
                      [ngModelOptions]="{ standalone: true }"
                      id="w3review"
                      name="w3review"
                      rows="4"
                      cols="50"
                    ></textarea>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                (click)="updateStatus(singleLead._id)"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
      } } @if(selectedType === 'Lead Magnet Pricing'){
        @if(isSpinnerLoader){
          <div class="loader-wrapper">
            <span class="loader"></span>
          </div>
          }
      <div class="demo-tracker-banner">
        @if(showNoDataMessage){
        <div
          class="d-flex justify-content-center"
          style="
            font-size: 40px;
            width: 100%;
            margin-top: 70px;
            flex-direction: column;
            position: relative;
          "
        >
          <img
            src="../../../assets/no-data.jpg"
            alt=""
            width="500px"
            style="position: absolute; left: 30%; top: 15%"
          />
          <p style="position: absolute; left: 37%">No Leads Available</p>
        </div>
        } @else { @if (!isShimmerLoading){
        <table class="table leads-content">
          <thead style="background-color: #f0f2f4">
            <tr>
              <th style="width: 20%" scope="col">Date</th>
              <th style="width: 30%" scope="col">Contact No.</th>
              <th style="width: 30%" scope="col">Assigned To</th>
              <th style="width: 30%" scope="col">Status</th>
              <th style="width: 30%" scope="col">Assign To</th>
            </tr>
          </thead>
          <tbody>
            @for(lead of filteredLeads; track lead; let i=$index){
            <tr>
              <td
                data-toggle="modal"
                data-target="#leadMagnetPricingVr"
                (click)="singleLeadDetails(lead._id)"
              >
                <p class="name">{{ lead.createdAt | date : "dd-MM-yyyy" }}</p>
              </td>
              <td
                data-toggle="modal"
                data-target="#leadMagnetPricingVr"
                (click)="singleLeadDetails(lead._id)"
              >
                <p class="name">{{ lead.phoneNo }}</p>
              </td>

              <td
                data-toggle="modal"
                data-target="#leadMagnetPricingVr"
                (click)="singleLeadDetails(lead._id)"
              >
                <p>{{ lead.assignedToString }}</p>
              </td>
              <td
                data-toggle="modal"
                data-target="#leadMagnetPricingVr"
                (click)="singleLeadDetails(lead._id)"
              >
                <p>{{ lead.status }}</p>
              </td>
              <td class="d-flex">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="assign-button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ selectedUser[i] ? selectedUser[i] : "Users" }}
                  <svg
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.6"
                      d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                      stroke="#092C4C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <div class="dropdown-menu" aria-labelledby="sales-rep-button">
                  @for (user of iicUsers; track user) {

                  <a class="dropdown-item" (click)="assignToUser(user, lead, i)"
                    >{{ user.username }}
                  </a>
                  }
                </div>
              </td>
            </tr>
            }
          </tbody>
        </table>
        } }
      </div>
      @if(!showNoDataMessage && !isSpinnerLoader){
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" [ngClass]="{ disabled: currentPage === 1 }">
            <a
              class="page-link"
              href="javascript:void(0)"
              aria-label="Previous"
              (click)="currentPage !== 1 ? pageChanged(currentPage - 1) : null"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  d="m4 10l9 9l1.4-1.5L7 10l7.4-7.5L13 1z"
                />
              </svg>
            </a>
          </li>
          @for(page of visiblePages; track page){
          <li
            class="page-item"
            [ngClass]="{
              active: currentPage === page,
              hidden: !isPageVisible(page)
            }"
          >
            <a
              class="page-link"
              href="javascript:void(0)"
              (click)="pageChanged(page)"
              >{{ page }}</a
            >
          </li>
          }

          <li
            class="page-item"
            [ngClass]="{ disabled: currentPage === totalPages }"
          >
            <a
              class="page-link"
              href="javascript:void(0)"
              aria-label="Next"
              (click)="
                currentPage !== totalPages ? pageChanged(currentPage + 1) : null
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  d="M7 1L5.6 2.5L13 10l-7.4 7.5L7 19l9-9z"
                />
              </svg>
            </a>
          </li>
        </ul>
      </nav>
      } @for(single of filteredLeads; track single;let i=$index){
      <div
        class="modal fade"
        id="leadMagnetPricingVr"
        tabindex="-1"
        role="dialog"
        aria-labelledby="contactUsVrModalTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered new-lead"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Leads Details
              </h5>
              <div class="d-flex">
                <button
                  type="button"
                  class="close lead-close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img
                    src="../../assets/crm/create-lead-close.svg"
                    alt="close"
                  />
                </button>
              </div>
            </div>
            <div class="modal-body detail-content">
              <form>
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="comp-name">Date</label>
                    <p class="mb-0">
                      {{ singleLead?.createdAt | date : "dd-MM-yyyy" }}
                    </p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="custmer">Email</label>
                    <p class="mb-0">{{ singleLead?.email }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputEmail">Assigned To</label>
                    <p class="mb-0">{{ singleLead?.assignedToString }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputEmail">Status</label>
                    <div class="dropdown lead-track-status">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="industry-button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <p
                          style="width: 130px; margin-bottom: 0"
                          class="track-status"
                        >
                          {{
                            selectedStatus[i] !== undefined &&
                            selectedStatus[i] !== ""
                              ? selectedStatus[i]
                              : singleLead?.status !== undefined &&
                                singleLead?.status !== ""
                              ? singleLead?.status
                              : "Select"
                          }}
                        </p>
                        <svg
                          width="11"
                          height="7"
                          viewBox="0 0 11 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.6"
                            d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                            stroke="#092C4C"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                      <div
                        class="dropdown-menu status-items"
                        aria-labelledby="industry-button"
                      >
                        @for (status of leadStatus; track status) {
                        <a
                          class="dropdown-item"
                          (click)="selectStatus(status, i, singleLead._id)"
                        >
                          {{ status }}
                        </a>
                        }
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-12 d-flex flex-column">
                    <label for="inputEmail">Note</label>
                    <textarea
                      [(ngModel)]="statusnote"
                      [ngModelOptions]="{ standalone: true }"
                      id="w3review"
                      name="w3review"
                      rows="4"
                      cols="50"
                    ></textarea>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      } }
    </div>

    } @if(selectedProject === 'V-ESTATE' ){
    <div class="vestate-container-right">
      <div class="d-flex align-items-center justify-content-between">
        <h2 style="padding-bottom: 5px">{{ totalLeads }} Leads</h2>
      </div>
      <div class="listing-types d-flex align-items-center justify-content-end">
        <button
          class="demo"
          [class.active]="selectedType === 'Book Demo'"
          (click)="selectType('Book Demo')"
        >
          Book A Demo
        </button>
        <button
          class="contact-us"
          [class.active]="selectedType === 'Contact Us'"
          (click)="selectType('Contact Us')"
        >
          Contact Us
        </button>
      </div>
      <div
        class="vretail-menu-content"
        style="margin-top: 15px; display: flex; justify-content: flex-end"
      >
        <div class="calender-field">
          <div class="month-type">
            <!-- CALENDAR -->
            <!-- <mat-form-field
              class="example-form-field"
              style="
                --mat-form-field-filled-with-label-container-padding-bottom: 0px;
                --mat-form-field-container-height: 0px;
                --mat-form-field-filled-with-label-container-padding-top: 0px;
                --mdc-filled-text-field-container-color: #fff;
                --mat-form-field-container-vertical-padding: 10px;
              "
            >
              <mat-date-range-input [rangePicker]="rangePicker">
                <input
                  matStartDate
                  placeholder="Start date"
                  [(ngModel)]="startDate"
                />
                <input
                  matEndDate
                  placeholder="End date"
                  [(ngModel)]="endDate"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matIconSuffix
                [for]="rangePicker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #rangePicker>
                <mat-date-range-picker-actions>
                  <button
                    mat-button
                    matDateRangePickerCancel
                    (click)="clearLeads()"
                  >
                    Reset
                  </button>
                  <button
                    style="
                      --mdc-protected-button-container-color: #fecc28;
                      --mdc-protected-button-label-text-color: #000;
                    "
                    mat-raised-button
                    color="primary"
                    matDateRangePickerApply
                    (click)="searchLeadsListByDateRange()"
                  >
                    Apply
                  </button>
                </mat-date-range-picker-actions>
              </mat-date-range-picker>
            </mat-form-field> -->
          </div>
          <button
            class="btn btn-primary track-btn download"
            type="button"
            (click)="exportToExcel()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M21 14a1 1 0 0 0-1 1v4a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-4a1 1 0 0 0-2 0v4a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-4a1 1 0 0 0-1-1m-9.71 1.71a1 1 0 0 0 .33.21a.94.94 0 0 0 .76 0a1 1 0 0 0 .33-.21l4-4a1 1 0 0 0-1.42-1.42L13 12.59V3a1 1 0 0 0-2 0v9.59l-2.29-2.3a1 1 0 1 0-1.42 1.42Z"
              />
            </svg>
          </button>
          @if(isAdminShare){
          <button
            class="btn btn-primary track-btn"
            type="button"
            data-toggle="modal"
            data-target="#shareLeadModal"
            (click)="sharedUsers()"
          >
            <svg
              class="share"
              xmlns="http://www.w3.org/2000/svg"
              width="1.2em"
              height="1.2em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M18 22q-1.25 0-2.125-.875T15 19q0-.175.025-.363t.075-.337l-7.05-4.1q-.425.375-.95.588T6 15q-1.25 0-2.125-.875T3 12t.875-2.125T6 9q.575 0 1.1.213t.95.587l7.05-4.1q-.05-.15-.075-.337T15 5q0-1.25.875-2.125T18 2t2.125.875T21 5t-.875 2.125T18 8q-.575 0-1.1-.212t-.95-.588L8.9 11.3q.05.15.075.338T9 12t-.025.363t-.075.337l7.05 4.1q.425-.375.95-.587T18 16q1.25 0 2.125.875T21 19t-.875 2.125T18 22"
              /></svg
            >Share
          </button>
          }
        </div>
      </div>

      <!-- Share Modal -->
      <div
        class="modal fade"
        id="shareLeadModal"
        #shareModal
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Share with..
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              @for (user of iicUsers; track user;) {
              <div class="d-flex justify-content-between shareDiv mb-1 mt-1">
                <div
                  style="
                    padding-bottom: 8px;
                    font-size: 16px;
                    font-weight: 600;
                    width: 100%;
                  "
                  (click)="toggleCheckbox(user)"
                >
                  {{ user.username }}
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      [checked]="user.checked"
                      (change)="toggleCheckbox(user)"
                    />
                  </div>
                </div>
              </div>
              }
            </div>
            <div class="modal-footer" style="border-top: none">
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                class="close d-flex justify-flex-end continue"
                (click)="shareWithUsers(); closeShareLeadmodal()"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>

      @if(selectedType === 'Book Demo'){
      <div class="demo-tracker-banner">
        @if(isSpinnerLoader){
          <div class="loader-wrapper">
            <span class="loader"></span>
          </div>
          }
        @if(showNoDataMessage){
        <div
          class="d-flex justify-content-center"
          style="
            font-size: 40px;
            width: 100%;
            margin-top: 70px;
            flex-direction: column;
            position: relative;
          "
        >
          <img
            src="../../../assets/no-data.jpg"
            alt=""
            width="500px"
            style="position: absolute; left: 30%; top: 15%"
          />
          <p style="position: absolute; left: 37%">No Leads Available</p>
        </div>
        } @else {
        <table class="table leads-content">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Assigned To</th>
              <th scope="col">Status</th>
              <th scope="col">Contact No.</th>
              <th scope="col">Assign To</th>
            </tr>
          </thead>
          <tbody>
            @for(lead of filteredLeads; track lead;let i=$index){
            <tr>
              <td
                data-toggle="modal"
                data-target="#bookDemoVe"
                (click)="singleLeadDetails(lead._id)"
              >
                <p class="name">{{ lead.createdAt | date : "dd-MM-yyyy" }}</p>
              </td>
              <td
                data-toggle="modal"
                data-target="#bookDemoVe"
                (click)="singleLeadDetails(lead._id)"
              >
                <p class="name">{{ lead.name }}</p>
              </td>
              <td
                data-toggle="modal"
                data-target="#bookDemoVe"
                (click)="singleLeadDetails(lead._id)"
              >
                <p class="">
                  {{ lead.emails }}
                </p>
              </td>

              <td
                data-toggle="modal"
                data-target="#bookDemoVe"
                (click)="singleLeadDetails(lead._id)"
              >
                <p>{{ lead.assignedToString }}</p>
              </td>
              <td
                data-toggle="modal"
                data-target="#bookDemoVe"
                (click)="singleLeadDetails(lead._id)"
              >
                <p>{{ lead.status }}</p>
              </td>
              <td
                data-toggle="modal"
                data-target="#bookDemoVe"
                (click)="singleLeadDetails(lead._id)"
              >
                <p>{{ lead.phoneNo }}</p>
              </td>
              <td class="d-flex">
                <button
                  (click)="getALLUsersList()"
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="assign-button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ selectedUser[i] ? selectedUser[i] : "Users" }}
                  <svg
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.6"
                      d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                      stroke="#092C4C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <div class="dropdown-menu" aria-labelledby="sales-rep-button">
                  @for (user of iicUsers; track user) {
                  <a class="dropdown-item" (click)="assignToUser(user, lead, i)"
                    >{{ user.username }}
                  </a>
                  }
                </div>
              </td>
            </tr>
            }
          </tbody>
        </table>
        }
      </div>
      @if(!showNoDataMessage && !isSpinnerLoader){
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" [ngClass]="{ disabled: currentPage === 1 }">
            <a
              class="page-link"
              href="javascript:void(0)"
              aria-label="Previous"
              (click)="currentPage !== 1 ? pageChanged(currentPage - 1) : null"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  d="m4 10l9 9l1.4-1.5L7 10l7.4-7.5L13 1z"
                />
              </svg>
            </a>
          </li>
          @for(page of visiblePages; track page){
          <li
            class="page-item"
            [ngClass]="{
              active: currentPage === page,
              hidden: !isPageVisible(page)
            }"
          >
            <a
              class="page-link"
              href="javascript:void(0)"
              (click)="pageChanged(page)"
              >{{ page }}</a
            >
          </li>
          }

          <li
            class="page-item"
            [ngClass]="{ disabled: currentPage === totalPages }"
          >
            <a
              class="page-link"
              href="javascript:void(0)"
              aria-label="Next"
              (click)="
                currentPage !== totalPages ? pageChanged(currentPage + 1) : null
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  d="M7 1L5.6 2.5L13 10l-7.4 7.5L7 19l9-9z"
                />
              </svg>
            </a>
          </li>
        </ul>
      </nav>
      } @for(single of filteredLeads; track single;let i=$index){
      <div
        class="modal fade"
        id="leadMagnetVrLeadDetail"
        tabindex="-1"
        role="dialog"
        aria-labelledby="contactUsVrModalTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered new-lead"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Leads Details
              </h5>
              <div class="d-flex">
                <button
                  type="button"
                  class="close lead-close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img
                    src="../../assets/crm/create-lead-close.svg"
                    alt="close"
                  />
                </button>
              </div>
            </div>
            <div class="modal-body detail-content">
              <form>
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="comp-name">Date</label>
                    <p class="mb-0">
                      {{ singleLead?.createdAt | date : "dd-MM-yyyy" }}
                    </p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="custmer">Email</label>
                    <p class="mb-0">{{ singleLead?.email }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputEmail">Assigned To</label>
                    <p class="mb-0">{{ singleLead?.assignedToString }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputEmail">Status</label>
                    <div class="dropdown lead-track-status">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="industry-button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <p
                          style="width: 130px; margin-bottom: 0"
                          class="track-status"
                        >
                          {{
                            selectedStatus[i] !== undefined &&
                            selectedStatus[i] !== ""
                              ? selectedStatus[i]
                              : singleLead?.status !== undefined &&
                                singleLead?.status !== ""
                              ? singleLead?.status
                              : "Select"
                          }}
                        </p>
                        <svg
                          width="11"
                          height="7"
                          viewBox="0 0 11 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.6"
                            d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                            stroke="#092C4C"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                      <div
                        class="dropdown-menu status-items"
                        aria-labelledby="industry-button"
                      >
                        @for (status of leadStatus; track status) {
                        <a
                          class="dropdown-item"
                          (click)="selectStatus(status, i, singleLead._id)"
                        >
                          {{ status }}
                        </a>
                        }
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-12 d-flex flex-column">
                    <label for="inputEmail">Note</label>
                    <textarea
                      [(ngModel)]="statusnote"
                      [ngModelOptions]="{ standalone: true }"
                      id="w3review"
                      name="w3review"
                      rows="4"
                      cols="50"
                    ></textarea>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                (click)="updateStatus(singleLead._id)"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
      } } @if(selectedType === 'Contact Us'){
        @if(isSpinnerLoader){
          <div class="loader-wrapper">
            <span class="loader"></span>
          </div>
          }
      <div class="demo-tracker-banner">
        @if(showNoDataMessage){
        <div
          class="d-flex justify-content-center"
          style="
            font-size: 40px;
            width: 100%;
            margin-top: 70px;
            flex-direction: column;
            position: relative;
          "
        >
          <img
            src="../../../assets/no-data.jpg"
            alt=""
            width="500px"
            style="position: absolute; left: 30%; top: 15%"
          />
          <p style="position: absolute; left: 37%">No Leads Available</p>
        </div>
        } @else {
        <table class="table leads-content">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Contact No</th>
              <th scope="col">Message</th>
              <th scope="col">Assigned To</th>
              <th scope="col">Status</th>
              <th scope="col">Assign To</th>
            </tr>
          </thead>
          <tbody>
            @for(lead of filteredLeads; track lead;let i=$index){
            <tr>
              <td
                data-toggle="modal"
                data-target="#contactUsVeLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p class="name">{{ lead.createdAt | date : "dd-MM-yyyy" }}</p>
              </td>
              <td
                data-toggle="modal"
                data-target="#contactUsVeLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p class="name">{{ lead.name }}</p>
              </td>
              <td
                class="email"
                data-toggle="modal"
                data-target="#contactUsVeLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p
                  data-toggle="tooltip"
                  data-placement="top"
                  title="{{ lead.emails }}"
                >
                  {{ lead.emails }}
                </p>
              </td>
              <td
                data-toggle="modal"
                data-target="#contactUsVeLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p>{{ lead.phoneNo }}</p>
              </td>
              <td
                class="message"
                data-toggle="modal"
                data-target="#contactUsVeLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p
                  data-toggle="tooltip"
                  data-placement="top"
                  title="{{ lead.message }}"
                >
                  {{ lead.message }}
                </p>
              </td>

              <td
                data-toggle="modal"
                data-target="#contactUsVeLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p>{{ lead.assignedToString }}</p>
              </td>
              <td
                data-toggle="modal"
                data-target="#contactUsVeLeadDetail"
                (click)="singleLeadDetails(lead._id)"
              >
                <p>{{ lead.status }}</p>
              </td>
              <td class="d-flex">
                <button
                  (click)="getALLUsersList()"
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="assign-button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ selectedUser[i] ? selectedUser[i] : "Users" }}
                  <svg
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.6"
                      d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                      stroke="#092C4C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <div class="dropdown-menu" aria-labelledby="sales-rep-button">
                  @for (user of iicUsers; track user) {
                  <a class="dropdown-item" (click)="assignToUser(user, lead, i)"
                    >{{ user.username }}
                  </a>
                  }
                </div>
              </td>
            </tr>
            }
          </tbody>
        </table>
        }
      </div>
      @if(!showNoDataMessage && !isSpinnerLoader){
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" [ngClass]="{ disabled: currentPage === 1 }">
            <a
              class="page-link"
              href="javascript:void(0)"
              aria-label="Previous"
              (click)="currentPage !== 1 ? pageChanged(currentPage - 1) : null"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  d="m4 10l9 9l1.4-1.5L7 10l7.4-7.5L13 1z"
                />
              </svg>
            </a>
          </li>
          @for(page of visiblePages; track page){
          <li
            class="page-item"
            [ngClass]="{
              active: currentPage === page,
              hidden: !isPageVisible(page)
            }"
          >
            <a
              class="page-link"
              href="javascript:void(0)"
              (click)="pageChanged(page)"
              >{{ page }}</a
            >
          </li>
          }

          <li
            class="page-item"
            [ngClass]="{ disabled: currentPage === totalPages }"
          >
            <a
              class="page-link"
              href="javascript:void(0)"
              aria-label="Next"
              (click)="
                currentPage !== totalPages ? pageChanged(currentPage + 1) : null
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  d="M7 1L5.6 2.5L13 10l-7.4 7.5L7 19l9-9z"
                />
              </svg>
            </a>
          </li>
        </ul>
      </nav>
      } @for(single of filteredLeads; track single;let i=$index){
      <div
        class="modal fade"
        id="contactUsVeLeadDetail"
        tabindex="-1"
        role="dialog"
        aria-labelledby="contactUsVrModalTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered new-lead"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Leads Details
              </h5>
              <div class="d-flex">
                <button
                  type="button"
                  class="close lead-close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img
                    src="../../assets/crm/create-lead-close.svg"
                    alt="close"
                  />
                </button>
              </div>
            </div>
            <div class="modal-body detail-content">
              <form>
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="comp-name">Date</label>
                    <p class="mb-0">
                      {{ singleLead?.createdAt | date : "dd-MM-yyyy" }}
                    </p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="custmer">Name</label>
                    <p class="mb-0">{{ singleLead?.name }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="custmer">Email</label>
                    <p class="mb-0">{{ singleLead?.email }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="custmer">Contact No</label>
                    <p class="mb-0">{{ singleLead?.phoneNo }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="custmer">Message</label>
                    <p class="mb-0 msg-content">{{ singleLead?.message }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputEmail">Assigned To</label>
                    <p class="mb-0">{{ singleLead?.assignedToString }}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputEmail">Status</label>
                    <div class="dropdown lead-track-status">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="industry-button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <p
                          style="width: 130px; margin-bottom: 0"
                          class="track-status"
                        >
                          {{
                            selectedStatus[i] !== undefined &&
                            selectedStatus[i] !== ""
                              ? selectedStatus[i]
                              : singleLead?.status !== undefined &&
                                singleLead?.status !== ""
                              ? singleLead?.status
                              : "Select"
                          }}
                        </p>
                        <svg
                          width="11"
                          height="7"
                          viewBox="0 0 11 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.6"
                            d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                            stroke="#092C4C"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                      <div
                        class="dropdown-menu status-items"
                        aria-labelledby="industry-button"
                      >
                        @for (status of leadStatus; track status) {
                        <a
                          class="dropdown-item"
                          (click)="selectStatus(status, i, singleLead._id)"
                        >
                          {{ status }}
                        </a>
                        }
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-12 d-flex flex-column">
                    <label for="inputEmail">Note</label>
                    <textarea
                      [(ngModel)]="statusnote"
                      [ngModelOptions]="{ standalone: true }"
                      id="w3review"
                      name="w3review"
                      rows="4"
                      cols="50"
                    ></textarea>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                (click)="updateStatus(singleLead._id)"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
      } }
    </div>
    }
  </div>
</div>
