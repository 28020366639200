<!-- <app-header></app-header> -->
<div class="row crm-main-container">
  <div class="side-nav-banner">
    <div class="side-menu">
      <ul>
        <li (click)="navigateToHome()"> <svg xmlns="http://www.w3.org/2000/svg" width="1.6rem" height="1.6rem" viewBox="0 0 24 24"><path fill="currentColor" d="M5 20V9.5l7-5.288L19 9.5V20h-5.192v-6.384h-3.616V20z"/></svg><span>Home</span></li>
        <li (click)="selectMenu('dashboard')">
          <svg
            class="right"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <rect
                x="1.15088"
                y="1.10098"
                width="7.05637"
                height="5.01948"
                rx="0.803109"
                stroke="#fff"
                stroke-width="1.67281"
              />
              <rect
                x="1.15088"
                y="9.57101"
                width="7.05637"
                height="9.457"
                rx="0.803109"
                stroke="#fff"
                stroke-width="1.67281"
              />
              <rect
                x="18.7144"
                y="19.0274"
                width="7.05637"
                height="5.01948"
                rx="0.803109"
                transform="rotate(-180 18.7144 19.0274)"
                stroke="#fff"
                stroke-width="1.67281"
              />
              <rect
                x="18.7144"
                y="10.5574"
                width="7.05637"
                height="9.457"
                rx="0.803109"
                transform="rotate(-180 18.7144 10.5574)"
                stroke="#fff"
                stroke-width="1.67281"
              />
            </g>
          </svg>
          Dashboard
        </li>
        <li (click)="selectLead('leads')">
          <img src="assets/crm/lead-menu-icon.svg" alt="" />
          Leads
          <!-- <svg
                width="11"
                height="7"
                viewBox="0 0 11 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                >
                <path
                  opacity="0.6"
                  d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                  stroke="#fff"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  />
              </svg> -->
          <!-- <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="#">All Leads</a>
            <a class="dropdown-item" href="#">Clients</a>
          </div> -->
        </li>
        <li class="disabled">
          <svg
            width="28"
            height="19"
            viewBox="0 0 28 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.4">
              <path
                d="M13.986 0.0623779C17.5221 0.0623779 21.0686 0.0623779 24.6047 0.0623779C26.3054 0.0623779 27.249 0.995667 27.249 2.68596C27.249 7.15537 27.249 11.6352 27.249 16.1046C27.249 17.7949 26.3054 18.7281 24.6151 18.7281C17.5117 18.7281 10.4187 18.7281 3.31536 18.7281C1.65618 18.7281 0.702148 17.7845 0.702148 16.1357C0.702148 11.6455 0.702148 7.15537 0.702148 2.66522C0.702148 1.00604 1.65618 0.0623779 3.31536 0.0623779C6.87222 0.0623779 10.4291 0.0623779 13.986 0.0623779ZM3.49164 1.64897C3.54349 1.85637 3.69904 1.94969 3.82348 2.06376C6.58187 4.81178 9.34025 7.54943 12.0986 10.2871C13.3638 11.5418 14.6289 11.5418 15.894 10.2871C18.6524 7.54943 21.4108 4.80141 24.1692 2.06376C24.2832 1.94969 24.4492 1.86674 24.4906 1.64897C17.4702 1.64897 10.4809 1.64897 3.49164 1.64897ZM3.45016 17.1312C10.5017 17.1312 17.4806 17.1312 24.3766 17.1312C22.2922 14.995 20.1768 12.8381 18.0613 10.6811C17.885 10.4945 17.7813 10.5774 17.6465 10.7122C17.3562 11.013 17.0658 11.3033 16.7651 11.5937C15.1163 13.1699 12.8349 13.1699 11.1861 11.5937C10.9061 11.3344 10.6261 11.0752 10.3772 10.7848C10.1595 10.5256 10.0143 10.5463 9.77579 10.7848C8.38622 12.1848 6.98629 13.564 5.58636 14.9535C4.89158 15.669 4.18643 16.3846 3.45016 17.1312ZM2.27837 15.9387C2.33022 15.9801 2.38207 16.0112 2.44429 16.0527C4.54937 13.9062 6.64409 11.7596 8.75954 9.63377C9.06027 9.33305 8.78028 9.21898 8.6351 9.06343C6.62335 7.06204 4.62196 5.06066 2.61021 3.06964C2.52725 2.98668 2.46503 2.83113 2.27837 2.85187C2.27837 7.21759 2.27837 11.5833 2.27837 15.9387ZM25.6728 2.78965C25.5795 2.86224 25.5173 2.89335 25.4758 2.9452C23.3914 5.01918 21.3175 7.09315 19.2331 9.14639C18.9635 9.40563 19.1087 9.53007 19.2953 9.71673C21.3278 11.7285 23.3603 13.7506 25.3928 15.7727C25.4654 15.8453 25.5173 15.9905 25.6728 15.9387C25.6728 11.5729 25.6728 7.21759 25.6728 2.78965Z"
                fill="white"
              />
            </g>
          </svg>

          E-Mails
        </li>
        <li class="disabled">
          <svg
            width="26"
            height="27"
            viewBox="0 0 26 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.9723 26.3203C10.9283 26.265 8.88418 26.3976 6.85115 26.1214C3.22704 25.6242 0.973028 23.3923 0.486868 19.7682C0.354279 18.8179 0.287984 17.8677 0.299033 16.9065C0.321131 14.1221 0.210641 11.3488 0.365328 8.56438C0.409524 7.76885 0.542113 6.99541 0.752046 6.23303C1.60283 3.13928 3.76845 1.61451 6.818 1.15044C7.79032 0.995757 8.76264 0.940511 9.73496 0.95156C12.5193 0.984708 15.2927 0.863168 18.077 1.0289C18.9831 1.08415 19.867 1.23884 20.7288 1.51506C23.6237 2.44319 25.0048 4.58671 25.4578 7.45948C25.6015 8.3876 25.6567 9.32677 25.6567 10.2659C25.6236 13.0835 25.7451 15.901 25.5794 18.7185C25.5241 19.6024 25.3694 20.4753 25.1042 21.315C24.1761 24.2651 22.0216 25.6684 19.0935 26.1103C17.0605 26.4197 15.0054 26.265 12.9723 26.3203ZM23.8888 13.6249C23.8336 11.625 23.9993 9.61405 23.69 7.61416C23.3474 5.44854 22.3199 3.82432 20.1101 3.17243C19.5134 2.99564 18.8947 2.852 18.2759 2.81886C15.0164 2.64207 11.768 2.71942 8.50851 2.76361C8.00025 2.77466 7.48094 2.84096 6.97269 2.9183C4.43139 3.30502 2.99501 4.57566 2.40941 6.91807C2.26577 7.49262 2.16633 8.06718 2.14423 8.66383C2.00059 11.6802 2.08899 14.6966 2.08899 17.713C2.08899 18.3539 2.16633 18.9947 2.26577 19.6356C2.59724 21.8012 3.62481 23.4254 5.84568 24.0773C6.46443 24.2651 7.09423 24.4088 7.73507 24.4419C11.2266 24.5966 14.7291 24.6408 18.2207 24.4309C21.5685 24.232 23.2038 22.6741 23.7231 19.3593C24.0104 17.4589 23.8226 15.5364 23.8888 13.6249Z"
              fill="white"
            />
            <path
              d="M14.7403 15.526C14.2762 15.5481 13.8342 15.3381 13.4365 15.0177C12.8398 14.5536 12.2321 14.1006 11.6686 13.6145C11.2709 13.283 11.0388 13.3382 10.7405 13.7471C9.93391 14.8409 9.09418 15.9127 8.25445 16.9844C7.84563 17.5037 7.28213 17.5811 6.87331 17.2054C6.53079 16.885 6.4645 16.432 6.78492 16.0121C7.72409 14.7636 8.66326 13.515 9.64663 12.2996C10.3538 11.4267 11.5471 11.3273 12.4752 12.0123C13.094 12.4764 13.6906 12.9626 14.2983 13.4377C14.8618 13.8796 14.8729 13.8796 15.3369 13.294C16.1435 12.2665 16.939 11.2279 17.7346 10.2003C17.9887 9.87988 18.3091 9.74729 18.7069 9.84673C19.0494 9.92407 19.2372 10.1561 19.3256 10.4876C19.414 10.797 19.2925 11.0621 19.1157 11.3052C18.2318 12.4543 17.3368 13.6034 16.4418 14.7525C16.022 15.2718 15.4806 15.537 14.7403 15.526Z"
              fill="white"
            />
          </svg>
          Activity
        </li>
      </ul>
    </div>
  </div>
  <router-outlet> </router-outlet>
</div>
