import { Component } from "@angular/core";
import { ChatService } from "src/app/service/chat.service";
import axios from "axios";
import Swal from "sweetalert2";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { DatePipe, NgStyle } from "@angular/common";
import { Channel, Converse } from "converse.svc-client";
import { environment } from "src/environments/environment";
import { UserService } from "src/app/service/user.service";
import { OnlineUsersService } from "src/app/service/online-users.service";

const CHUNK_SIZE = 500 * 1024 * 1024; 
const MIN_CHUNK_SIZE = 10 * 1024 * 1024; 
@Component({
  selector: "app-new-group-chat",
  templateUrl: "./new-group-chat.component.html",
  styleUrls: ["./new-group-chat.component.css"],
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, DatePipe ,NgStyle],
})
export class NewGroupChatComponent {
  channel_id: any = "653a07521e843a9bec8a486e";
  user_id: any = localStorage.getItem("guestID");
  ephemeral = false;
  showChatBox = false;
  AllMessages: any = {};
  project_id: any;
  channel!: any;
  allUsersData: any;
  selectedName: any;
  chatBody: any;
  sendFile: any;
  isLoading: boolean = false;
  groupChatMessages: any[] = [];
  // groupedMessages: { date: Date; messages: any[] }[] = [];
  groupedMessages: any[] = [];
  previousTimestamp: Date | null = null;
  previousSenderName: string | null = null;
  newMessage: string = "";
  profileImageMapping = {};
  converse: Converse | null = null;
  chan: Channel | null = null;
  groupUser: any;
  channelCache: any[] = [];
  chatUserId: any;
  newReceiver: any;
  newUserName: any;
  chat_messages: any[] = [];
  isSuggestionBoxVisible: boolean = true;
  ngOnInit(): void {
    this.userId = localStorage.getItem("guestID");
    this.chatInit();
    
  }

  ngAfterViewInit() {}

  constructor(private chatService: ChatService, private api: UserService , private OnlineUsersService: OnlineUsersService) {}

  userId: any;
  conversationStart: any;
  totalGroupMembers: any;
  memberChannelId: any;
  async chatInit() {
    this.chatService.getChats().subscribe((users: any) => {
      const groupMembers = users.data.find(
        (group) => group.groupName === "Samurais"
      );
      
      console.log(groupMembers, "groupMembers");
      this.initiateChat(groupMembers.channelId);
      this.memberChannelId = groupMembers.channelId; 
      console.log("Assigned Member Channel ID:", this.memberChannelId);
      this.totalGroupMembers = groupMembers.members.length;
      groupMembers.members.forEach((member: any) => {
        this.profileImageMapping[member.userId] = member.profileImage;
    });
    });
  }

  chatUserName: string;
  async initiateChat(channelId: string) {
    console.log("initiate chat", channelId);
    try {
      this.converse = new Converse();
      let converseToken = localStorage.getItem("converseToken");

      if (!converseToken) {
        const res = await fetch(environment.host + "chats/create-token", {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("guestToken")}`,
          },
          method: "POST",
          body: JSON.stringify({ projectId: "project_link_module_test" }),
        });

        const jsonResponse = await res.json();
        converseToken = jsonResponse.data.token;
        localStorage.setItem("converseToken", converseToken);
      }

      await this.converse.init({
        projectId: "project_link_module_test",
        converseToken,
      });

      this.converse.listenConnectionCallback(async () => {
        try {
          this.chan = await this.converse.connectChannel({ channelId });
          console.log("Channel connected successfully:", this.chan);
          this.getAllMessages(this.chan);
          this.groupMessagesByDate();
          this.scrollToBottom();
        } catch (error) {
          console.error("Chat connection error:", error);
        }
      });
    } catch (error) {
      console.error("Failed to initiate chat:", error);
    }
  }

  unreadMessageCount: any;
  async getAllMessages(channel) {
    console.log("profile image, mapping", this.profileImageMapping)
    try {
      let messages = await channel.getMessages({
        limit: 50,
        beforeMessageId: null,
      });

      if (!channel.hasListener) {
        channel.listenMessage((msg) => this.inComingListenerMessages(msg));
        channel.hasListener = true;
    }

      this.AllMessages = {
        read: messages.msgs?.read || [],
        unread: messages.msgs?.unread || [],
      };

      this.groupChatMessages = [
        ...this.AllMessages.read,
        ...this.AllMessages.unread,
      ].sort(
        (a, b) =>
          new Date(a.creationTime).getTime() -
          new Date(b.creationTime).getTime()
      );
      this.groupChatMessages.forEach((message) => {
        message["profileImage"] = this.profileImageMapping[message.sender_id];

      });
      this.unreadMessageCount = this.AllMessages.unread.length;
      this.processMessages();
      this.scrollToBottom();
      console.log(this.groupChatMessages, "groupChatMessages");

      this.inComingListenerMessages(channel);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  }

  async showChat() {
    this.showChatBox = !this.showChatBox;
    this.scrollToBottom();
     this.unreadMessageCount = 0;
  }

  inComingListenerMessages(msg: any) {
    console.log("inComingListenerMessages", msg);
    const newMessage = {
      ...msg,
      sender_id: msg.sid,
      createdAt: new Date(),
       profileImage: this.profileImageMapping[msg.sid] ,
    };
    this.groupChatMessages.push(newMessage);
    console.log(newMessage,"NEW")
    this.scrollToBottom();
  }

  groupMessagesByDate(): void {
    const messageMap = new Map<string, any[]>();

    this.chat_messages.forEach((message) => {
      const date = new Date(message.createdAt).toDateString();
      if (!messageMap.has(date)) {
        messageMap.set(date, []);
      }
      messageMap.get(date).push(message);
    });
    this.groupedMessages = [];
    messageMap.forEach((messages, dateString) => {
      this.groupedMessages.push({ date: new Date(dateString), messages });
    });
    this.groupedMessages.sort((a, b) => b.date.getTime() - a.date.getTime());
  }

  scrollToBottom() {
    setTimeout(() => {
      const chatContainer = document.getElementById("chat-messages");
      if (chatContainer) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    }, 100);
  }

  fileId: any;
  async sendMessage() {
    const groupChatChannel = this.chan;
    console.log("sendMessage", groupChatChannel);
    try {
      if (!groupChatChannel) {
        console.error("Error: Channel is not initialized.");
        return;
      }

      if (!this.newMessage.trim() && !this.sendFile) {
        console.warn("Cannot send an empty message.");
        return;
      }

      let messageObject = {
        message: this.newMessage,
        sender_id: this.userId,
        createdAt: new Date(),
        meta: null,
        file: this.sendFile ? this.sendFile : "",
        profileImage: this.profileImageMapping[this.userId],
      };

      // Check if message is a URL
      if (this.isURL(this.newMessage)) {
        let body = {
          url: this.newMessage,
          channelId: this.memberChannelId,
        };

        if (this.fileId) body["fileId"] = this.fileId;

        await this.OnlineUsersService.isUrlValidSave(body).then((res: any) => {
          if (res?.data) {
            messageObject.meta = {
              title: res.data.meta.title || "",
              description: res.data.meta.description || "",
              image: res.data.meta.icon || "",
            };
            console.log("Updated message with metadata:", messageObject);
          }
          // this.cdr.detectChanges();
        });
      }

      await groupChatChannel.sendMessage(messageObject);

      this.newMessage = "";
      this.sendFile = null;
      this.scrollToBottom();
      this.groupMessagesByDate();
    } catch (error) {
      console.error("Error sending message:", error);
    }
  }

  onSuggestionSelectedAction(selectedSuggestion: string): void {
    console.log("this is selected suggestions", selectedSuggestion);
  }

  filterDataSearch: any;
  filterDataSearchLength: any;
  selectSuggestion(selectedSuggestion: string, fileId?: any): void {
    this.newMessage = selectedSuggestion;
    this.fileId = fileId;
    // Clearing suggestions after selection
    this.filterDataSearch = [];
    this.filterDataSearchLength = 0;
    this.onSuggestionSelectedAction(selectedSuggestion);
  }


  skipApi: boolean = false;
  MetaDataDetails: any;
  MedataLength: any;
  private isFirstTextTyping: boolean = true;
  onInputChange(): void {
    console.log(this.memberChannelId);
    if (!this.newMessage) {
      // Reset suggestion data
      this.MetaDataDetails = null;
      this.MedataLength = 0;
      this.filterDataSearch = [];
      this.filterDataSearchLength = 0;
      this.isFirstTextTyping = true;
      return;
    }
  
    if (this.newMessage.split("")[0] == "/") {
      let newText = this.newMessage.slice(1);
      if (newText) {
        let text = newText.toLowerCase();
        let filterData = this.groupChatMessages.filter((msg: any) =>
          msg.message?.toLowerCase()?.includes(text)
        );
        this.filterDataSearch = filterData;
        this.filterDataSearchLength = filterData.length;
      }
    }
  
    let result = this.isURL(this.newMessage);
  
   
    window.addEventListener("keydown", (event) => {
      if (event.keyCode === 8) {
        if (!this.isURL(this.newMessage)) {
          this.MetaDataDetails = null;
          this.MedataLength = 0;
          this.skipApi = true;
        }
        return;
      }
    });
  
    // Allow pasting events to process URLs
    window.addEventListener("paste", (event) => {});
  
    if (result && !this.skipApi) {
      let body = { url: this.newMessage, channelId:  this.memberChannelId,};
      this.OnlineUsersService.isUrlValid(body).subscribe((res: any) => {
        if (res?.data) {
          if (!this.MetaDataDetails || this.MetaDataDetails.fileUrl !== res.data.fileUrl) {
            // If it's a new URL, reset the metadata
            this.MetaDataDetails = res.data;
            this.MedataLength = 1;
          } else {
            // If it's the same URL, append the new version to `thread`
            if (!this.MetaDataDetails.thread) {
              this.MetaDataDetails.thread = [];
            }
            this.MetaDataDetails.thread.push({
              fileUrl: res.data.fileUrl,
              _id: res.data._id,
              version: this.MetaDataDetails.thread.length + 1,
            });
            this.MedataLength = this.MetaDataDetails.thread.length + 1;
          }
        } else {
          this.MetaDataDetails = null;
          this.MedataLength = 0;
        }
        console.log("MetaDataDetails", this.MetaDataDetails?.thread);
      });
  
      this.isFirstTextTyping = false;
    }
  }

    openUrlInNewWindow(url: string): void {
    if (this.isURL(url)) {
      window.open(url, "_blank");
    }
  }
  closeSuggestionBox() {
    this.isSuggestionBoxVisible = false; 
  }

processMessages() {
  let lastDisplayedDate: string | null = null;

  this.groupChatMessages.forEach((message) => {
    const messageDate = new Date(message.updatedAt).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });

    message["showDateHeader"] = lastDisplayedDate !== messageDate;
    lastDisplayedDate = messageDate;
  });
}



  isURL(str: string): boolean {
  const urlRegex = /^(?:(?:https?|ftp):)?\/\/[^\s/$.?#].[^\s]*$/;
  return urlRegex.test(str);
  }

  isImage(url: string): boolean {
    return /\.(jpg|jpeg|png|gif)$/i.test(url);
  }

  isDocument(url: string): boolean {
    return /\.(doc|docx|pdf|zip|xml|rar)$/i.test(url);
  }

  isVideo(url: string): boolean {
    return /\.(mp4|webm)$/i.test(url);
  }


  uploadProgress = 0;
  private getDynamicChunks(fileSize: number) {
    let chunks = [];
    let totalChunks = Math.ceil(fileSize / CHUNK_SIZE);

    for (let i = 0; i < totalChunks; i++) {
      let start = i * CHUNK_SIZE;
      let end = Math.min(start + CHUNK_SIZE, fileSize);

      if (
        i === totalChunks - 1 &&
        end - start < MIN_CHUNK_SIZE &&
        chunks.length > 0
      ) {
        let previousChunk = chunks.pop();
        start = previousChunk.start;
        end = fileSize;
        totalChunks--;
      }

      chunks.push({ start, end });
    }

    return { chunks, totalChunks };
  }

  async uploadFile(file: File) {
    this.isLoading = true;
    this.scrollToBottom();
    const fileBuffer = await file.arrayBuffer();
    const fileBytes = fileBuffer.byteLength;

    let { chunks, totalChunks } = this.getDynamicChunks(file.size);

    try {
      // Get pre-signed URLs
      const response = await this.chatService
        .getPreSignedUrls(file.name, totalChunks)
        .toPromise();
      if (!response?.data) {
        console.error("Error: Invalid response from pre-signed URL API.");
        return;
      }

      const { uploadId, preSignedUrls, fileName, fileUrl } = response.data;
      let uploadedParts: any[] = [];
      let chunkProgress: { [key: number]: number } = {};

      // Upload chunks in parallel
      await Promise.all(
        preSignedUrls.map(async ({ partNumber, url }: any, index: number) => {
          const chunk = file.slice(chunks[index].start, chunks[index].end);

          try {
            const res = await axios.put(url, chunk, {
              headers: { "Content-Type": "application/octet-stream" },
              onUploadProgress: (progressEvent) => {
                chunkProgress[partNumber] = progressEvent.loaded;
                const totalUploaded = Object.values(chunkProgress).reduce((a, b) => a + b, 0);
                this.uploadProgress = Math.min((totalUploaded / fileBytes) * 100, 100);
            
                document.getElementById("progress-text").innerText = `${Math.floor(this.uploadProgress)}%`;
            
                const progressBar = document.querySelector('.circle-progress') as HTMLElement;
                if (progressBar) {
                    const percent = this.uploadProgress;
                    progressBar.style.strokeDashoffset = `${100 - percent}`;
                }
            },
            });

            const eTag = res.headers["etag"]?.replace(/^"(.*)"$/, "$1");
            if (eTag) {
              uploadedParts.push({ PartNumber: partNumber, ETag: eTag });
            } else {
              console.warn(`Chunk ${partNumber} uploaded but missing ETag.`);
            }
          } catch (error) {
            console.error(`Chunk ${partNumber} upload failed:`, error);
          }
        })
      );

      if (uploadedParts.length === 0) {
        console.error("Error: No chunks uploaded successfully.");
        return;
      }

      const messagePayload = {
        file: fileUrl,
        message:`${file.name}`,
        type:false,
        meta: {
          data: "test",
          message: "test",
        },
      };
   this.isLoading = true;
      try {
        await this.chatService
          .completeUpload(
            fileName,
            uploadId,
            uploadedParts,
            file.size,
            this.memberChannelId,
            messagePayload
          )
          .toPromise();
        console.log("Upload completed successfully!");
        console.log("Before calling handleIncomingMessage1:", { 
          sid: this.userId, 
          createdAt: new Date(),
          file: fileUrl, 
          message: `File uploaded: ${file.name}`,
        })
        this.inComingListenerMessages({
          sid: this.userId,
          createdAt: new Date(),
          file: fileUrl,
        });
        this.isLoading = false;
        this.scrollToBottom();

      } catch (err) {
        console.error("Upload failed:", err);
      }
    } catch (err) {
      console.error("Error in file upload process:", err);
    }
  }

  async handleFileInput(event: any): Promise<void> {
    const file = event.target.files[0];
    if (!file) return;

    const fileMessage = {
      sender_id: this.userId,
      creationTime: new Date(),
      file: file,
      fileName: file.name,
    };
    console.log(fileMessage, "fileMessage", file, this.userId);
    try {
      await this.uploadFile(file);
    } catch (error) {
      console.error("File upload failed:", error);
    }
  }

  // fileSizeExceedsLimit: boolean = false;
  // handleFileInput(event: any): void {
  //   this.isLoading = true;
  //   const file = event.target.files[0];
  //   console.log(file);
  //   this.scrollToBottom();
  //   const fileSizeLimit = 300 * 1024 * 1024; // 300MB in bytes
  //   if (file && file.size > fileSizeLimit) {
      
  //     setTimeout(() => {
  //       this.fileSizeExceedsLimit = false;
  //     }, 5000);
  //     return;
  //   }
  //   console.log(file);


  //   if (file) {
  //     this.sendFile = file;
  //     const fileName = { fileName: file.name };
  //     const fileMessage = {
  //       sender_id: this.userId,
  //       creationTime: new Date(),
  //       file: file ? file : "",
  //       fileName: file.name,
  //       meta: { videoUploaded: true },
  //     };
  //     this.groupedMessages.push(fileMessage);
  //     setTimeout(() => {
  //       this.isLoading = false; 
  //       this.sendMessage(); 
  //   }, 3000); 
  //     console.log(this.groupedMessages);
  //   }
  // }

  // convertFileToBase64(file: File): void {
  //   this.isLoading = true;
  //   const reader = new FileReader();

  //   reader.onload = (e: any) => {
  //     const base64String = e.target.result.split(",")[1];
  //     // Now you can use the base64String as needed, for example, send it to your server.
  //     this.sendFile = base64String;
  //     this.isLoading = false;
  //   };

  //   reader.readAsDataURL(file);
  // }

  handelKeyDown(event: KeyboardEvent): void {
    if (event.ctrlKey && event.shiftKey && event.key === "Enter") {
      // Insert new line
      this.newMessage += "\n";
    }
  }
}
