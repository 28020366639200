<div class="containerBanner" style="overflow: hidden;">
    <div class="menu-container">
        @if (showServiceRequestTable) {
        <h1>New Inventory Requests <span>({{ this.totalNewInventory}})</span></h1>
        }
        @if (showForwardedReqTable) {
        <h1> Forwarded Requests<span>({{this.totalForwarded}})</span></h1>
        }

        @if (showApprovedRequest) {
        <h1> Approved Requests<span>({{this.totalApproved}})</span></h1>
        }
        @if (showPendingRequest) {
        <h1> Pending Requests<span>({{this.totalPending}})</span></h1>
        }
        <div class="menu-content d-flex align-items-center justify-content-between">
            <div class="search-menu">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"
                    class="search-icon">
                    <g opacity="0.4">
                        <circle cx="11.0795" cy="11.3768" r="7.62168" transform="rotate(-45 11.0795 11.3768)"
                            stroke="#092C4C" stroke-width="1.72802" stroke-linecap="round" />
                        <path d="M16.6416 16.9393L19.8465 20.1443" stroke="#092C4C" stroke-width="1.72802"
                            stroke-linecap="round" />
                    </g>
                </svg>
                <input type="search" placeholder="Search requests.." />
            </div>
            <div class="btn-container d-flex align-items-center justify-content-between">
                <div class="month-type">
                    <!-- <mat-form-field class="example-form-field" style="
                  --mat-form-field-filled-with-label-container-padding-bottom: 0px;
                  --mat-form-field-container-height: 0px;
                  --mat-form-field-filled-with-label-container-padding-top: 0px;
                  --mdc-filled-text-field-container-color: #fff;
                  --mat-form-field-container-vertical-padding: 10px;
                ">
                <mat-date-range-input [rangePicker]="rangePicker">
                  <input matStartDate placeholder="Start date" />
                  <input matEndDate placeholder="End date" />
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #rangePicker>
                  <mat-date-range-picker-actions>
                    <button mat-button matDateRangePickerCancel>Reset</button>
                    <button style="
                        --mdc-protected-button-container-color: #fecc28;
                        --mdc-protected-button-label-text-color: #000;
                      " mat-raised-button color="primary" matDateRangePickerApply>
                      Apply
                    </button>
                  </mat-date-range-picker-actions>
                </mat-date-range-picker>
              </mat-form-field> -->
                </div>

                <!-- <select id="admin" class="form-control" formControlName="admin" style="margin-left: 15px">
                    <option value="admin" selected>All Admins</option>
                </select> -->

                <mat-form-field style="width:185px!important;height: 45px!important;">

                    <mat-select placeholder="New Inventory Request">
                        @for (request of requests; track $index) {
                        <mat-option [value]="request.value" (click)="onRequestSelect(request.value)">
                            {{request.viewValue}}
                        </mat-option>
                        }
                        @for (newRequest of newReq; track $index) {
                        <mat-option [value]="newRequest.value" (click)=" getNewInventory()"> {{newRequest.viewValue}}
                        </mat-option>
                        }
                        @for (pendingRequest of pendingServiceReq; track $index) {
                        <mat-option [value]="pendingRequest.value"
                            (click)="onFilterPendingRequest(pendingRequest.value)">
                            {{pendingRequest.viewValue}}
                        </mat-option>
                        }
                        @for (approvedRequest of approvedServiceReq; track $index) {
                        <mat-option [value]="approvedRequest.value"
                            (click)="onFilterApprovedRequest(approvedRequest.value)">
                            {{approvedRequest.viewValue}}
                        </mat-option>
                        }
                    </mat-select>

                </mat-form-field>

                <button class="d-flex align-items-center justify-content-center new-req" type="button"
                    (click)="openNewRequest()" data-toggle="modal" data-target="#newInventoryModal"
                    style="margin-left: 13px;">
                    Request
                </button>
            </div>
        </div>
        <!-- New Inventory Table -->
        @if (showServiceRequestTable) {
        <div class="checked-out-inv" >

            <table style="overflow-x: auto; max-height: 500px; height: 510px;">
                <thead>
                    <tr>
                        <th scope="col" style="width: 15%;">ASSET TYPE</th>
                        <th scope="col" style="width: 15%;">REQUESTED BY</th>
                        <!-- <th scope="col">APPROVER</th> -->
                        <th scope="col" style="width: 15%;">STATUS</th>
                        <th scope="col" style="width: 15%;">LINKS</th>
                        <th scope="col" style="width: 15%;">REQUESTED ON</th>
                        <th scope="col" style="width: 15%;">NEED BY</th>
                        <th scope="col" style="width: 15%;">FOR PROJECT</th>
                        <!-- <th scope="col">REQUESTED FOR</th> -->
                        <th scope="col" style="width: 15%;">URGENCY</th>
                        @if(showForwardButton ){
                        <th scope="col" style="padding-left: 30px;">ACTIONS</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    @for (newInventory of newInventories; track $index) {
                    <tr data-toggle="modal" data-target="#requestDetailModal">
                        <td>
                            <div class="d-flex align-items-center">
                                <p>{{newInventory.assetCategory}}</p>
                            </div>
                        </td>
                        <td>
                            <p>{{newInventory.requestedBy}}</p>
                        </td>
                        <!-- <td>
                            <p>-</p>
                        </td> -->
                        <td>
                            <p class="status-point">{{newInventory.status}}</p>
                        </td>
                        <td>
                            <p class="link"><a [href]="newInventory.links[0]">{{newInventory.links[0]!== "null" ?
                                    newInventory.links[0] : '-' }}</a></p>
                            <p class="link"><a [href]="newInventory.links[1]">{{newInventory.links[1]!== "null" ?
                                    newInventory.links[1] : ''}}</a></p>
                            <p class="link"><a [href]="newInventory.links[2]">{{newInventory.links[2]!== "null" ?
                                    newInventory.links[2] : ''}}</a></p>
                        </td>
                        <td>
                            <p>{{newInventory.requestedOn}}</p>
                        </td>
                        <td>
                            <p>
                                {{newInventory.needBy}}
                            </p>
                        </td>
                        <td class="project-name">
                            <p>{{newInventory.project}}</p>
                        </td>
                        <!-- <td>
                            <p>-</p>
                        </td> -->
                        <td>
                            <p [ngClass]="{
                                'text-red': newInventory.urgency === 'High',
                                'text-orange': newInventory.urgency === 'Medium',
                                'text-blue': newInventory.urgency === 'Low'
                              }">
                                {{ newInventory.urgency }}
                            </p>
                        </td>
                        @if(showForwardButton ){
                        <td style="padding-left: 30px;">
                            <div class="d-flex align-items-center">
                                 <button class="forward-req" style="margin-right: 15px;"
                                    (click)="selectedInventory = newInventory" data-target="#forwardNewRequestModal"
                                    data-toggle="modal">
                                    Forward Request
                                </button>
                                
                                <button mat-stroked-button class="accept" style="margin-right: 10px;"
                                    (click)="acceptRequest(newInventory.requestId)">Accept</button>
                                <button mat-stroked-button class="decline"
                                    (click)=" declineRequest(newInventory.requestId)">Decline</button>
                            </div>
                        </td>
                        }
                    </tr>
                    }
                </tbody>
                @if(newInventories.length === 0){
                    <app-no-data></app-no-data>
                   }
            </table>
        </div>
        }
        @if (showForwardedReqTable) {
        <div class="checked-out-inv" >
            <table style="overflow-x: auto; max-height: 500px; height: 500px;">
                <thead>
                    <tr>
                        <th scope="col" style="width: 15%;">ASSET NAME</th>
                        <th scope="col" style="width: 15%;">REQUESTED BY</th>
                        <th scope="col" style="width: 15%;">APPROVER</th>
                        <th scope="col" style="width: 15%;">STATUS</th>
                        <th scope="col" style="width: 15%;">LINKS</th>
                        <th scope="col" style="width: 15%;">REQUESTED ON</th>
                        <th scope="col" style="width: 15%;">NEED BY</th>
                        <th scope="col" style="width: 15%;">URGENCY</th>
                        <th scope="col" style="width: 15%;">FORWARDED ON</th>
                        <th scope="col" style="width: 15%;">FORWARDED TO</th>
                        <th scope="col">COMMENT</th>
                    </tr>
                </thead>
                <tbody>
                    @for (forward of forwardedReq; track $index) {
                    <tr data-toggle="modal" data-target="#requestDetailModal">
                        <td>
                            <div class="d-flex align-items-center">
                                <p>{{forward.assetType}}</p>
                            </div>
                        </td>
                        <td>
                            <p>{{forward.requestedBy}}</p>
                        </td>
                        <td class="project-name">
                            <p>{{forward.approver}}</p>
                        </td>
                        <td>
                            <p>
                                {{forward.status}}
                            </p>
                        </td>
                        <td>
                            <p class="link"><a [href]="forward.links[0]">{{forward.links[0]!== "null" ?
                                    forward.links[0] : '-' }}</a></p>
                            <p class="link"><a [href]="forward.links[1]">{{forward.links[1]!== "null" ?
                                    forward.links[1] : ''}}</a></p>
                            <p class="link"><a [href]="forward.links[2]">{{forward.links[2]!== "null" ?
                                    forward.links[2] : ''}}</a></p>
                        </td>
                        <td>
                            <p>{{forward.requestedOn}}</p>
                        </td>
                        <td>
                            <p>{{forward.needBy}}</p>
                        </td>
                        <td>
                            <p [ngClass]="{
                                'text-red':forward.urgency === 'High',
                                'text-orange':forward.urgency === 'Medium',
                                'text-blue':forward.urgency === 'Low'
                              }">{{forward.urgency}}</p>
                        </td>
                        <td>
                            <p>{{forward.forwardedOn}}</p>
                        </td>
                        <td>
                            <p>{{forward.forwardedTo}}</p>
                        </td>
                        <td>
                            <p class="other-details">
                                {{forward.comment}}
                            </p>
                        </td>
                    </tr>
                    }
                </tbody>
                @if(forwardedReq.length === 0){
                    <app-no-data></app-no-data>
                   }
            </table>
        </div>
        }
        <!-- Pending Request Table -->
        @if (showPendingRequest) {
       

            <div class="checked-out-inv">
                <table style="overflow-x: auto; max-height: 500px;height: 500px;">
                    <thead>
                        <tr style="
                border-top: 1px solid #e7e7e7;
                border-bottom: 1px solid #e7e7e7;
              ">
                            <th scope="col" style="width: 20%">ASSET TYPE</th>
                            <th scope="col" style="width: 20%;">REQUESTED ON</th>
                            <th scope="col" style="width: 20%;">START DATE</th>
                            <th scope="col" style="width: 20%;">STATUS</th>
                            <th scope="col" style="width: 20%;">PROJECT</th>
                            <th scope="col" style="width: 20%;">URGENCY</th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (pending of pendingReq; track $index) {
                        <tr class="">
                            <td>
                                <div style="width: 80%">
                                    <p>{{pending.assetType}}</p>
                                </div>
                            </td>
                            <td>
                                <p>{{pending.requestedOn}}</p>
                            </td>   
                            <td>
                                <p>{{pending.startDate}}</p>
                            </td>                                                   
                            <td>
                                <p [ngClass]="{
                  
                  'text-pending':pending.status === 'Pending' || 'Forwarded',
                  'text-rejected' : pending.status === 'Rejected By tanvi'
                }">{{pending.status}}</p>
                            </td>
                            <td class="project-name">
                                <p>{{pending.project}}</p>
                            </td>
                            <td>
                                <p [ngClass]="{
                  'text-red':pending.urgency === 'High',
                  'text-orange':pending.urgency === 'Medium',
                  'text-blue':pending.urgency === 'Low'
                }">{{pending.urgency}}</p>
                            </td>
                        </tr>
                        }


                    </tbody>
                    @if(pendingReq.length === 0){
                        <app-no-data></app-no-data>
                       }
                </table>
            </div>

        
        }

        <!-- Approved Requests Table -->
        @if (showApprovedRequest) {
        <div class="checkout-request">

            <div class="checked-out-inv" >
                <table style="overflow-x: auto;max-height: 500px;height: 500px;">
                    <thead>
                        <tr style="
              border-top: 1px solid #e7e7e7;
              border-bottom: 1px solid #e7e7e7;
            ">
                            <th scope="col" style="width:400px">ASSET TYPE</th>
                            <th scope="col" style="width: 400px;">REQUESTED ON</th>
                            <th scope="col" style="width: 400px;">START DATE</th>
                            <th scope="col" style="width: 400px;">PROJECT</th>
                            <th scope="col" style="width: 200px;">URGENCY</th>

                        </tr>
                    </thead>
                    <tbody>
                        @for (approve of approveInv; track $index) {
                        <tr class="">
                            <td>
                                <div>
                                    <p>{{approve.assetType}}</p>
                                </div>
                            </td>
                            <td>
                                <p>{{approve.requestedOn}}</p>
                            </td>
                            <td>
                                <p>{{approve.startDate}}</p>
                            </td>
                            <td class="project-name">
                                <p>{{approve.project}}</p>
                            </td>
                            <td>
                                <p [ngClass]="{
                'text-red': approve.urgency === 'High',
                'text-orange': approve.urgency === 'Medium',
                'text-blue': approve.urgency === 'Low'}">{{approve.urgency}}</p>
                            </td>

                            <!-- <td>
                                <button type="button" class="accept">Submit Images</button>
                            </td> -->
                        </tr>
                        }


                    </tbody>
                    @if(approveInv.length === 0){
                        <app-no-data></app-no-data>
                       }
                </table>
            </div>
        </div>
        }
        <!-- Pagination -->
     @if(approveInv.length > 0 || pendingReq.length > 0 || newInventories.length > 0 || forwardedReq.length > 0){
     <nav aria-label="Page navigation example" style="position: absolute;left: 50%;bottom: 3%;">
        <ul class="pagination" style="margin-top: 10px;">
          <li class="page-item" [ngClass]="{ disabled: currentPage === 1 }">
            <a class="page-link" href="javascript:void(0)" aria-label="Previous"
              (click)="currentPage !== 1 ? pageChanged(currentPage - 1) : null">
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20">
                <path fill="currentColor" d="m4 10l9 9l1.4-1.5L7 10l7.4-7.5L13 1z" />
              </svg>
            </a>
          </li>
          @for(page of visiblePages; track page){
          <li class="page-item" [ngClass]="{
              active: currentPage === page,
              hidden: !isPageVisible(page)
            }">
            <a class="page-link" href="javascript:void(0)" (click)="pageChanged(page)">{{ page }}</a>
          </li>
          }
      
          <li class="page-item" [ngClass]="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="javascript:void(0)" aria-label="Next" (click)="
                currentPage !== totalPages ? pageChanged(currentPage + 1) : null
              ">
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20">
                <path fill="currentColor" d="M7 1L5.6 2.5L13 10l-7.4 7.5L7 19l9-9z" />
              </svg>
            </a>
          </li>
        </ul>
      </nav>
    }
    </div>
     
</div>





<!-- Request New Inventory Modal -->
<div class="modal fade" id="newInventoryModal" tabindex="-1" role="dialog" aria-labelledby="addInventoryModalTitle"
    aria-hidden="true" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered main-width-content" role="document">

        <div class="modal-content">
            <div class="modal-header addInventory-head">
                @if (!formSubmitted) {
                <h5 class="modal-title" id="addInventoryModalTitle">
                    Request New Inventory
                </h5>
                }
                <button type="button" class="close inven-cls" data-dismiss="modal" aria-label="Close">
                    <svg class="cross-svg" width="14" height="12" viewBox="0 0 14 14" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.5518 1.64453L1.85612 12.3402" stroke="#25252B" stroke-width="2.26313"
                            stroke-linecap="round" />
                        <path d="M12.5518 12.3401L1.85612 1.64445" stroke="#25252B" stroke-width="2.26313"
                            stroke-linecap="round" />
                    </svg>
                </button>
            </div>

            <div class="modal-body ">
                @if (!formSubmitted) {
                <div class="new-inventory-container">
                    <form [formGroup]="newInventoryForm" (ngSubmit)="onSubmitForm()">
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="assetCategory" class="form-label asterisk-lbl">Asset Type</label>
                                <input type="text" class="form-control" id="assetCategory"
                                    formControlName="assetCategory">
                            </div>
                            <div class="form-group col-md-4">
                                <label for="startDate" class="form-label asterisk-lbl">Start Date</label>
                                <input type="date" id="storage" class="form-control" id="startDate"
                                    formControlName="startDate" />
                            </div>
                            <div class="form-group col-md-4">
                                <label for="projectName" class="form-label asterisk-lbl">Project Name</label>
                                <input type="text" class="form-control" id="projectName" formControlName="projectName">
                            </div>

                        </div>
                        <div class="form-row">

                            <div class="form-group col-md-4">
                                <label for="link1">Product Link 1</label>
                                <input type="text" class="form-control" id="link1" formControlName="link1">
                            </div>

                            <div class="form-group col-md-4">
                                <label for="link2">Product Link 2</label>
                                <input type="text" class="form-control" id="link2" formControlName="link2">
                            </div>
                            <div class="form-group col-md-4">
                                <label for="link3" class="form-label asterisk-lbl">Product Link 3</label>
                                <input type="text" id="link3" class="form-control" formControlName="link3" />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="urgency" class="form-label asterisk-lbl">Urgency</label>
                                <mat-form-field>
                                    <mat-select formControlName="urgency" placeholder="select">
                                        @for (urgency of urgencies; track urgency) {
                                        <mat-option [value]="urgency.value">{{urgency.viewValue}}</mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="approverName" class="form-label asterisk-lbl">Select Approver</label>
                                <mat-form-field>
                                    <mat-select formControlName="approverName" placeholder="select">
                                        @for (adminUser of adminUsers; track adminUser) {
                                            <mat-option [value]="adminUser.value">{{adminUser.viewValue}}</mat-option>
                                            }
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end align-items-center">
                            <button type="submit" class="btn btn-warning" style="margin-top: 40px">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
                }
                @if (formSubmitted) {
                <div class="text-center" style="padding: 30px 0;">
                    <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M37.7808 3.91014C46.0698 3.91014 54.0763 6.92354 60.3083 12.3889C66.5404 17.8543 70.573 25.3989 71.655 33.617C72.7369 41.8352 70.7943 50.1664 66.1892 57.0586C61.584 63.9507 54.6303 68.9336 46.6237 71.079C38.6171 73.2244 30.1035 72.3858 22.6693 68.7197C15.2351 65.0535 9.38711 58.8097 6.21502 51.1516C3.04292 43.4935 2.76302 34.9434 5.42745 27.0942C8.09189 19.245 13.5189 12.6321 20.6975 8.48759"
                            stroke="#24BA61" stroke-width="6.40622" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M24.9683 38.0768L33.5099 46.6184L50.5932 29.5352" stroke="#24BA61"
                            stroke-width="5.33852" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <h3 style="padding: 15px 0;font-weight: 600;">Request Submitted!</h3>
                    <p style="font-weight: 600;padding-bottom: 30px;">Your request has been submitted, you’ll be
                        notified when it gets<br>
                        approved and for further steps
                    </p>
                    <div class="d-flex justify-content-center align-items-center">
                        <button class="btn btn-warning close" style="width: 210px; opacity: 1;
                    font-size: 16px;
                    height: 48px;" data-dismiss="modal" aria-label="Close">Okay</button>
                    </div>
                </div>
                }
            </div>
        </div>

    </div>
</div>


<!-- Forward New Inventory Modal -->
 <!-- <div class="modal fade" id="forwardRequestModal" tabindex="-1" role="dialog" aria-labelledby="forwardRequestTitle"
    aria-hidden="true" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered forward-req-width" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close inven-cls" data-dismiss="modal" aria-label="Close">
                    <svg class="cross-svg" width="14" height="12" viewBox="0 0 14 14" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.5518 1.64453L1.85612 12.3402" stroke="#25252B" stroke-width="2.26313"
                            stroke-linecap="round" />
                        <path d="M12.5518 12.3401L1.85612 1.64445" stroke="#25252B" stroke-width="2.26313"
                            stroke-linecap="round" />
                    </svg>
                </button>
            </div>
            <div class="modal-body forward-req-container">
                @if (!showForwardSuccess) {
                <h1>Forward Request</h1>
                }
                @if(!showForwardSuccess){
                <div class="forward-container">
                    <form [formGroup]="forwardRequestForm" (ngSubmit)="onSubmitForwardReq(selectedInventory)">
                        <div class="form-row">
                            <div class="form-group col-md-6 maximum-cost-input">
                                <label for="maximumCost" class="form-label">Maximum Cost(₹)</label>
                                <input type="number" id="maximumCost" class="form-control"
                                    formControlName="maximumCost" />
                            </div>
                            <div class="form-group col-md-6">
                                <label for="forward" class="form-label">Forward to</label>
                                <mat-form-field style="width:320px">
                                    <mat-select formControlName="selectedAdmin">
                                        @for (adminUser of adminUsers; track adminUser) {
                                        <mat-option [value]="adminUser.id">{{adminUser.viewValue}}</mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="link1" class="form-label">Link 1</label>
                                <input type="text" id="link1" class="form-control" formControlName="link1" />
                            </div>
                            <div class="form-group col-md-6">
                                <label for="link2" class="form-label">Link 2</label>
                                <input type="text" id="link2" class="form-control" formControlName="link2" />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="link3" class="form-label">Link 3</label>
                                <input type="text" id="link3" class="form-control" formControlName="link3" />
                            </div>

                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-12 d-flex flex-column">
                                <label for="comment">Comment</label>
                                <textarea id="comment" placeholder="Add your comment" name="comment" rows="4" cols="50"
                                    formControlName="comment">
              </textarea>
                            </div>
                        </div>

                        <div class="approve-submit-button d-flex justify-content-center">
                            <button type="submit" data-toggle="modal" class="btn approve-submit"
                                data-target="#sentRequestModal">
                                Submit For Approval
                            </button>
                        </div>
                    </form>
                </div>
                }
                @if(showForwardSuccess){
                <div class="sent-req-content">
                    <svg class="tick-mark" width="76" height="76" viewBox="0 0 76 76" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M37.7808 3.91014C46.0698 3.91014 54.0763 6.92354 60.3083 12.3889C66.5404 17.8543 70.573 25.3989 71.655 33.617C72.7369 41.8352 70.7943 50.1664 66.1892 57.0586C61.584 63.9507 54.6303 68.9336 46.6237 71.079C38.6171 73.2244 30.1035 72.3858 22.6693 68.7197C15.2351 65.0535 9.38711 58.8097 6.21502 51.1516C3.04292 43.4935 2.76302 34.9434 5.42745 27.0942C8.09189 19.245 13.5189 12.6321 20.6975 8.48759"
                            stroke="#24BA61" stroke-width="6.40622" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M24.9683 38.0768L33.5099 46.6184L50.5932 29.5352" stroke="#24BA61"
                            stroke-width="5.33852" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <h3>Request Sent</h3>

                    <p>
                        Your request has been sent for approval, you will be <br />
                        notified once it gets approved by superadmin
                    </p>
                    <div class="d-flex justify-content-center align-items-center">
                        <button class="btn btn-warning close" style="width: 210px; opacity: 1;
                    font-size: 16px;
                    height: 48px;" data-dismiss="modal" aria-label="Close">Okay</button>
                    </div>
                </div>
                }
            </div>
        </div>
    </div>
</div>  -->

<app-forward-req [selectedInventory]= "selectedInventory"></app-forward-req>