<div class="containerBanner">
  <div class="menu-container">
    @if(!showServicePending){
    <h1>Return Requests<span>({{ this.totalAssigned }})</span></h1>
    }
    @if(!showReturnHead && showServicePending){
      <h1>Extended Requests<span>({{ this.totalExtend }})</span></h1>
      }
    <div
      class="menu-content d-flex align-items-center justify-content-between"
      style="margin-bottom: 15px"
    >
      <div class="search-menu">
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="search-icon"
        >
          <g opacity="0.4">
            <circle
              cx="11.0795"
              cy="11.3768"
              r="7.62168"
              transform="rotate(-45 11.0795 11.3768)"
              stroke="#092C4C"
              stroke-width="1.72802"
              stroke-linecap="round"
            />
            <path
              d="M16.6416 16.9393L19.8465 20.1443"
              stroke="#092C4C"
              stroke-width="1.72802"
              stroke-linecap="round"
            />
          </g>
        </svg>
        <input type="search" placeholder="Search requests.." />
      </div>
      <div
        class="btn-container d-flex align-items-center justify-content-between"
      >
        <mat-form-field
          style="
            width: 185px !important;
            height: 45px !important;
            margin-left: 12px;
          "
        >
          <mat-select placeholder="Return Request">
            @for (request of requests; track $index) {
            <mat-option
              [value]="request.value"
              (click)="onRequestSelect(request.value)"
            >
              {{ request.viewValue }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

<!-- Return Requests -->
@if(!showServicePending){
  <div class="checked-out-inv" style="overflow-x: auto">
    <table style="overflow-x: auto; height: 510px">
      <thead>
        <tr
          style="
            border-top: 1px solid #e7e7e7;
            border-bottom: 1px solid #e7e7e7;
            margin: 0 20px;
          "
        >
          <th scope="col" style="width: 580px">ASSET NAME</th>
          <th scope="col" style="width: 580px">ASSIGNED ON</th>
          <th scope="col" style="width: 310px">ACTIONS</th>
        </tr>
      </thead>
      <tbody>
        @for (inventories of inventory; track $index) {

        <tr>
          <td>
            <div class="d-flex align-items-center" style="width: 80%">
              <div class="inventory-img">
                <img
                  [src]="
                    inventories.assetImage
                      ? inventories.assetImage
                      : '../../../../assets/inventories/computer-system.png '
                  "
                  alt=""
                  width="60px"
                  height="60px"
                />
              </div>
              <p>{{ inventories.assetName }}</p>
            </div>
          </td>
          <td>
            <p>{{ inventories.assignedOn }}</p>
          </td>
          <td>
            <div class="d-none align-items-center justify-content-between">
              <button class="send d-none">Send Reminder</button>
              <button class="sent">
                <svg
                  width="13"
                  height="10"
                  viewBox="0 0 13 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style="margin-right: 5px"
                >
                  <path
                    d="M1.76489 4.95386L5.23359 8.5006L12.0059 1.57594"
                    stroke="#24BA61"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Reminder Sent
              </button>
              <button class="take-action">Extend</button>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <!-- <button class="take-action" style="margin-right: 15px;"
                    (click)="openReturn(inventories.assignedInventoryId,$index)" data-target="#assignedExtendModal"
                    data-toggle="modal">Request for Extend</button> -->
              <button
                class="decline"
                data-toggle="modal"
                data-target="#assignedReturnModal"
                (click)="openReturn(inventories.assignedInventoryId, $index)"
              >
                Return
              </button>
            </div>
          </td>
        </tr>
        }
      </tbody>
      <!-- @if(inventory.length === 0){
            <app-no-data></app-no-data>
           } -->
    </table>
  </div>
}

  <!-- Extend Request -->
  @if(!showReturnHead && showServicePending){
  <div class="checked-out-inv" style="overflow-x: auto">
    <table style="overflow-x: auto; height:470px">
      <thead>
        <tr style="
          border-top: 1px solid #e7e7e7;
          border-bottom: 1px solid #e7e7e7;
        ">
          <th scope="col" style="width: 20%">ASSET NAME</th>
          <th scope="col" style="width: 20%">TAKEN BY</th>
          <th scope="col" style="width: 20%">TAKEN ON</th>
          <th scope="col" style="width: 20%">RETURN DATE</th>
          <th scope="col" style="width: 20%">REQUESTED FOR</th>
          <th scope="col" style="width: 20%">ACTIONS</th>
        </tr>
      </thead>
      <tbody>
        @for (extend of extendInv; track $index) {
        <tr>
          <td>
            <div class="d-flex align-items-center" style="width: 80%">
              <div class="inventory-img">
                <img [src]="extend.assetImage" alt="" width="60px" height="60px" />
              </div>
              <p>{{extend.assetName}}</p>
            </div>
          </td>
          <td>
            <p>{{extend.takenBy}}</p>
          </td>
          <td>
            <p>{{extend.takenOn}}</p>
          </td>
          <td>
            <p>{{extend.returnDate}}</p>
          </td>
          <td>
            <p>{{extend.requestedFor}} Days</p>
          </td>

          <td>
            <div class="d-flex align-items-center">
              <button class="accept" style="margin-right: 15px">
                Accept
              </button>
              <button class="decline">Decline</button>
            </div>
          </td>
        </tr>

        }
      </tbody>
      @if(extendInv.length === 0){
        <app-no-data></app-no-data>
       }
    </table>
  </div>
}

<!-- Pagination -->
<nav aria-label="Page navigation example" style="position: absolute;left: 50%;bottom: 3%;">
  <ul class="pagination" style="margin-top: 10px;">
    <li class="page-item" [ngClass]="{ disabled: currentPage === 1 }">
      <a class="page-link" href="javascript:void(0)" aria-label="Previous"
        (click)="currentPage !== 1 ? pageChanged(currentPage - 1) : null">
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20">
          <path fill="currentColor" d="m4 10l9 9l1.4-1.5L7 10l7.4-7.5L13 1z" />
        </svg>
      </a>
    </li>
    @for(page of visiblePages; track page){
    <li class="page-item" [ngClass]="{
        active: currentPage === page,
        hidden: !isPageVisible(page)
      }">
      <a class="page-link" href="javascript:void(0)" (click)="pageChanged(page)">{{ page }}</a>
    </li>
    }

    <li class="page-item" [ngClass]="{ disabled: currentPage === totalPages }">
      <a class="page-link" href="javascript:void(0)" aria-label="Next" (click)="
          currentPage !== totalPages ? pageChanged(currentPage + 1) : null
        ">
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20">
          <path fill="currentColor" d="M7 1L5.6 2.5L13 10l-7.4 7.5L7 19l9-9z" />
        </svg>
      </a>
    </li>
  </ul>
</nav>
  <!-- Inventory Return Request Modal -->
  @for (return of returnInv; track $index) {
  <div
    class="modal fade"
    id="inventoryReturnReqModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="assetReturnReqModalTitle"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered return-req-slider"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="assetReturnReqModalLongTitle">
            Asset Return Request
          </h5>
          <button
            type="button"
            class="close inven-cls"
            data-dismiss="modal"
            aria-label="Close"
          >
            <svg
              class="cross-svg"
              width="14"
              height="12"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5518 1.64453L1.85612 12.3402"
                stroke="#25252B"
                stroke-width="2.26313"
                stroke-linecap="round"
              />
              <path
                d="M12.5518 12.3401L1.85612 1.64445"
                stroke="#25252B"
                stroke-width="2.26313"
                stroke-linecap="round"
              />
            </svg>
          </button>
        </div>
        <div class="modal-body">
          @if (!showDeclineForm) {
          <div class="">
            <h6
              style="
                padding-bottom: 25px;
                text-align: center;
                font-weight: 600;
                font-size: 24px;
              "
            >
              Photos Uploaded By User
            </h6>
            <div
              id="productImagecarousel"
              class="carousel slide product-carousel"
              data-ride="carousel"
              data-interval="false"
              style="margin: 0 auto"
            >
              <ol class="carousel-indicators return-pointers">
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="0"
                  class="active"
                ></li>
                @for ( productImg of previewReturnImg?.images.slice(1) ; track
                $index) {
                <li
                  data-target="#carouselExampleIndicators"
                  attr.data-slide-to="{{ $index }}"
                ></li>

                }
              </ol>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    [src]="
                      previewReturnImg?.images[0]
                        ? previewReturnImg?.images[0]
                        : '../../../assets/inventories/computer-system.jpg'
                    "
                    class="d-block"
                    alt="..."
                    style="
                      width: 380px;
                      height: 290px;
                      margin: 16px auto;
                      object-fit: contain;
                    "
                  />
                </div>
                @for ( productImg of previewReturnImg?.images.slice(1) ; track
                $index) {
                <div class="carousel-item">
                  <img
                    [src]="[productImg]"
                    class="d-block"
                    alt="..."
                    style="width: fit-content; height: 290px; margin: 16px auto"
                  />
                </div>
                }
              </div>

              <button
                class="carousel-control-prev"
                type="button"
                data-target="#productImagecarousel"
                data-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-target="#productImagecarousel"
                data-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
              </button>
            </div>
            <div
              class="asset-rtn-button d-flex justify-content-center align-items-center"
              style="margin: 60px 0 40px 0"
            >
              <button
                class="btn btn-accept"
                (click)="acceptReturnRequest(return.requestId)"
              >
                Accept Request
              </button>
              <button class="btn btn-decline" (click)="onDeclineButtonClick()">
                Decline Request
              </button>
            </div>
          </div>
          } @if (showDeclineForm) {
          <div class="reject-container">
            <h6
              style="
                padding-bottom: 25px;
                text-align: center;
                font-weight: 600;
                font-size: 24px;
              "
            >
              Reject Request
            </h6>
            <form [formGroup]="declineReasonForm">
              <div
                class="d-flex flex-column justify-content-center"
                style="margin: 0 auto; width: 700px"
              >
                <label for="rejectionReason">Comment</label>
                <textarea
                  id="rejectionReason"
                  name="contact"
                  rows="3"
                  formControlName="rejectionReason"
                  required
                >
                </textarea>
              </div>
              <button
                class="close inven-close btn btn-decline d-flex justify-content-center align-items-center"
                style="margin: 30px auto"
                (click)="rejectReturnRequest(return.requestId)"
                aria-label="Close"
              >
                Confirm Reject Request
              </button>
            </form>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
  }

  <!-- Assigned Return Modal -->
<div class="modal fade" id="assignedReturnModal" tabindex="-1" role="dialog" aria-labelledby="chargingReminderTitle"
aria-hidden="true">
<div class="modal-dialog modal-dialog-centered assigned-return-width" role="document">
  <div class="modal-content">
    <div class="modal-header charging-head">
      <h1>Upload Asset Images</h1>
      <button type="button" class="close inven-cls" data-dismiss="modal" aria-label="Close">
        <svg class="cross-svg" width="14" height="12" viewBox="0 0 14 14" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M12.5518 1.64453L1.85612 12.3402" stroke="#25252B" stroke-width="2.26313" stroke-linecap="round" />
          <path d="M12.5518 12.3401L1.85612 1.64445" stroke="#25252B" stroke-width="2.26313" stroke-linecap="round" />
        </svg>
      </button>
    </div>
    <div class="modal-body charging-container">
      @if (!showForwardSuccess) {
      <div class="sent-req-content">
        <form [formGroup]="uploadAssetImageForm">
          <div class="form-row">
            @if (selectedFiles.length < 4) { <div class="form-group col-md-12">
              <div class="invoice-upload">
                <div class="invoice-content" style="left: 39%;">
                  <svg width="54" height="37" viewBox="0 0 54 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M43.0897 13.9457C41.5792 6.28235 34.8488 0.529297 26.7634 0.529297C20.344 0.529297 14.7687 4.17215 11.9921 9.50316C5.30613 10.214 0.108398 15.8782 0.108398 22.7418C0.108398 30.0942 6.08357 36.0694 13.4359 36.0694H42.3122C48.4429 36.0694 53.4185 31.0938 53.4185 24.9631C53.4185 19.099 48.8649 14.3455 43.0897 13.9457ZM42.3122 31.6269H13.4359C8.52695 31.6269 4.55091 27.6508 4.55091 22.7418C4.55091 18.1883 7.94943 14.3899 12.4586 13.9235L14.8353 13.6791L15.9459 11.5689C18.0561 7.50404 22.1877 4.97181 26.7634 4.97181C32.5831 4.97181 37.6032 9.10334 38.736 14.812L39.4024 18.1438L42.8009 18.3882C46.2661 18.6103 48.976 21.5202 48.976 24.9631C48.976 28.6282 45.9773 31.6269 42.3122 31.6269ZM17.8784 20.5206H23.5426V27.1843H29.9843V20.5206H35.6485L26.7634 11.6356L17.8784 20.5206Z"
                      fill="#030303" />
                  </svg>
                  <p class="drag-text">
                    <span>Drag your file(s)</span>
                  </p>
                  <p class="file-size" style="font-weight: 400;font-size: 16px;">Max 10 MB files are allowed</p>
                </div>
                <input type="file" class="form-control" formControlName="assetImage" accept=".png,.jpeg"
                  (change)="onFileSelected($event)" multiple style="width: 100% !important;;" />
              </div>
              <p class="text-left" style="font-weight: 400;font-size: 16px;padding-top: 15px;">
                <span>Maximum 4 files</span>
              </p>
          </div>
          }

          <div class="form-group col-md-12 d-flex justify-content-between flex-wrap flex-row">
            @for (file of selectedFiles; track $index) {
            <div class="col-md-5 d-flex justify-content-between align-items-center uploaded-images"
              style="margin-bottom: 15px;border: 1px solid #e7e7e7;
              border-radius: 8px;
              padding: 10px; ">
              <div class="d-flex align-items-center">
                <img [src]="file.preview" alt="" width="60px" height="60px">
                <div style="padding-left: 10px;">
                  <p style="margin-bottom: 0;font-weight: 400;">{{ file.name }}</p>
                  <span class="text-left ">{{ file.size }}kb</span>
                </div>
              </div>
              <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg"
                (click)="removeFile(file)" style="cursor: pointer;">
                <circle opacity=" 0.1" cx="15.3789" cy="15.5879" r="15" fill="#030303" />
                <path d="M11.5723 11.7832L19.1813 19.3923" stroke="#030303" stroke-width="1.56126"
                  stroke-linecap="round" />
                <path d="M19.1816 11.7832L11.5726 19.3923" stroke="#030303" stroke-width="1.56126"
                  stroke-linecap="round" />
              </svg>

            </div>
            }
          </div>
      </div>
      <button type="button" class="btn btn-warning" (click)="onReturnSubmit()">Upload And Submit</button>
      </form>
    </div>
    }
    @if (showForwardSuccess) {
    <div class="sent-req-content">
      <svg class="tick-mark" width="76" height="76" viewBox="0 0 76 76" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M37.7808 3.91014C46.0698 3.91014 54.0763 6.92354 60.3083 12.3889C66.5404 17.8543 70.573 25.3989 71.655 33.617C72.7369 41.8352 70.7943 50.1664 66.1892 57.0586C61.584 63.9507 54.6303 68.9336 46.6237 71.079C38.6171 73.2244 30.1035 72.3858 22.6693 68.7197C15.2351 65.0535 9.38711 58.8097 6.21502 51.1516C3.04292 43.4935 2.76302 34.9434 5.42745 27.0942C8.09189 19.245 13.5189 12.6321 20.6975 8.48759"
          stroke="#24BA61" stroke-width="6.40622" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M24.9683 38.0768L33.5099 46.6184L50.5932 29.5352" stroke="#24BA61" stroke-width="5.33852"
          stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <h3>Request Sent</h3>

      <p>
        Your request has been sent for approval, you will be <br />
        notified once it gets approved by superadmin
      </p>
      <div class="d-flex justify-content-center align-items-center">
        <button class="btn btn-warning close" style="width: 210px; opacity: 1;
              font-size: 16px;
              height: 48px;" data-dismiss="modal" aria-label="Close">Okay</button>
      </div>
    </div>
    }
  </div>
</div>
</div>


</div>
