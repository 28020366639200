import { Component, OnInit } from "@angular/core";
import { Router, NavigationStart, RouterOutlet } from "@angular/router";
import { AlertsService } from "./service/alerts.service";
import { ChatService } from "./service/chat.service";
import { LiveProjectService } from "./service/live-project.service";
import { UserService } from "./service/user.service";
import { LoaderService } from "./service/loader.service";
import { FooterComponent } from "./component/footer/footer.component";
import { AlertsComponent } from "./component/alerts/alerts.component";
import { NewSingleChatComponent } from "./component/new-single-chat/new-single-chat.component";
import { NewGroupChatComponent } from "./component/new-group-chat/new-group-chat.component";

declare var $: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  standalone: true,
  imports: [
    NewGroupChatComponent,
    NewSingleChatComponent,
    RouterOutlet,
    AlertsComponent,
    FooterComponent,
  ],
})
export class AppComponent implements OnInit {
  title = "link-frontend";
  showChat = false;
  message;
  audio = new Audio("../assets/bell.mp3");
  User = "";
  isMobile: boolean;

  constructor(
    public router: Router,
    public chatService: ChatService,
    private userService: UserService,
    private alertsService: AlertsService,
    private liveProjectService: LiveProjectService,
    public loader: LoaderService
  ) {
    // on route change to '/login', set the variable showHead to false
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (
          event["url"] == "/login" ||
          event["url"] === "/" ||
          event["url"].includes("/video-call/") ||
          event["url"] == "/registration"
        ) {
          this.showChat = false;
        } else {
          this.showChat = true;
        }
      }
    });
  }

  ngOnInit() {
    window.onmessage = function (e) {
      if (e.data == "update") {
        window.location.href = window.location.href;
        window.location.reload();
      }
      localStorage.setItem("version", "V1");
    };

    navigator.serviceWorker.addEventListener("message", (event) => {
      let resp = event.data;
      this.message = resp;

      if (resp["data"]["meetingType"] == "instant") {
        let starting = Date.parse(resp["data"]["startingTime"]);
        let current = Date.now();
        let delay = (current - starting) / 1000;
        console.log(
          "starting :-",
          starting,
          "current :-",
          current,
          "delay :-",
          delay
        );
        if (delay < 30) {
          let others = resp["data"]["usernames"].split(",");
          others = others.filter((x) => x != localStorage.getItem("username"));
          others = others.filter((x) => x != resp["data"]["creator"]);
          if (others.length) {
            if (others.length == 1) {
              this.User =
                this.nameCaller(resp["data"]["creator"]) +
                " and " +
                this.nameCaller(others[0]);
            } else if (others.length == 2) {
              this.User =
                this.nameCaller(resp["data"]["creator"]) +
                ", " +
                this.nameCaller(others[0]) +
                " and 1 Other";
            } else {
              this.User =
                this.nameCaller(resp["data"]["creator"]) +
                ", " +
                this.nameCaller(others[0]) +
                " and " +
                (others.length - 1) +
                " Others";
            }
          } else {
            this.User = this.nameCaller(resp["data"]["creator"]);
          }

          let element = document.getElementById("alert");
          element.classList.remove("hide");
          this.audio.currentTime = 0;
          this.audio.play();
          setTimeout(() => {
            this.audio.pause();
            this.audio.currentTime = 0;
            element.classList.add("hide");
          }, 30000);
        }
      } else if (resp["data"]["meetingType"] == "scheduled") {
        let date = new Date(resp["data"]["startingTime"]);
        this.alertsService.sendAlert(
          resp["data"]["body"] +
          ` on ${date.toLocaleDateString("en-GB", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          })} at ${date.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })}`
        );
      } else if (resp["data"]["meetingType"] == "reminder") {
        this.alertsService.sendAlert(resp["data"]["body"]);
      } else if (resp["data"]["meetingType"] == "autocall") {
        let others = resp["data"]["usernames"].split(",");
        others = others.filter((x) => x != localStorage.getItem("username"));
        others = others.filter((x) => x != resp["data"]["creator"]);
        if (others.length) {
          if (others.length == 1) {
            this.User =
              this.nameCaller(resp["data"]["creator"]) +
              " and " +
              this.nameCaller(others[0]);
          } else if (others.length == 2) {
            this.User =
              this.nameCaller(resp["data"]["creator"]) +
              ", " +
              this.nameCaller(others[0]) +
              " and 1 Other";
          } else {
            this.User =
              this.nameCaller(resp["data"]["creator"]) +
              ", " +
              this.nameCaller(others[0]) +
              " and " +
              (others.length - 1) +
              " Others";
          }
        } else {
          this.User = this.nameCaller(resp["data"]["creator"]);
        }

        let element = document.getElementById("alert");
        element.classList.remove("hide");
        this.audio.currentTime = 0;
        this.audio.play();
        setTimeout(() => {
          this.audio.pause();
          this.audio.currentTime = 0;
          element.classList.add("hide");
        }, 30000);
      }
    });
  }

  onReloadDoc() {
    console.log("in fnc");
    window.postMessage("update", "*");
  }

  nameCaller(string) {
    return string[0].toUpperCase() + string.slice(1);
  }

  onClickDecline() {
    document.getElementById("alert").classList.add("hide");
    this.audio.pause();
    this.audio.currentTime = 0;
  }

  onClickAccept() {
    document.getElementById("alert").classList.add("hide");
    this.audio.pause();
    this.audio.currentTime = 0;
    console.log("message in accept func", this.message);

    if (this.message["data"]["meetingType"] == "instant") {
      this.userService
        .updateUserStatus(this.message["data"]["_id"])
        .subscribe((resp) => {
          console.log("update user status", resp);
          this.liveProjectService.activeInstantMeeting = this.message["data"];
          if (window.location.href.includes("video-call")) {
            console.log("already in the video call route");
            (<any>document.getElementById("vid-frame")).src =
              this.message["data"]["contents"];
          } else {
            this.router.navigate(["./video-call"]);
          }
        });
    } else if (this.message["data"]["meetingType"] == "autocall") {
      this.userService
        .updateUserScheduledStatus(this.message["data"]["_id"])
        .subscribe((resp) => {
          console.log("update user status", resp);
          this.liveProjectService.activeInstantMeeting = this.message["data"];
          if (window.location.href.includes("video-call")) {
            console.log("already in the video call route");
            (<any>document.getElementById("vid-frame")).src =
              this.message["data"]["contents"];
          } else {
            this.router.navigate(["./video-call"]);
          }
        });
    }
  }

  shouldShowChat(): boolean {
    const currentRoute = this.router.url;
    return currentRoute.includes("/home");
  }
}
