import { Channel, Converse } from "converse.svc-client";
import axios from "axios";
// @ts-ignore
import Project from "converse.svc-client/src/project";
import { Component } from "@angular/core";
import { UserService } from "src/app/service/user.service";
import { OnlineUsersService } from "src/app/service/online-users.service";
import { ChatService } from "src/app/service/chat.service";
import { ChatSortPipe } from "../../pipe/chat-sort.pipe";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { environment } from "src/environments/environment";
import { ChangeDetectorRef } from "@angular/core";
import { NgStyle } from "@angular/common";

const CHUNK_SIZE = 500 * 1024 * 1024; 
const MIN_CHUNK_SIZE = 10 * 1024 * 1024; 
@Component({
  selector: "app-new-single-chat",
  templateUrl: "./new-single-chat.component.html",
  styleUrls: ["./new-single-chat.component.css"],
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, DatePipe, ChatSortPipe ,NgStyle],
})
export class NewSingleChatComponent {
  // @ViewChild('chatBody', { static: true }) chatBody!: ElementRef;
  constructor(
    private api: UserService,
    private OnlineUsersService: OnlineUsersService,
    private chatService: ChatService,
    private cdr: ChangeDetectorRef
  ) {}
  converse: Converse | null = null;

  UserData: any[];
  singleUser: any;
  showUsers1: boolean = false;
  showUsers2: boolean = false;
  showChatContainer: boolean = true;
  showcount: boolean = true;
  token: string;
  projectId = "project_link_module";
  newMessage: string = "";
  singleChatMessages: any;
  singleChatMessages2: any;
  userId: any;
  showFileName: string;
  channelCache: any[] = [];
  isLoading: boolean = false;
  chatBody: any;
  chatUserName: string;
  chatUserName2: string;
  getfilediv: any;
  isActiveChatIndex: number = 0;
  AllMessages: any = {};
  lastMessage: any = {};
  searchTerm: string = "";
  filteredUsers: any[] = [];
  fileId: any;
  sendfile1: any;
  sendfile2: any;
  chatUserId: any;
  newMessage2: string = "";
  previousTimestamp: Date | null = null;
  chan: Channel | null = null;
  allUsers: any[] = [];
 
  ngOnInit(): void {
    this.userId = localStorage.getItem("guestID");
    this.projectId = environment.converseProjectId;
    this.getChatAndUsers();
  }

  newReceiver1: any;
  newReceiver2: any;
  newUserName: any;
  lastUpdatedChat: number = 2;
  async startChat(userId: string) {
    this.scrollToBottom();
    this.chatUserId = userId;
    this.singleUser = this.filteredUsers.find((user) => user.userId === userId);
    if (!this.singleUser) return;

    console.log("New Receiver:", this.singleUser.userId, this.singleUser.userName);

   
    // if (this.newReceiver1 === this.singleUser.userId || this.newReceiver2 === this.singleUser.userId) {
    //   console.log("User is already in a chatbox.");
    //   return;
    // }

   
    if (!this.showUsers1) {
      this.assignToChatbox(1, this.singleUser);
    } else if (!this.showUsers2) {
      this.assignToChatbox(2, this.singleUser);
    } else {
      this.assignToChatbox(2, this.singleUser);
    }

    if (!this.singleUser.channelId) {
      const body = {
        members: [
          {
            userId: this.singleUser.userId,
            userName: this.singleUser.userName,
            profileImage: this.singleUser.profileImage,
            fcmToken: "",
          },
        ],
        type: "single",
      };

      this.api.sendSingleChatUserData(body).subscribe(
        (res: any) => {
          if (res?.data?.chatId) { 
            this.singleUser.channelId = res.data.chatId;
            this.handleConnection(this.singleUser,res.data.chatId);
            this.scrollToBottom();
          }
        },
        (error) => {
          console.error("Error creating chat:", error);
        }
      );
    } else {
      this.handleConnection(this.singleUser,this.singleUser.channelId);
    }

    // Reset unread count
    setTimeout(() => {
      let user = this.filteredUsers.find((user) => user.userId === userId);
      if (user) {
        user.unreadCount = 0;
      }
    }, 500);
  }

  //Assigning chatbox1 and chatbox2 chat ON Creating Chat
  assignToChatbox(boxNumber: number, user: any) {
    if (boxNumber === 1) {
      this.newReceiver1 = user.userId;
      this.chatUserName = user.userName;
      this.userProfileImg1 = user.profileImage;
      this.showUsers1 = true;
    } else {
      this.newReceiver2 = user.userId;
      this.chatUserName2 = user.userName;
      this.userProfileImg2 = user.profileImage;
      this.showUsers2 = true;
    }
  }
  
  getTimeAgo(timestamp: string | Date): string {
    const now = new Date();
    const sentTime =
      typeof timestamp === "string" ? new Date(timestamp) : timestamp;
    const diffInMilliseconds = now.getTime() - sentTime.getTime();
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

    if (diffInHours < 24) {
      return sentTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    } else {
      return sentTime.toLocaleDateString();
    }
  }

  //Get All Chats and Users
  getChatAndUsers() {
    this.chatService.getChats().subscribe((resp: any) => {
      this.allUsers = resp.data
        .map((user) => ({
          ...user,
          lastMessageTimeAgo: user.lastMessage?.updatedAt
            ? this.getTimeAgo(user.lastMessage.updatedAt)
            : "",
        }))
        .sort(
          (a, b) =>
            new Date(b.lastMessage?.updatedAt || 0).getTime() -
            new Date(a.lastMessage?.updatedAt || 0).getTime()
        );

      this.filteredUsers = [...this.allUsers];
    });
  }

  // Search function to filter users by userName
  updateFilteredUsers() {
    console.log("updateFilteredUsers", this.searchTerm);
    if (!this.searchTerm.trim()) {
      this.filteredUsers = [...this.allUsers];
    } else {
      this.filteredUsers = this.allUsers.filter(
        (user) =>
          user.userName &&
          user.userName.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }
  }

  //Initiate socket connection
  userProfileImg1: any;
  userProfileImg2: any;
  async initiatChat() {
    this.converse = new Converse();
    let converseToken = localStorage.getItem("converseToken");

    if (!converseToken) {
      const res = await fetch(environment.host + "chats/create-token", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("guestToken")}`,
        },
        method: "POST",
        body: JSON.stringify({
          projectId: "project_link_module_test",
        }),
      });

      const jsonResponse = await res.json();
      converseToken = jsonResponse.data.token;

      localStorage.setItem("converseToken", converseToken);
    }

    await this.converse.init({
      projectId: "project_link_module_test",
      converseToken,
    });

  }

  //Create chat socket connection
  async handleConnection( singleUser:any , channelId: string) {
    if (!this.converse) return;
    
    try {
      this.chan = await this.converse.connectChannel({
        channelId:this.singleUser.channelId,
      });
      console.log("SOME DATA FOR THE CHANNEL", singleUser.channelId);
       
      if (this.chan) {
        if (this.channelCache.length >= 2) {
          this.channelCache[0].close();
          this.channelCache.shift();
        }
        this.channelCache.push(this.chan);

        if (this.channelCache.length === 1) {
          this.chatUserName = singleUser.userName;
          this.userProfileImg1 = singleUser.profileImage;
          this.newReceiver1 = singleUser.userId;
          await this.getAllMsg1(this.chan);
          this.showUsers1 = true;
        } else if (this.channelCache.length === 2) {
          this.chatUserName2 = singleUser.userName;
          this.userProfileImg2 = singleUser.profileImage;
          this.newReceiver2 = singleUser.userId;
          await this.getAllMsg2(this.chan);
          this.showUsers2 = true;
        }
      }
    } catch (error) {
      console.log("chat errored", error);
    }
  }


  //URL REGEX
  isURL(str: string): boolean {
    const urlRegex = /^(?:(?:https?|ftp):)?\/\/[^\s/$.?#].[^\s]*$/;
    return urlRegex.test(str);
  }

  
  filterDataSearch: any;
  filterDataSearchLength: any;
  private isFirstTextTyping: boolean = true;
  skipApi: boolean = false;
  MetaDataDetails: any;
  MedataLength: any;
  //Meta Tags Versioning
  onInputChange(): void {
    if (!this.newMessage) {
      // Reset suggestion data
      this.MetaDataDetails = null;
      this.MedataLength = 0;
      this.filterDataSearch = [];
      this.filterDataSearchLength = 0;
      this.isFirstTextTyping = true;
      return;
    }
  
    if (this.newMessage.split("")[0] == "/") {
      let newText = this.newMessage.slice(1);
      if (newText) {
        let text = newText.toLowerCase();
        let filterData = this.singleChatMessages.filter((msg: any) =>
          msg.message?.toLowerCase()?.includes(text)
        );
        this.filterDataSearch = filterData;
        this.filterDataSearchLength = filterData.length;
      }
    }
  
    let result = this.isURL(this.newMessage);
  
   
    window.addEventListener("keydown", (event) => {
      if (event.keyCode === 8) {
        if (!this.isURL(this.newMessage)) {
          this.MetaDataDetails = null;
          this.MedataLength = 0;
          this.skipApi = true;
        }
        return;
      }
    });
  
    // Allow pasting events to process URLs
    window.addEventListener("paste", (event) => {});
  
    if (result && !this.skipApi) {
      let body = {
         url: this.newMessage,
         channelId: this.singleUser.channelId 
        };
      this.OnlineUsersService.isUrlValid(body).subscribe((res: any) => {
        if (res?.data) {
          if (!this.MetaDataDetails || this.MetaDataDetails.fileUrl !== res.data.fileUrl) {
            // If it's a new URL, reset the metadata
            this.MetaDataDetails = res.data;
            this.MedataLength = 1;
          } else {
            // If it's the same URL, append the new version to `thread`
            if (!this.MetaDataDetails.thread) {
              this.MetaDataDetails.thread = [];
            }
            this.MetaDataDetails.thread.push({
              fileUrl: res.data.fileUrl,
              _id: res.data._id,
              version: this.MetaDataDetails.thread.length + 1,
            });
            this.MedataLength = this.MetaDataDetails.thread.length + 1;
          }
        } else {
          this.MetaDataDetails = null;
          this.MedataLength = 0;
        }
        console.log("MetaDataDetails", this.MetaDataDetails?.thread);
      });
  
      this.isFirstTextTyping = false;
    }
  }
  
  onSuggestionSelectedAction(selectedSuggestion: string): void {
    console.log("this is selected suggestions", selectedSuggestion);
  }
  selectSuggestion(selectedSuggestion: string, fileId?: any): void {
    this.newMessage = selectedSuggestion;
    this.fileId = fileId;
    // Clearing suggestions after selection
    this.filterDataSearch = [];
    this.filterDataSearchLength = 0;
    this.onSuggestionSelectedAction(selectedSuggestion);
  }

  //Opening URL in new window
  openUrlInNewWindow(url: string): void {
    if (this.isURL(url)) {
      window.open(url, "_blank");
    }
  }
  //Resetting Meta Suggestion Data
  linkImagePreview: any;
  resetSuggestionData(): void {
    this.MetaDataDetails = null;
    this.MedataLength = null;
  }

  scrollToBottom() {
    setTimeout(() => {
      document.querySelectorAll(".chat-wrapper").forEach((val) => {
        val.scrollTo(0, 12313);
      });
    }, 100);
  }


  // Function to call  getMessages()
  //ChatBOX 1
  async getAllMsg1(channel: any) {
    try {
      let messages = await channel.getMessages({
        limit: 50,
        beforeMessageId: null,
      });

      this.AllMessages = {
        read: messages.msgs.read || [],
        unread: messages.msgs.unread || [],
      };

      this.singleChatMessages = [
        ...this.AllMessages.read,
        ...this.AllMessages.unread,
      ]
      this.scrollToBottom();
      this.processMessages();
      let user = this.filteredUsers.find(
        (user) => user.userId === this.singleUser.userId
      );
      if (user) {
        user.unreadCount = 0;
      }

      if (!this.channelCache[0].hasListener) {
        this.channelCache[0].listenMessage(this.handleIncomingMessage1);
        this.channelCache[0].hasListener = true;
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  }

  //ChatBOX 2
  async getAllMsg2(channel: any) {
    try {
      let messages = await channel.getMessages({
        limit: 50,
        beforeMessageId: null,
      });

      this.AllMessages = {
        read: messages.msgs.read || [],
        unread: messages.msgs.unread || [],
      };

      this.singleChatMessages2 = [
        ...this.AllMessages.read,
        ...this.AllMessages.unread,
      ]

      this.scrollToBottom();
      this.processMessages();
      let user = this.filteredUsers.find(
        (user) => user.userId === this.singleUser.userId
      );
      if (user) {
        user.unreadCount = 0;
      }

      // Prevent duplicate listeners
      if (!this.channelCache[1].hasListener) {
        this.channelCache[1].listenMessage(this.handleIncomingMessage1);
        this.channelCache[1].hasListener = true;
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  }

  //Handle Incoming Messages in Chatbox1 Chat
  handleIncomingMessage1 = (msg: any) => {
    console.log("handleIncomingMessage1 received:", msg);
    // this.singleChatMessages.push(msg);
    const newListenMsgObj = {
      sender_id: msg.sid,
      createdAt: msg.createdAt,
      url: msg.url,  
      message: msg.message 
      
    };
    if(this.isActiveChatIndex === 0){
      this.singleChatMessages.push(newListenMsgObj);
    }
    else{
      this.singleChatMessages2.push(newListenMsgObj);
    }
  
    this.scrollToBottom();
    console.log("newListenMsgObj",newListenMsgObj, {msg}, msg.fileUrl, );
  
    let user = this.filteredUsers.find(user => user.userId === msg.sender_id);
    if (user) {
        user.lastMessage = {
            message: msg.message,
            creationTime: msg.creationTime,
        };
    }
    this.sortUsersByRecentChats();
  }
  // Handle Incoming Messages in Chatbox2 Chat
//   handleIncomingMessage2 = (msg: any) => {
//     // this.singleChatMessages2.push(msg);
//     const newListenMsgObj = {
//       sender_id: msg.sid,
//       created: msg.createdAt,
//       url: msg.url,  
//       message: msg.type ? msg.message : "",
//     };
// if(this.isActiveChatIndex === 1)
// {
//   this.singleChatMessages2.push(newListenMsgObj);

// }
//     this.scrollToBottom();
//     let user = this.filteredUsers.find((user) => user.userId === msg.sender_id);
//     if (user) {
//       user.lastMessage = {
//         message: msg.message,
//         creationTime: msg.creationTime,
//       };
//     }
//     this.sortUsersByRecentChats();
//   };

  //Sending Message in Chatbox1 and Chatbox2
  urlMessage: any;
  async sendChatMessage(chatIndex: number) {
    const isPrimaryChat = chatIndex === 0;
    const messageText = isPrimaryChat ? this.newMessage : this.newMessage2;
    const fileToSend = isPrimaryChat ? this.sendfile1 : this.sendfile2;
    const receiverId = isPrimaryChat ? this.newReceiver1 : this.newReceiver2;
    const chatChannel = this.channelCache[chatIndex];
  
    if (!messageText.trim() && !fileToSend) return;
  
    let newMessageObj: any = {
      message: messageText,
      sender_id: this.userId,
      createdAt: new Date(),
      meta: null, 
    };
  
    // Check if message is a URL
    if (this.isURL(messageText)) {
      let body = {
        url: messageText,
        channelId: this.singleUser.channelId,
      };
  
      if (this.fileId) body["fileId"] = this.fileId;
  
     await this.OnlineUsersService.isUrlValidSave(body).then((res: any) => {
        if (res?.data) {
         
          newMessageObj.meta = {
            title: res.data.meta.title || "",
            description: res.data.meta.description || "",
            image: res.data.meta.icon || "",
          };
  
          console.log("Updated message with metadata:", newMessageObj);
        }
  
        this.cdr.detectChanges();
      });
    }
  
    // Send message via socket
    await chatChannel.sendMessage(newMessageObj);
  
    // Update user chat preview
    let user = this.filteredUsers.find((user) => user.userId === receiverId);
    if (user) {
      user.lastMessage = newMessageObj;
      user.lastMessageTimeAgo = this.getTimeAgo(newMessageObj.createdAt.toISOString());
      this.sortUsersByRecentChats();
      this.filteredUsers = [...this.filteredUsers];
      this.cdr.detectChanges();
    }
  
    // Push message to chat UI
    if (isPrimaryChat) {
      this.singleChatMessages.push(newMessageObj);
      this.newMessage = "";
    } else {
      this.singleChatMessages2.push(newMessageObj);
      this.newMessage2 = "";
    }
  
    this.showFileName = "";
    this.resetSuggestionData();
    this.scrollToBottom();
  }
  

  sortUsersByRecentChats() {
    this.filteredUsers.sort((a, b) => {
      const timeA = new Date(a.lastMessage?.createdAt || 0).getTime();
      const timeB = new Date(b.lastMessage?.createdAt || 0).getTime();

      return timeB - timeA;
    });
  }

  //Handle Media File Upload
  uploadProgress = 0;
  private getDynamicChunks(fileSize: number) {
    let chunks = [];
    let totalChunks = Math.ceil(fileSize / CHUNK_SIZE);

    for (let i = 0; i < totalChunks; i++) {
      let start = i * CHUNK_SIZE;
      let end = Math.min(start + CHUNK_SIZE, fileSize);

      if (
        i === totalChunks - 1 &&
        end - start < MIN_CHUNK_SIZE &&
        chunks.length > 0
      ) {
        let previousChunk = chunks.pop();
        start = previousChunk.start;
        end = fileSize;
        totalChunks--;
      }

      chunks.push({ start, end });
    }

    return { chunks, totalChunks };
  }

  //Uploading File
  async uploadFile(file: File, chatIndex: number) {
    this.isLoading = true;
    this.scrollToBottom();
    this.isActiveChatIndex = chatIndex;
    const fileBuffer = await file.arrayBuffer();
    const fileBytes = fileBuffer.byteLength;
    let { chunks, totalChunks } = this.getDynamicChunks(file.size);

    try {
        const response = await this.chatService
            .getPreSignedUrls(file.name, totalChunks)
            .toPromise();

        if (!response?.data) {
            console.error("Error: Invalid response from pre-signed URL API.");
            return;
        }

        const { uploadId, preSignedUrls, fileName, fileUrl } = response.data;
        let uploadedParts: any[] = [];
        let chunkProgress: { [key: number]: number } = {};

        // Upload chunks in parallel
        await Promise.all(
            preSignedUrls.map(async ({ partNumber, url }: any, index: number) => {
                const chunk = file.slice(chunks[index].start, chunks[index].end);
                try {
                    const res = await axios.put(url, chunk, {
                        headers: { "Content-Type": "application/octet-stream" },
                        onUploadProgress: (progressEvent) => {
                          chunkProgress[partNumber] = progressEvent.loaded;
                          const totalUploaded = Object.values(chunkProgress).reduce((a, b) => a + b, 0);
                          this.uploadProgress = Math.min((totalUploaded / fileBytes) * 100, 100);
                      
                          document.getElementById("progress-text").innerText = `${Math.floor(this.uploadProgress)}%`;
                      
                          const progressBar = document.querySelector('.circle-progress') as HTMLElement;
                          if (progressBar) {
                              const percent = this.uploadProgress;
                              progressBar.style.strokeDashoffset = `${100 - percent}`;
                          }
                      },
                      
                    });

                    const eTag = res.headers["etag"]?.replace(/^"(.*)"$/, "$1");
                    if (eTag) {
                        uploadedParts.push({ PartNumber: partNumber, ETag: eTag });
                    } else {
                        console.warn(`Chunk ${partNumber} uploaded but missing ETag.`);
                    }
                } catch (error) {
                    console.error(`Chunk ${partNumber} upload failed:`, error);
                }
            })
        );

        if (uploadedParts.length === 0) {
            console.error("Error: No chunks uploaded successfully.");
            return;
        }

        const messagePayload = {
            file: fileUrl,
            message: `${file.name}`,
            type: false,
            meta: { data: "test", message: "test" },
        };
        this.isLoading = true;
        try {
            await this.chatService
                .completeUpload(
                    fileName,
                    uploadId,
                    uploadedParts,
                    file.size,
                    this.singleUser.channelId,
                    messagePayload
                )
                .toPromise();

            console.log("Upload completed successfully!");
            console.log("Before calling handleIncomingMessage:", { 
                sid: this.userId, 
                createdAt: new Date(),
                file: fileUrl, 
                message: `File uploaded: ${file.name}`,
            });
            this.isLoading = false;
            this.scrollToBottom();

        } catch (err) {
            console.error("Upload failed:", err);
        }
    } catch (err) {
        console.error("Error in file upload process:", err);
    }
}


  async handleFileInput(event: any): Promise<void> {
    const file = event.target.files[0];
    if (!file) return;

    const fileMessage = {
      sender_id: this.userId,
      creationTime: new Date(),
      file: file,
      fileName: file.name,
    };
    console.log(fileMessage, "fileMessage", file, this.userId);
    try {
      await this.uploadFile(file,0);
    } catch (error) {
      console.error("File upload failed:", error);
    }
  }

  async handleFileInput2(event: any): Promise<void> {
     const file = event.target.files[0];
    if (!file) return;

    const fileMessage = {
      sender_id: this.userId,
      creationTime: new Date(),
      file: file,
      fileName: file.name,
    };
    console.log(fileMessage, "fileMessage", file, this.userId);
    try {
      await this.uploadFile(file,1);
    } catch (error) {
      console.error("File upload failed:", error);
    }

    // this.isLoading2 = true;
    // if (file) {
    //   // this.convertFileToBase64(file);
    //   this.sendfile2 = file;

    //   const fileName = { fileName: file.name };

    //   const fileMessage = {
    //     sender_id: this.userId,
    //     creationTime: new Date(),
    //     file: file ? file : "",
    //     fileName: file.name,
    //   };
    //   this.singleChatMessages2.push(fileMessage);
    //   setTimeout(() => {
    //     this.isLoading1 = false;
    //     this.sendChatMessage(0);
    // }, 3000);
    // }
  }

  getFileType(file: File): string {
    if (!file) return "text"; 
  
    const fileType = file.type; 
  
    if (fileType.startsWith("image/")) return "image";
    if (fileType.startsWith("video/")) return "video";
    if (
      fileType.includes("pdf") || 
      fileType.includes("msword") || 
      fileType.includes("presentation") ||
      fileType.includes("spreadsheet")
    ) {
      return "document";
    }
  
    return "other";
  }
  

  isDifferentTimestamp(currentMessage: any): boolean {
    const currentTimestamp = new Date(currentMessage.creationTime);
    if (
      this.previousTimestamp &&
      currentTimestamp.getMinutes() === this.previousTimestamp.getMinutes()
    ) {
      return false;
    } else {
      // Update the previous timestamp
      this.previousTimestamp = currentTimestamp;
      return true;
    }
  }

  //Displaying Date in chatbox1 and chatbox2 chat
  processMessages() {
    this.singleChatMessages = this.singleChatMessages || [];
    this.singleChatMessages2 = this.singleChatMessages2 || [];
 
    // Now process the messages
    this.processSingleChatMessages(this.singleChatMessages);
    this.processSingleChatMessages(this.singleChatMessages2);
 }
 
  
  processSingleChatMessages(chatMessages: any[]) {
    if (!Array.isArray(chatMessages)) {
        console.error('Expected an array of messages, but received:', chatMessages);
        return;
    }

    chatMessages.sort((a, b) => {
        const aDate = new Date(a.updatedAt).getTime();
        const bDate = new Date(b.updatedAt).getTime();
        
        if (isNaN(aDate) || isNaN(bDate)) {
            console.warn('Invalid date encountered while sorting messages');
            return 0; 
        }

        return aDate - bDate;
    });

    let lastDisplayedDate: string | null = null;

    chatMessages.forEach((message) => {
        if (!message.updatedAt || isNaN(new Date(message.updatedAt).getTime())) {
            console.warn('Invalid message date:', message);
            return;
        }

        const messageDate = new Date(message.updatedAt).toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
        });

        message["showDateHeader"] = lastDisplayedDate !== messageDate;
        lastDisplayedDate = messageDate;
    });
}

  // For Checking the file type
  isImage(url: string): boolean {
    return /\.(jpg|jpeg|png|gif)$/i.test(url);
  }

  isDocument(url: string): boolean {
    return /\.(doc|docx|pdf|zip|xml|rar|xlsx|pptx)$/i.test(url);
  }

  isVideo(url: string): boolean {
    return /\.(mp4|webm)$/i.test(url);
  }

  //Close Chatbox1 
  closeChat() {
    this.showUsers1 = !this.showUsers1;
    this.channelCache[0].close();

    this.channelCache.shift();
  }
  //Close Chatbox2
  closeChat2() {
    this.showUsers2 = !this.showUsers2;
    this.channelCache[1].close();
    this.channelCache.pop();
  }

  ngOnDestroy() {
    if (this.channelCache.length) {
      this.channelCache.forEach((ch) => {
        ch.close();
      });
      this.channelCache = [];
    }
  }

  handelKeyDown1(event: KeyboardEvent): void {
    if (event.ctrlKey && event.shiftKey && event.key === "Enter") {
      this.newMessage += "\n";
    }
  }
  handelKeyDown2(event: KeyboardEvent): void {
    if (event.ctrlKey && event.shiftKey && event.key === "Enter") {
      this.newMessage2 += "\n";
    }
  }
  closeContainer() {
    this.showChatContainer = !this.showChatContainer;
    this.showUsers1 = false;
    this.showUsers2 = false;
    this.channelCache = [];
    this.initiatChat();
  }

}
