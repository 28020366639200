<div class="container"></div>
<div class="logo-wrapper" (click)="showChat()" style="position: relative">
  <img class="rounded-circle" src="assets/samurais.png" alt="" />
  @if(userId && unreadMessageCount > 0) {
  <div class="notification-badge">
    {{ unreadMessageCount }}
  </div>
  }
</div>
@if (showChatBox) {
<div class="chatbox-holder">
  <div class="chatbox group-chat chatbox1">
    <div class="chatbox-top chatbox-top1">
      <div class="d-flex justify-content-between align-items-center w-100">
        <div>
          <h5 class="m-0">Samurais</h5>
          <p class="d-block" style="font-weight: 500">
            {{ totalGroupMembers }} members
          </p>
        </div>
        <div (click)="showChatBox = !showChatBox" style="cursor: pointer">
          <img style="width: 15px" src="assets/close1.png" alt="" />
        </div>
      </div>
    </div>
    <hr />
    <!-- <div class="p-2">
      <hr>
        <input type="text" class="form-control" placeholder="search in chats">
      </div> -->
    <div id="chat-messages" class="chat-messages">
      @for (msg of groupChatMessages; track msg) {

      <div style="display: contents">
        @if(msg.showDateHeader){
        <div
          class="message-date d-flex justify-content-center align-items-center w-100"
        >
          <p>{{ msg.updatedAt | date : "MMM d, yyyy" }}</p>
        </div>
        }
        <div style="display: contents">
          @if (userId === msg.sender_id) {
          <div class="message-box-holder">
            @if (msg.message) {
            <div class="d-flex justify-content-end">
              @if (msg.message) {
              <div class="d-flex justify-content-end">
                <div
                  class="message-box"
                  (click)="openUrlInNewWindow(msg.message)"
                  style="cursor: pointer"
                >
                  @if (isURL(msg.message)) {

                  <div class="url-preview">
                    <div class="d-flex thumb-box">
                      @if (msg.meta?.image) {
                      <img
                        [src]="msg.meta.image"
                        alt="Thumbnail"
                        class="thumbnail-image"
                      />
                      }

                      <div class="meta-title-container">
                        @if (msg.meta?.title) {
                        <p class="meta-title">{{ msg.meta.title }}</p>
                        } @if (msg.meta?.description) {
                        <p class="meta-description">
                          {{ msg.meta.description }}
                        </p>
                        }
                      </div>
                    </div>
                    <span [style.textDecoration]="'underline'">
                      {{ msg.message }}
                    </span>
                  </div>
                  } @else {
                  <div>
                    <span [style.color]="'white'">
                      {{ msg.message }}
                    </span>
                  </div>
                  }

                  <div class="message-time d-flex justify-content-end">
                    <span style="color: white">{{
                      msg.createdAt | date : "hh:mm a"
                    }}</span>
                  </div>
                </div>
                <img
                  style="
                    width: 20px;
                    margin-left: 8px;
                    display: flex;
                    height: 20px;
                  "
                  class="rounded-circle"
                  [src]="msg.profileImage"
                  alt=""
                />
              </div>
              }
            </div>
            } @if (msg.url && isImage(msg.url)) {
            <div class="image-box-holder message-box" style="margin-top: 10px">
              <div class="image-box">
                <img [src]="msg.url" class="img-fluid" alt="Image" />
              </div>
            </div>
            } @if (msg.url && isVideo(msg.url)) {
            <div class="video-box-holder message-box" style="margin-top: 10px">
              <div class="video-box">
                <video
                  [src]="msg.url"
                  class="embed-responsive embed-responsive-16by9"
                  controls
                ></video>
                <div class="message-time">
                  <span>{{ msg.createdAt | date : "hh:mm a" }}</span>
                </div>
              </div>
            </div>
            } @if (msg.url && isDocument(msg.url)) {
            <div
              class="document-box-holder message-box"
              style="margin-top: 10px"
            >
              <div class="document-box download-doc">
                <p>{{ msg.url }}</p>
                <a [href]="msg.url">
                  <img src="../../../assets/dwnload-icon.svg" alt="" />
                </a>
              </div>
              <div class="message-time">
                <span>{{ msg.createdAt | date : "hh:mm a" }}</span>
              </div>
            </div>
            }
          </div>
          } @if (userId !== msg.sender_id) {
          <div class="message-box-holder" style="margin-top: 8px">
            @if (msg.message) {
            <div class="d-flex justify-content-start">
              <!-- @if (userId !== msg.sender_id && msg.isDifferentSender) { -->
              <div class="message-sender d-flex justify-content-start">
                <div class="" style="margin-right: 8px">
                  <img
                    [src]="msg.profileImage"
                    class="rounded-circle"
                    alt=""
                    width="20px"
                  />
                </div>
                <div
                  class="message-box message-partner 1"
                  (click)="openUrlInNewWindow(msg.message)"
                  style="cursor: pointer"
                >
                  <p class="senderName text-capitalize">{{ msg.name }}</p>

                  @if (isURL(msg.message)) {
                  <div class="url-preview">
                    <div class="d-flex thumb-box">
                      @if (msg.meta?.image) {
                      <img
                        [src]="msg.meta.image"
                        alt="Thumbnail"
                        class="thumbnail-image"
                      />
                      }

                      <div class="meta-title-container">
                        @if (msg.meta?.title) {
                        <p class="meta-title">{{ msg.meta.title }}</p>
                        } @if (msg.meta?.description) {
                        <p class="meta-description">
                          {{ msg.meta.description }}
                        </p>
                        }
                      </div>
                    </div>
                    <span [style.textDecoration]="'underline'">
                      {{ msg.message }}
                    </span>
                  </div>
                  } @else {
                  <div>
                    <span>
                      {{ msg.message }}
                    </span>
                  </div>
                  }

                  <span
                    class="d-flex justify-content-end"
                    style="font-size: 11px"
                    >{{ msg.createdAt | date : "hh:mm a" }}</span
                  >
                </div>
              </div>
            </div>
            } @if (msg.url && isImage(msg.url)) {
            <div
              class="image-box-holder message-box message-partner"
              style="margin-top: 10px"
            >
              <div class="image-box">
                <img [src]="msg.url" class="img-fluid" alt="Image" />
                <div class="message-time">
                  <span>{{ msg.createdAt | date : "hh:mm a" }}</span>
                </div>
              </div>
            </div>
            } @if (msg.url && isVideo(msg.url)) {
            <div
              class="video-box-holder message-box message-partner"
              style="margin-top: 10px"
            >
              <div class="video-box">
                <video
                  [src]="msg.url"
                  class="embed-responsive embed-responsive-16by9"
                  controls
                ></video>
                <div class="message-time">
                  <span>{{ msg.createdAt | date : "hh:mm a" }}</span>
                </div>
              </div>
            </div>
            } @if (msg.url && isDocument(msg.url)) {
            <div
              class="document-box-holder message-box message-partner"
              style="margin-top: 10px"
            >
              <div class="mess-box download-doc">
                <p>{{ msg.url }}</p>
                <a [href]="msg.url">
                  <img src="../../../assets/dwnload-icon.svg" />
                </a>
              </div>
              <div class="message-time">
                <span>{{ msg.createdAt | date : "hh:mm a" }}</span>
              </div>
            </div>
            }
          </div>
          }
        </div>
      </div>
      } @if (isLoading) {
      <div class="upload-container">
        <div class="progress-circle">
          <svg viewBox="0 0 36 36">
            <path
              class="circle-bg"
              d="M18 2.084a 15.916 15.916 0 1 1 0 31.832a 15.916 15.916 0 1 1 0 -31.832"
            />
            <path
              class="circle-progress"
              d="M18 2.084a 15.916 15.916 0 1 1 0 31.832a 15.916 15.916 0 1 1 0 -31.832"
              stroke-dasharray="100, 100"
              stroke-dashoffset="100"
            />
          </svg>
        </div>
        <span id="progress-text">{{ uploadProgress }}%</span>
      </div>
      }
    </div>
    @if (MetaDataDetails && MedataLength > 0 && MetaDataDetails.fileUrl && isSuggestionBoxVisible)  {
    @if(MetaDataDetails.thread && MetaDataDetails.thread.length > 0) {
    <ul class="filter-data" style="position: relative; z-index: 20" >
      <svg
       (click)="closeSuggestionBox()"
        class="meta-close"
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <g
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="4"
        >
          <path d="M8 8L40 40" />
          <path d="M8 40L40 8" />
        </g>
      </svg>
      <p><b>Main File:</b> {{ MetaDataDetails.fileName }}</p>
      <div class="d-flex" style="width: 90%; word-break: break-word">
        <p style="margin-bottom: 0"><b>Version - 1:</b></p>

        <p>{{ MetaDataDetails.fileUrl }}</p>
      </div>
      <span class="d-block" style="font-weight: 500; padding-left: 68px"
        >(sent by : {{ MetaDataDetails.thread[0]?.senderName }} at
        {{ MetaDataDetails.createdAt | date : "hh:mm a - MMM d, y" }})</span
      >

      @if (MetaDataDetails.thread.length > 1) {
      <ul class="sub-file">
        @for (threadItem of MetaDataDetails.thread; track threadItem; let j =
        $index) {
        <li
          (click)="
            selectSuggestion(MetaDataDetails.fileUrl, MetaDataDetails._id)
          "
        >
          <b>Version - {{ j + 2 }}:</b> {{ MetaDataDetails.fileUrl }}
          <span class="d-block" style="font-weight: 500; padding-left: 68px"
            >(sent by : {{ threadItem.senderName }} at
            {{ threadItem.createdAt | date : "hh:mm a - MMM d, y" }})</span
          >
        </li>
        }
      </ul>
      }
    </ul>
    } }
    <div>
      <div class="chat-input-holder">
        <textarea
          type="text"
          class="chat-input"
          [(ngModel)]="newMessage"
          (input)="onInputChange()"
          (keydown.enter)="sendMessage()"
          (keydown)="handelKeyDown($event)"
          placeholder="Type a message"
        ></textarea>
        <div class="position-relative mx-3">
          <input
            type="file"
            (change)="handleFileInput($event)"
            (keydown.enter)="sendMessage()"
          /><img
            class="img-fluid img-icon"
            src="assets/upload-pic.png"
            alt=""
          />
        </div>
        <div class="cursorPointer">
          <img
            src="../../../assets/sendMessage.png"
            class="message-send message-send1"
            (click)="sendMessage()"
          />
        </div>
      </div>
    </div>
  </div>
</div>
}
