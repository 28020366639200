import { Component, OnInit } from '@angular/core';
import { NgClass, NgStyle } from '@angular/common';
import {
  ReactiveFormsModule,
  FormGroup,
  FormsModule,
  Validators,
  FormBuilder,
  FormControl,
  FormArray,
} from "@angular/forms";
import { InventoryService } from 'src/app/service/inventory.service';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { alertModalDelete, alertModalWithoutConfirm } from '../../helpers/alert';
import { monitorEventLoopDelay } from 'perf_hooks';
import { clippingParents } from '@popperjs/core';


interface userList {
  id: string;
  viewValue: string;
}


@Component({
  selector: 'app-workstation',
  standalone: true,
  imports: [MatInputModule, MatSelectModule, MatFormFieldModule, ReactiveFormsModule, FormsModule, NgClass, NgStyle],
  templateUrl: './workstation.component.html',
  styleUrls: ['./workstation.component.css', '../styles/inventory.css']
})
export class WorkstationComponent implements OnInit {

  currentStep = 1;
  selectedImage: File | null = null;
  selectedImages: File[] = [];
  previewImageUrl: string | ArrayBuffer | null = null;
  previewImagesUrl: string[] = [];
  maxImages: number = 5;
  showWorkstationButton = false;
  userDepartment: string | null = null;

  users: { value: string, viewValue: string, id: string }[] = [];

  configurationForm: FormGroup;
  accessoriesForm: FormGroup;

  constructor(private fb: FormBuilder,
    private inventoryService: InventoryService,
  ) {

    this.configurationForm = this.fb.group({
      workstationName: ['', [Validators.required, Validators.maxLength(50)]],
      processor: ['', Validators.required],
      graphicCard: ['', Validators.required],
      isLicensed: ['', Validators.required],
      assignTo: ['',],
      ramValue: ['', Validators.required],
      version: ['', Validators.required],
      WorkstationFile: []
    })

    this.accessoriesForm = this.fb.group({
      monitor1: ['', Validators.maxLength(50)],
      monitor2: ['', Validators.maxLength(50)],
      monitor3: ['', Validators.maxLength(50)],
      monitor4: ['', Validators.maxLength(50)],
      monitor5: ['', Validators.maxLength(50)],
      monitor6: ['', Validators.maxLength(50)],
      headPhone: ['', Validators.maxLength(50)],
      keyboard: ['', Validators.maxLength(50)],
      mouse: ['', Validators.maxLength(50)],
      camera: ['', Validators.maxLength(50)],
      speakers: ['', Validators.maxLength(50)]
    });

  }


  ngOnInit(): void {
    this.getAllUsers();
    this.getWorkstation();
    this.userDepartment = localStorage.getItem('department');
    this.showWorkstationButton = this.userDepartment === 'HR';
  }



  getAllUsers(): void {
    this.inventoryService.getInvUsers().subscribe(res => {
      let data = res["data"];
      this.users = data.map((user: any) => ({
        value: user.userName,
        viewValue: user.userName,
        id: user._id
      }));
      console.log("users", this.users);

    });
  }


  navigateToStep(step: number): void {
    this.currentStep = step;
  }

  resetClose() {
    this.configurationForm.reset();
    this.accessoriesForm.reset();
    this.currentStep = 1;
    this.selectedImages = [];
    this.previewImagesUrl =[];
    this.isEdit = false;
  }

  previewInventory: any
  openPreviewSlider(index: number) {
    this.previewInventory = this.workstation[index]
  }


  isEditMode: boolean;
  previewProductImg: any
  workstationDetail: any
  openWorkstationDetail(index: number) {
    this.isEditMode = true;
    this.workstationDetail = this.workstation[index]
    console.log({ Detail: this.workstationDetail })
  }

  // Configuration Image Upload
  newImages: File[] = []; 
  onSelectImage(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const selectedImage = event.target.files[0];
  
      this.newImages = [selectedImage];
      this.selectedImages = [...this.selectedImages, ...this.newImages];
  
      this.updateImagePreviews();
  
      this.configurationForm.patchValue({
        WorkstationFile: this.selectedImages,
      });
      console.log("Selected images (including existing):", this.selectedImages);
    }
}

  // Configuration Multiple Image Upload
  onSelectMultipleImages(event: any): void {

    if (event.target.files && event.target.files.length > 0) {
      const filesArray: File[] = Array.from(event.target.files) as File[];
      const totalImages = this.selectedImages.length + filesArray.length;

      if (totalImages > this.maxImages) {
        return alertModalWithoutConfirm(
          "warning",
          `You can only upload a maximum of ${this.maxImages} images.`
        );
      }  
    this.newImages = filesArray;
    this.selectedImages = [...this.selectedImages, ...this.newImages];

    this.selectedImages = Array.from(new Set(this.selectedImages));

      this.updateImagePreviews();
      this.configurationForm.patchValue({
        WorkstationFile: this.selectedImages
      });

    }
    console.log("Selected images (including existing):", this.selectedImages);
  }

  updateImagePreviews(): void {
    this.previewImagesUrl = [];
    this.selectedImages.forEach((image) => {
      if (typeof image === "string") {
        this.previewImagesUrl.push(image);
      } else {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.previewImagesUrl.push(e.target.result);
        };
        reader.readAsDataURL(image);
      }
    });
  }

  // // Remove multiple uploaded Image
  // removeImage(index: number): void {
  //   this.selectedImages.splice(index, 1);
  //   this.updateImagePreviews();
  //   this.configurationForm.patchValue({
  //     WorkstationFile: this.selectedImages
  //   });
  // }


  removeImage(index: number ): void {
    const removedImage = this.selectedImages[index]; 
    const body = {
      inventoryId: this.editWorkstationId, 
      images: [removedImage], 
    };
  
    this.inventoryService.deleteWorkstationImage(this.editWorkstationId,body).subscribe(
      (response) => {
        console.log('Image removed successfully from server:', response);
  
        this.selectedImages.splice(index, 1);
        this.updateImagePreviews();
        this.configurationForm.patchValue({
          WorkstationFile: this.selectedImages,
        });
      },
      (error) => {
        console.error('Failed', error);
      }
    );
  }


  submitted = false;
  //Configuration Form Submit
  onSubmitConfiguration() {
    console.log(this.configurationForm)
    this.submitted = true;
    if (this.configurationForm.valid) {
      this.currentStep = 2;
      const formData = new FormData();
      Object.keys(this.configurationForm.value).forEach(key => {
        formData.append(key, this.configurationForm.value[key]);
      });

      if (this.selectedImages.length > 0) {
        this.selectedImages.forEach((image, index) => {
          formData.append(`files[${index}]`, image);
        });
      }
    }
    else if (this.configurationForm.invalid) {
      console.log('invalid')
      return alertModalWithoutConfirm(
        "warning",
        "Please fill in all required fields before submitting."
      );
    }

  }


  // onSubmitAccessories() {
  //   console.log("isEdit value:", this.isEdit);
  //   console.log(this.accessoriesForm);
  
  //   if (this.configurationForm.valid) {
  //     const workstationFormValue = {
  //       ...this.configurationForm.value,
  //       ...this.accessoriesForm.value
  //     };
  //     const assignee = this.users.find(user => user.value === workstationFormValue.assignTo);
  //     console.log('Assignee:', assignee);
  //     const assigneeId = assignee ? assignee.id : "null";
  //     console.log({ assigneeId });
  
  //     const monitorArray = [
  //     workstationFormValue.monitor1,
  //     workstationFormValue.monitor2,
  //     workstationFormValue.monitor3,
  //     workstationFormValue.monitor4,
  //     workstationFormValue.monitor5,
  //     workstationFormValue.monitor6
  //   ].filter(monitor => monitor); 
  
  //     const getFormValue: any = {
  //       name: String(workstationFormValue.workstationName),
  //       assigneeName: workstationFormValue.assignTo,
  //       assigneeId:assigneeId,
  //       processor: workstationFormValue.processor,
  //       ram: workstationFormValue.ramValue,
  //       graphicsCard: workstationFormValue.graphicCard,
  //       windowsVersion: workstationFormValue.version,
  //       windowsLicensed: Boolean(workstationFormValue.isLicensed === 'yes' ? true : false),
  //       // headPhone: workstationFormValue.headPhone,
  //       // keyboard: workstationFormValue.keyboard,
  //       // mouse: workstationFormValue.mouse,
  //       monitors: monitorArray,
        
  //     };
  
     
  //      // Conditionally add optional fields
  //   if (workstationFormValue.headPhone) {
  //     getFormValue.headPhone = workstationFormValue.headPhone;
  //   }
  //   if (workstationFormValue.keyboard) {
  //     getFormValue.keyboard = workstationFormValue.keyboard;
  //   }
  //   if (workstationFormValue.mouse) {
  //     getFormValue.mouse = workstationFormValue.mouse;
  //   }


  //     const formData = new FormData();
  //     for (const key in getFormValue) {
  //       if (getFormValue.hasOwnProperty(key)) {
  //         if (Array.isArray(getFormValue[key])) {
  //           getFormValue[key].forEach((item, index) => {
  //             formData.append(`monitors[${index}]`, item);
  //           });
  //         } else {
  //           formData.append(key, getFormValue[key]);
  //         }
  //       }
  //     }
    
  //     const selectedImages = this.configurationForm.get("WorkstationFile")?.value;
  //     if (selectedImages && Array.isArray(selectedImages)) {
  //       let newIndex = 0;
  //       selectedImages.forEach(async (image: File | string) => {
  //         if (typeof image === "string") {
  //           const blob = await fetch(image).then(res => res.blob());
  //           formData.append(`files[${newIndex}]`, blob, "blob");
  //         } else {
  //           formData.append(`files[${newIndex}]`, image, image.name);
  //         }
  //         newIndex++;
  //       });
  //     }
  
  //     console.log(getFormValue);
  
  //     if (!this.isEdit) {
  //       // Create new workstation
  //       this.inventoryService.addWorkstation(formData).subscribe(
  //         response => {
  //           this.currentStep = 3;
  //           console.log(response, "response");
  //           this.getWorkstation();
  //         },
  //         error => {
  //           console.error('error:', error);
  //         }
  //       );
  //     } else {
  //       // Update existing workstation
  //       console.log("Updating workstation with ID:", this.editWorkstationId);
  
  //       this.inventoryService.updateWorkstation(this.editWorkstationId, formData).subscribe(
  //         response => {
  //           this.currentStep = 3;
  //           console.log("Updated successfully:", response);
  //           this.getWorkstation();
  //         },
  //         error => {
  //           console.error("Update failed:", error);
  //         }
  //       );
  //     }
  //   } else {
  //     console.log("One or more forms are invalid");
  //     alert("Please fill in all required fields before submitting.");
  //   }
  // }


  onSubmitAccessories() {
    console.log("isEdit value:", this.isEdit);
    console.log(this.accessoriesForm);
  
    if (this.configurationForm.valid) {
      const workstationFormValue = {
        ...this.configurationForm.value,
        ...this.accessoriesForm.value
      };
      const assignee = this.users.find(user => user.value === workstationFormValue.assignTo);
      console.log('Assignee:', assignee);
      const assigneeId = assignee ? assignee.id : "null";
      console.log({ assigneeId });
  
      const monitorArray = [
        workstationFormValue.monitor1,
        workstationFormValue.monitor2,
        workstationFormValue.monitor3,
        workstationFormValue.monitor4,
        workstationFormValue.monitor5,
        workstationFormValue.monitor6
      ].filter(monitor => monitor); // Filter out empty monitors
  
      const getFormValue: any = {
        name: String(workstationFormValue.workstationName),
        assigneeName: workstationFormValue.assignTo,
        assigneeId: assigneeId,
        processor: workstationFormValue.processor,
        ram: workstationFormValue.ramValue,
        graphicsCard: workstationFormValue.graphicCard,
        windowsVersion: workstationFormValue.version,
        windowsLicensed: Boolean(workstationFormValue.isLicensed === 'yes'),
        monitors: monitorArray
      };
  
      // Conditionally add optional fields
      if (workstationFormValue.headPhone) {
        getFormValue.headPhone = workstationFormValue.headPhone;
      }
      if (workstationFormValue.keyboard) {
        getFormValue.keyboard = workstationFormValue.keyboard;
      }
      if (workstationFormValue.mouse) {
        getFormValue.mouse = workstationFormValue.mouse;
      }
  
      const selectedImages = this.configurationForm.get("WorkstationFile")?.value;
  
      // When adding a new workstation
      if (!this.isEdit) {
        const formData = new FormData();
        for (const key in getFormValue) {
          if (getFormValue.hasOwnProperty(key)) {
            if (Array.isArray(getFormValue[key])) {
              getFormValue[key].forEach((item, index) => {
                formData.append(`monitors[${index}]`, item);
              });
            } else {
              formData.append(key, getFormValue[key]);
            }
          }
        }
  
        // Add files (if any) to FormData
        if (selectedImages && Array.isArray(selectedImages)) {
          let newIndex = 0;
          selectedImages.forEach(async (image: File | string) => {
            if (typeof image === "string") {
              const blob = await fetch(image).then(res => res.blob());
              formData.append(`files[${newIndex}]`, blob, "blob");
            } else {
              formData.append(`files[${newIndex}]`, image, image.name);
            }
            newIndex++;
          });
        }
        // Send FormData to add new workstation
        this.inventoryService.addWorkstation(formData).subscribe(
          response => {
            this.currentStep = 3;
            console.log(response, "response");
            this.getWorkstation();
          },
          error => {
            console.error('error:', error);
          }
        );
      } else {
        // When editing an existing workstation, send JSON data (NOT FormData)
        console.log("Updating workstation with ID:", this.editWorkstationId);
  
        // Use JSON data for updating (not FormData)
        const requestBody = getFormValue; // Prepare requestBody as JSON
  
        // Send the JSON data for updating the workstation
        this.inventoryService.updateWorkstation(this.editWorkstationId, requestBody).subscribe(
          response => {
            this.currentStep = 3;
            console.log("Updated successfully:", response);
  
            // If files were selected, upload them after the update
            if (selectedImages && Array.isArray(selectedImages)) {
              console.log("Uploading files after workstation update");
              this.uploadFiles(selectedImages, this.editWorkstationId);
            }
            
            this.getWorkstation();
          },
          error => {
            console.error("Update failed:", error);
          }
        );
      }
    } else {
      console.log("One or more forms are invalid");
      alert("Please fill in all required fields before submitting.");
    }
  }
  
  // Helper method to handle file uploads separately
  uploadFiles(files: (File | string)[], workstationId: string) {
    const formData = new FormData();
    const selectedImages = this.configurationForm.get("WorkstationFile")?.value;
  
    // Append files to FormData
    if (selectedImages && Array.isArray(selectedImages)) {
      let newIndex = 0;
      selectedImages.forEach(async (image: File | string) => {
        if (typeof image === "string") {
          const blob = await fetch(image).then(res => res.blob());
          formData.append(`files[${newIndex}]`, blob, "blob");
        } else {
          formData.append(`files[${newIndex}]`, image, image.name);
        }
        newIndex++;
      });
    }
  
    this.inventoryService.uploadFiles(formData, workstationId).subscribe(
      response => {
        console.log("Files uploaded successfully:", response);
      },
      error => {
        console.error("File upload failed:", error);
      }
    );
  }
  
  
  
  
  workstation: any[] = [];
  totalWorkstation: number;
  getWorkstation() {
    const field: any = {
      pageNum: 1,
      limit: 500,
    };

    this.inventoryService.getWorkstation(field).subscribe((response: any) => {
      this.workstation = response.data.records.reverse();
      this.totalWorkstation = response.data.totalCounts
      console.log("Response", response)
    })
  }

  editWorkstationId: string;
  singleWorkstation: any;
  getSingleworkstation(id: any) {
    this.editWorkstationId = id;
    this.inventoryService.getWorkstationById(id).subscribe((res) => {
      this.singleWorkstation = res["data"];
      console.log("Workstation BY ID", this.singleWorkstation);
      if (this.singleWorkstation) {
        this.editWorkstation();
      }
    });
  
  }

  // Delete Workstation
  deleteWorkstation(id: any) {
        this.editWorkstationId = id;
        console.log(this.editWorkstationId,"ID");
          this.inventoryService.deleteWorkstation(id).subscribe(
            (res) => {
              window.location.reload();
            },
            (error) => {
              console.error(error);
              const message = error.error.message || "Something went wrong!";
              alertModalDelete(message);
            },
          );
  } 

  currentPage = 1;
  itemsPerPage = 20;
  getPaginatedLeads() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    
      return  this.workstation.slice(startIndex, endIndex);
    
  }

  // Function to handle page change
  pageChanged(page: number) {
    this.currentPage = page;
    console.log(page, "PAGE");
    const option = {
      pageNum: page,
      limit: 20,
      available:true,
    };
   
    this.inventoryService.getWorkstation(option).subscribe(
      (response: any) => {
        this.workstation = response.data.records;
        this.totalWorkstation = response.data.totalCounts;
      },
      (error) => {
        console.error("Error fetching leads data:", error);
      },
    );
}

  get totalPages(): number {
    return Math.ceil( this.totalWorkstation / this.itemsPerPage);
  }


  get pages(): number[] {
    return Array.from({ length: this.totalPages }, (_, index) => index + 1);
  }

  trackByFn(index: number, item: any): any {
    return item._id;
  }

  get visiblePages(): number[] {
    const numVisiblePages = 3;
    const startPage = Math.max(
      1,
      this.currentPage - Math.floor(numVisiblePages / 2),
    );
    const endPage = Math.min(this.totalPages, startPage + numVisiblePages - 1);

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index,
    );
  }

  isPageVisible(page: number): boolean {
    const numVisiblePages = 3;
    const startPage = Math.max(
      1,
      this.currentPage - Math.floor(numVisiblePages / 2),
    );
    const endPage = Math.min(this.totalPages, startPage + numVisiblePages - 1);

    return page <= 3 || (page >= startPage && page <= endPage);
  }



  isEdit:boolean= false;
  editWorkstation() {
    this.currentStep = 1;
    this.isEdit = true;
    const assignee = this.users.find(user => user.viewValue === this.singleWorkstation.assigneeName);
    const assigneeValue = assignee ? assignee.value : null;
    // Configuration Form Patching Values
    this.configurationForm.patchValue({
      workstationName: this.singleWorkstation.name,
      processor: this.singleWorkstation.processor,
      graphicCard: this.singleWorkstation.graphicsCard,
      isLicensed: this.singleWorkstation.windowsLicensed ? "yes" : "no",
      assignTo: assigneeValue,
      ramValue: this.singleWorkstation.ram, 
      version: this.singleWorkstation.windowsVersion,
      WorkstationFile: this.singleWorkstation.images,
      
    });
    console.log("assigneeName",assigneeValue);
    // Image Preview
    if (this.singleWorkstation.images && this.singleWorkstation.images.length > 0) {
      this.selectedImages = this.singleWorkstation.images;
      this.updateImagePreviews();
    }
    console.log("Images", this.singleWorkstation.images);

    // Accessories Form Patching Values
    const monitors = this.singleWorkstation.monitors || [];
    console.log('Monitors:', monitors);
  
    const monitorControls = {
      monitor1: monitors[0] || '',
      monitor2: monitors[1] || '',
      monitor3: monitors[2] || '',
      monitor4: monitors[3] || '',
      monitor5: monitors[4] || '',
      monitor6: monitors[5] || '',
    };
  
    this.accessoriesForm.patchValue({
      ...monitorControls,
      headPhone: this.singleWorkstation.headPhone?? '',
      keyboard: this.singleWorkstation.keyboard ?? '',
      mouse: this.singleWorkstation.mouse ?? '',
      camera: this.singleWorkstation.camera ?? '',
      speakers: this.singleWorkstation.speakers ?? '',
    });

  }

  removeAssignee(workstationId: any) {
    const body = {
      workstationId: this.editWorkstationId ,
    };
  console.log(body)
    this.inventoryService.removeWorkstationAssignee(body).subscribe(
      (data) => {
        console.log("Assignee Removed");
        this.configurationForm.get('assignTo')?.setValue(null);
      },
      (error) => {
        console.error("Error removing assignee:", error);
       
      }
    );
  }
  
}




