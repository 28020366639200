import { Component } from '@angular/core';
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { provideNativeDateAdapter } from "@angular/material/core";
import { MatSelectModule } from '@angular/material/select';
import { InventoryService } from 'src/app/service/inventory.service';
import { NgClass } from '@angular/common';
import { FormBuilder, FormGroup, Validators,ReactiveFormsModule } from '@angular/forms';


interface Request {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-admin-request',
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [ReactiveFormsModule,MatButtonModule, MatDatepickerModule, MatInputModule, MatFormFieldModule, MatSelectModule,NgClass],
  templateUrl: './admin-request.component.html',
  styleUrls: ['./admin-request.component.css', '../styles/inventory.css']
})
export class AdminRequestComponent {
  declineReasonForm: FormGroup;
  onHoldForm: FormGroup;
  constructor(private inventoryService: InventoryService,private fb: FormBuilder) {

    this.declineReasonForm = this.fb.group({
      rejectionReason: ['', Validators.required]
    })

    this.onHoldForm = this.fb.group({
      onHoldTillDate: ['', Validators.required]
    })
   }


    //Formatted onHold  DATE
    formatOnHoldDate(date: Date): { day: number; month: number; year: number } {
      return {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      };
    }

  defaultPendingRequestType: string = 'Service-Request';
  ngOnInit() {
    this.allAdminRequest(this.defaultPendingRequestType);
  }


  forwardedReq = []
  requests: Request[] = [
    { value: 'Service-Request', viewValue: 'Service Request' },
    { value: 'New-Inventory', viewValue: 'New Inventory Request' },
  ]

  onRequestSelect(value: string): void {
    console.log("Selected value:", value);
    this.allAdminRequest(value)
  }

  adminRequest = []
  totalForwarded: any;
  showNewInv: boolean = false;
  showService: boolean = false;
  allAdminRequest(requestType: string) {
    const adminValue = {
      requestType: requestType,

      // startDate: {
      //   day: 1,
      //   month: 1,
      //   year: 2021
      // },
      // endDate: {
      //   day: 1,
      //   month: 1,
      //   year: 2025
      // },
    }
    this.showNewInv = requestType === 'New-Inventory';
    this.showService = requestType === 'Service-Request';
    this.inventoryService.getAdminRequest(adminValue).subscribe(response => {
      console.log("Forward Response", response);
      this.adminRequest = response['data'].records;
      this.totalForwarded = response['data'].totalCounts;
      this.inventoryService.updateAdminRequestCount(this.adminRequest.length);
    });
  }

  // Price Formatting
  formatCurrency(value: string | number): string {
    if (!value) return "";
    return new Intl.NumberFormat("en-IN").format(Number(value));
  }
  
  acceptRequest(requestId: any) {
    const acceptRequest = {
      action: 'Accept',
      requestId: requestId,
      requestType: 'Service-Request',
    }
    this.inventoryService.superAdminAction(acceptRequest).subscribe(response => {
      console.log("called");
      this.showAcceptSuccess = true;
      this.allAdminRequest(this.defaultPendingRequestType);
    })
  }


  showRejectConfirm : boolean = false;
  showAcceptSuccess : boolean = false;
  onDeclineRequest(requestId: any){
    const rejectValue = {
      ...this.declineReasonForm.value
    }

    const getRejectValue = {
      rejectionReason: rejectValue.rejectionReason,
      action: 'Rejected',
      requestId: requestId,
      requestType: 'Service-Request',
    }
    this.inventoryService.superAdminAction(getRejectValue).subscribe(response => {
      console.log("called");
      this.showRejectConfirm = true;
      this.allAdminRequest(this.defaultPendingRequestType);
    })
  }
  onHoldConfirm(requestId: any){
    const onHoldValue = {
      ...this.onHoldForm.value
    }

    const holdDate = new Date(onHoldValue.onHoldTillDate);
    const formattedHoldDate = this.formatOnHoldDate(holdDate);

    const getHoldValue = {
      action: 'On Hold',
      requestId: requestId,
      requestType: 'Service-Request',
      onHoldTill: formattedHoldDate
    }

    this.inventoryService.superAdminAction(getHoldValue).subscribe(response => {
    console.log("onHoldConfirm");
    });
  }


  currentPage = 1;
  itemsPerPage = 20;
  getPaginatedLeads() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    if(!this.showNewInv){
    return this.adminRequest.slice(startIndex, endIndex);
    }
    else{
      return this.adminRequest.slice(startIndex, endIndex);
    }
  }

  // Pagination
    pageChanged(page: number) {
      this.currentPage = page;
      console.log(page, "PAGE");
      const option = {
        pageNum: page,
        limit: 20,
      };
      if(!this.showNewInv){
        this.inventoryService.getAdminRequest(this.defaultPendingRequestType).subscribe(res => {
          this.adminRequest = res['data'].records
          this.totalForwarded = res['data'].totalCounts
        }
      );
    }
  }
  
    get totalPages(): number {
      if(!this.showNewInv){
      return Math.ceil(this.totalForwarded / this.itemsPerPage);
    }
    if(!this.showService){
      return Math.ceil(this.totalForwarded / this.itemsPerPage);
    }
  }
  
    get pages(): number[] {
      return Array.from({ length: this.totalPages }, (_, index) => index + 1);
    }
  
    trackByFn(index: number, item: any): any {
      return item._id;
    }
  
    get visiblePages(): number[] {
      const numVisiblePages = 3;
      const startPage = Math.max(
        1,
        this.currentPage - Math.floor(numVisiblePages / 2),
      );
      const endPage = Math.min(this.totalPages, startPage + numVisiblePages - 1);
  
      return Array.from(
        { length: endPage - startPage + 1 },
        (_, index) => startPage + index,
      );
    }
  
    isPageVisible(page: number): boolean {
      const numVisiblePages = 3;
      const startPage = Math.max(
        1,
        this.currentPage - Math.floor(numVisiblePages / 2),
      );
      const endPage = Math.min(this.totalPages, startPage + numVisiblePages - 1);
  
      return page <= 3 || (page >= startPage && page <= endPage);
    }
}
